<template>
    <base-material-card :icon="resource.icon" :title="title">
        <v-tabs v-model="selectedTab">
            <v-tab
                class="mr-3"
                v-for="tab in mwsConfigurationOptions"
                :key="tab.value"
                @click="onClickTab(tab.value)"
            >
                <v-icon class="mr-2">mdi-amazon</v-icon>
                {{ tab.text }}
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="selectedTab" class="transparent"> </v-tabs-items>
        <va-list
            class="fba-inbound-shipment-list"
            :filters="filters"
            :filter.sync="filter"
            disable-create
            disable-export
        >
            <va-data-table
                :fields="fields"
                disable-actions
                disable-select
                show-expand
            >
                <template v-slot:expanded-item="{ item }">
                    <v-data-table
                        v-if="item.children.length"
                        disable-pagination
                        hide-default-footer
                        class="item-headers"
                        :headers="item_headers"
                        :items="item.children"
                        show-expand
                    >
                        <template v-slot:item.PrepDetailsList="{ item }">
                            <json-viewer
                                :value="item.PrepDetailsList"
                                :expand-depth="5"
                                copyable
                                sort
                            ></json-viewer>
                        </template>
                    </v-data-table>
                </template>
                <template v-slot:[`field.is_archived`]="{ item }">
                    <v-switch
                        hide-details
                        :label="'Ignore data'"
                        :false-value="false"
                        :true-value="true"
                        :input-value="item.is_archived"
                        @change="(value) => changeIsArchived(item.id, value)"
                    >
                    </v-switch>
                </template>
            </va-data-table>
        </va-list>
    </base-material-card>
</template>

<script>
import ShipmentStatuses from "@/constants/InboundShipmentStatuses";
import LabelPrepTypes from "@/constants/InboundShipmentLabelPrepTypes";

export default {
    props: ["resource", "title"],
    computed: {
        mwsConfigurationOptions() {
            return this.$store.getters.mwsConfigurationOptions;
        },
    },
    watch: {
        mwsConfigurationId: {
            immediate: true,
            handler(newVal) {
                if (!newVal) {
                    return;
                }
                const current = this.filter["mws_configuration_id"] || [];
                if (current.includes(newVal)) {
                    return;
                }
                this.filter["mws_configuration_id"] = [newVal];
            },
        },
        mwsConfigurationOptions: {
            immediate: true,
            handler(newVal) {
                if (!newVal || !newVal.length) {
                    return;
                }
                const filter = this.filters.find(
                    (filter) => filter.source === "mws_configuration_id"
                );
                filter.attributes.choices = newVal;
                this.findSelectedTab(newVal);
            },
        },
    },
    data() {
        return {
            selectedTab: 0,
            filter: {},
            filters: [
                {
                    label: "App",
                    source: "mws_configuration_id",
                    type: "select",
                    attributes: {
                        choices: this.mwsConfigurationOptions,
                        multiple: true,
                    },
                    alwaysOn: true,
                },
                {
                    source: "ShipmentId",
                    type: "text",
                    alwaysOn: true,
                },
                {
                    source: "ShipmentName",
                    type: "text",
                    alwaysOn: true,
                },
                {
                    source: "DestinationFulfillmentCenterId",
                    type: "text",
                    alwaysOn: true,
                },
                {
                    source: "AreCasesRequired",
                    type: "boolean",
                    alwaysOn: true,
                },
                {
                    source: "ShipmentStatus",
                    type: "select",
                    attributes: {
                        choices: ShipmentStatuses,
                        multiple: true,
                    },
                    alwaysOn: true,
                },
                {
                    source: "LabelPrepType",
                    type: "select",
                    attributes: {
                        choices: LabelPrepTypes,
                        multiple: true,
                    },
                    alwaysOn: true,
                },
                {
                    source: "ConfirmedNeedByDate",
                    type: "date",
                    alwaysOn: true,
                },
                {
                    label: "Ignore data",
                    source: "is_archived",
                    type: "boolean",
                    alwaysOn: true,
                },
            ],
            fields: [
                { source: "ShipmentId", sortable: true },
                { source: "ShipmentName", sortable: true },
                { source: "DestinationFulfillmentCenterId", sortable: true },
                { source: "ShipmentStatus", sortable: true },
                { source: "LabelPrepType", sortable: true },
                { source: "AreCasesRequired", type: "boolean", sortable: true },
                { source: "ConfirmedNeedByDate", type: "date", sortable: true },
                {
                    label: "Ignore data",
                    source: "is_archived",
                    type: "boolean",
                    sortable: true,
                    editable: true,
                },
            ],
            item_headers: [
                { text: "SellerSKU", value: "SellerSKU", sortable: true },
                {
                    text: "QuantityShipped",
                    value: "QuantityShipped",
                    sortable: true,
                },
                {
                    text: "QuantityReceived",
                    value: "QuantityReceived",
                    sortable: true,
                },
                {
                    text: "QuantityInCase",
                    value: "QuantityInCase",
                    sortable: true,
                },
                {
                    text: "ReleaseDate",
                    value: "ReleaseDate",
                    sortable: true,
                },
                {
                    text: "PrepDetailsList",
                    value: "PrepDetailsList",
                    sortable: true,
                },
            ],
        };
    },
    methods: {
        onClickTab(id) {
            this.filter["mws_configuration_id"] = id ? id : "";
        },
        findSelectedTab(tabs) {
            const { filter } = this.$route.query;
            if (!filter) {
                return;
            }
            const currentFilter = JSON.parse(filter);
            if (!currentFilter["mws_configuration_id"]) {
                return;
            }
            this.selectedTab = parseInt(
                currentFilter["mws_configuration_id"],
                10
            );
            if (!this.selectedTab) {
                return;
            }
            this.selectedTab = tabs.findIndex(
                (item) => item.value === this.selectedTab
            );
        },
        async changeIsArchived(id, is_archived) {
            try {
                await this.$store.dispatch("FbaInboundShipment/update", {
                    id,
                    data: { is_archived },
                });
            } catch (e) {
                console.log(e);
            } finally {
                await this.$store.dispatch(
                    "api/refresh",
                    this.$route.meta.resource
                );
            }
        },
    },
};
</script>
<style lang="sass">
$filter-height: 120px
.fba-inbound-shipment-list
    > header
        height: $filter-height !important

        > div
            height: $filter-height !important

            > form
                > div
                    > div:nth-child(2)
                        display: none !important
    > form
        > div
            > div
                > table
                    > tbody
                        > tr
                            td:last-child
                                width: 85px !important
                                label
                                    display: none !important
</style>
