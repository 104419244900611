import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import vuetify from "./plugins/vuetify";
import admin from "./plugins/admin";
import JsonViewer from "vue-json-viewer";
import moment from "moment-timezone";
import { sourceTz } from "@/constants/Time";
import HighchartsVue from "highcharts-vue";

import "./plugins/i18n";
import "./plugins/base";
import "./plugins/chartist";
import "./sass/overrides.sass";

Vue.config.productionTip = false;
Vue.use(require("vue-moment"), {
    moment,
});
Vue.use(JsonViewer);
moment.tz.setDefault(sourceTz);

Vue.use(HighchartsVue);

new Vue({
    router,
    store,
    i18n,
    vuetify,
    admin,
    render: (h) => h(App),
}).$mount("#app");
