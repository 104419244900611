<template>
    <va-form :id="id" :item="item">
        <v-row justify="center">
            <v-col sm="6">
                <base-material-card>
                    <template v-slot:heading>
                        <div class="display-2">
                            {{ title }}
                        </div>
                    </template>
                    <v-card-text>
                        <va-text-input source="name" required></va-text-input>
                        <va-text-input
                            source="client_id"
                            required
                        ></va-text-input>
                        <va-text-input
                            source="client_secret"
                            required
                        ></va-text-input>
                        <va-text-input
                            source="refresh_token"
                            required
                        ></va-text-input>
                        <va-select-input
                            source="region"
                            :choices="AdvertisingRegions"
                            required
                        ></va-select-input>
                        <va-boolean-input
                            source="is_activated"
                        ></va-boolean-input>
                        <va-boolean-input
                            source="sandbox"
                        ></va-boolean-input>
                    </v-card-text>
                    <va-save-button></va-save-button>
                </base-material-card>
            </v-col>
        </v-row>
    </va-form>
</template>

<script>
import AdvertisingRegions from "@/constants/AdvertisingRegions";

export default {
    props: ["id", "title", "item"],
    data() {
        return {
            AdvertisingRegions,
        };
    },
};
</script>
