const ranges = [15, 30, 45, 60];
const StockRemainingDayRanges = ranges.map((value) => ({
    value,
    text: `=< ${value} days`,
}));

StockRemainingDayRanges.unshift({
    value: 0,
    text: "= 0",
});

export default StockRemainingDayRanges;
