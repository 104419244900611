<template>
    <div>
        <va-aside-layout :title="asideTitle">
            <cog-catalog-item-import
                v-show="showImport"
                @saved="onSaved"
                :mwsConfigurationOptions="mwsConfigurationOptions"
                :filter="filter"
            ></cog-catalog-item-import>
            <cog-catalog-item-bulk
                v-show="showBulk"
                @saved="onSaved"
                :mwsConfigurationOptions="mwsConfigurationOptions"
                :filter="filter"
            ></cog-catalog-item-bulk>
            <cog-catalog-item-edit
                v-show="showEdit"
                :id="id"
                :item="item"
                @saved="onSaved"
            ></cog-catalog-item-edit>
        </va-aside-layout>
        <base-material-card :icon="resource.icon" :title="title">
            <v-tabs v-model="selectedTab">
                <v-tab
                    class="mr-3"
                    v-for="tab in mwsConfigurationOptions"
                    :key="tab.value"
                    @click="onClickTab(tab.value)"
                >
                    <v-icon class="mr-2">mdi-amazon</v-icon>
                    {{ tab.text }}
                </v-tab>
            </v-tabs>
            <v-tabs-items v-model="selectedTab" class="transparent">
            </v-tabs-items>
            <va-list
                ref="list"
                :filters="filters"
                :filter.sync="filter"
                disable-export
                disable-create
                class="cog-catalog-item-list"
                :items-per-page="100"
                :items-per-page-options="[100, 200, 300, 500]"
            >
                <template v-slot:actions>
                    <div class="actions-wrapper">
                        <va-action-button
                            label="Import COGs"
                            icon="mdi-upload"
                            text
                            @click="
                                asideTitle = 'Import COGs';
                                showImport = true;
                                showEdit = false;
                                showBulk = false;
                            "
                        ></va-action-button>
                        <!--                        <va-action-button-->
                        <!--                            label="Export COGs"-->
                        <!--                            icon="mdi-download"-->
                        <!--                            text-->
                        <!--                            :loading="isGenerating"-->
                        <!--                            @click="exportCogs"-->
                        <!--                        ></va-action-button>-->
                        <va-action-button
                            label="Export All"
                            icon="mdi-download"
                            text
                            :loading="isGenerating"
                            @click="exportAll"
                        ></va-action-button>
                        <va-action-button
                            label="Bulk update COGs"
                            icon="mdi-upload"
                            text
                            @click="
                                asideTitle = 'Bulk Update Info';
                                showBulk = true;
                                showImport = false;
                                showEdit = false;
                            "
                        ></va-action-button>
                        <!--                        <va-action-button-->
                        <!--                            label="Bulk update COGs template"-->
                        <!--                            icon="mdi-download"-->
                        <!--                            text-->
                        <!--                            :loading="isGenerating"-->
                        <!--                            @click="exportBulkUpdateCogsTemplate"-->
                        <!--                        ></va-action-button>-->
                        <a
                            :href="fileLink"
                            ref="fileLinkElement"
                            class="d-none"
                            :download="exportFileName"
                            >Download exported file.</a
                        >
                    </div>
                </template>
                <va-data-table
                    ref="table"
                    class="main-table"
                    :fields="fields"
                    disable-clone
                    disable-select
                    :disable-delete="disableDelete"
                    disable-show
                    disable-edit-redirect
                    @item-action="onAction"
                >
                    <template
                        v-slot:[`field.AttributeSets[0]`]="{ value, item }"
                    >
                        <img
                            width="75"
                            v-if="value && value.SmallImage"
                            :alt="value.SmallImage.URL"
                            :src="getImage(item, value.SmallImage.URL)"
                        />
                    </template>
                    <template v-slot:[`field.ASIN`]="{ value }">
                        <a
                            target="_blank"
                            :href="'https://amazon.com/dp/' + value"
                            >{{ value }}</a
                        >
                    </template>
                    <template v-slot:[`field.cog`]="{ value, item }">
                        <a @click="openTransactionDialog(item)">{{ value }}</a>
                    </template>
                    <template v-slot:[`field.listing_price`]="{ value, item }">
                        <span>{{
                            item.currentQuantity > 0
                                ? value
                                    ? value
                                    : "None"
                                : "-"
                        }}</span>
                    </template>
                    <template v-slot:[`field.referral_fee`]="{ value, item }">
                        <span>{{
                            item.currentQuantity > 0
                                ? value
                                    ? value
                                    : "None"
                                : "-"
                        }}</span>
                    </template>
                    <template v-slot:[`field.fba_fee`]="{ value, item }">
                        <span>{{
                            item.currentQuantity > 0
                                ? value
                                    ? value
                                    : "None"
                                : "-"
                        }}</span>
                    </template>
                    <template v-slot:[`field.gp`]="{ value, item }">
                        <span>
                            <strong v-if="item.currentQuantity > 0">
                                {{ value ? value : "None" }}
                                {{
                                    value && item.listing_price
                                        ? " (" +
                                          (
                                              (value * 100) /
                                              item.listing_price
                                          ).toFixed(2) +
                                          "%)"
                                        : ""
                                }}
                            </strong>
                            <strong v-else>-</strong>
                        </span>
                    </template>
                    <template v-slot:[`field.barcode_file`]="{ value, item }">
                        <a
                            v-if="value"
                            @click="downloadBarcode(item)"
                            href="javascript:void(0)"
                            >Download</a
                        >
                        <span v-else>No file</span>
                    </template>
                    <template v-slot:[`field.label`]="{ value, item }">
                        <v-chip
                            text-color="white"
                            v-if="value"
                            :color="getLabelColor(value)"
                        >
                            {{ value }}
                        </v-chip>
                        - ({{ parseInt(item.sum_sale_30_day, 10) }})
                    </template>
                    <template v-slot:[`field.tags`]="{ value, item }">
                        <v-chip v-if="item.is_low_price">
                            {{ "LowPrice" }}
                        </v-chip>
                        <v-chip v-if="item.is_neg_gp">
                            {{ "NegGP" }}
                        </v-chip>
                    </template>
                    <template v-slot:[`field.created_at`]="{ value, item }">
                        <v-chip
                            text-color="white"
                            :color="getUploadStatusColor(item)"
                        >
                            {{ value | moment("timezone", sourceTz, "LLL") }}
                        </v-chip>
                        - {{ item.asin_age }}
                    </template>
                </va-data-table>
            </va-list>
            <v-dialog v-model="dialog" @click:outside="closeTransactionDialog">
                <v-card>
                    <v-toolbar dark color="primary">
                        <v-btn icon dark @click="closeTransactionDialog">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title v-if="item">
                            SKU:
                            <a
                                target="_blank"
                                :href="'https://amazon.com/dp/' + item.asin"
                                >{{ item.sku }}</a
                            >
                        </v-toolbar-title>
                    </v-toolbar>
                    <v-list>
                        <v-list-item>
                            <v-list-item-content>
                                <CogCatalogItemTransactionSimpleForm
                                    v-if="dialog"
                                    @afterSave="
                                        afterChangeCogCatalogItemTransaction
                                    "
                                    :cogCatalogItemId="item.id"
                                ></CogCatalogItemTransactionSimpleForm>
                                <v-divider></v-divider>
                                <CogCatalogItemTransactionSimpleList
                                    v-if="dialog && item"
                                    :cogCatalogItemId="item.id"
                                    :currentQuantity="item.currentQuantity"
                                ></CogCatalogItemTransactionSimpleList>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-dialog>
        </base-material-card>
    </div>
</template>

<script>
import MarketplaceIds from "@/constants/MarketplaceIds";
import AdjustCog from "@/resources/financial-transaction/AdjustCog";
import Export from "@/resources/cog-catalog-item/Export";
import SaleInventoryLabels, {
    SaleInventoryLabelColorMap,
} from "@/constants/SaleInventoryLabels";
import UploadStatusColors from "@/constants/UploadStatusColors";
import { tz, sourceTz } from "@/constants/Time";
import { downloadBarcode } from "@/services/zipper";
import { ALL, BULK_UPDATE_COGS } from "@/constants/CogCatalogItemExportTypes";
import { AsinAgeLabels } from "@/constants/AsinAgeTypes";
import debounce from "lodash/debounce";
import CogCatalogTagTypes from "@/constants/CogCatalogItemTagTypes";

export default {
    mixins: [Export],
    components: {AdjustCog}, //eslint-disable-line
    props: ["resource", "title"],
    computed: {
        mwsConfigurationOptions() {
            return this.$store.getters.mwsConfigurationOptions;
        },
    },
    watch: {
        mwsConfigurationId: {
            immediate: true,
            handler(newVal) {
                if (!newVal) {
                    return;
                }
                const current = this.filter["parent_id"] || [];
                if (current.includes(newVal)) {
                    return;
                }
                this.filter["parent_id"] = [newVal];
            },
        },
        mwsConfigurationOptions: {
            immediate: true,
            handler(newVal) {
                if (!newVal || !newVal.length) {
                    return;
                }
                const filter = this.filters.find(
                    (filter) => filter.source === "parent_id"
                );
                filter.attributes.choices = newVal;
                this.findSelectedTab(newVal);
            },
        },
    },
    async mounted() {
        this.$watch(
            () => {
                return this.$refs.list.listState.items;
            },
            () => this.debounceGetSummary()
        );

        this.$root.$on("afterDeleteAndRevert", () => {
            this.afterDeleteAndRevertCogCatalogItemTransaction();
        });
    },
    data() {
        return {
            tz: tz,
            sourceTz: sourceTz,
            selectedTab: 0,
            marketplaceIdKey: "main_marketplace_id",
            disableDelete: false,
            dialog: false,
            hasChanged: false,
            filter: {
                second_marketplace: 0,
            },
            filters: [
                {
                    label: "App Configuration",
                    source: "parent_id",
                    type: "select",
                    attributes: {
                        choices: this.mwsConfigurationOptions,
                        multiple: true,
                    },
                    alwaysOn: true,
                },
                {
                    label: "SKU",
                    source: "CogCatalogItem.sku",
                    // type: "autocomplete",
                    // attributes: {
                    //     itemText: "SellerSKU",
                    //     itemValue: "id",
                    //     multiple: true,
                    //     reference: "CatalogItem",
                    //     minChars: 1,
                    // },
                    alwaysOn: true,
                },
                {
                    label: "FnSKU",
                    source: "CogCatalogItem.fnSku",
                    alwaysOn: true,
                },
                {
                    label: "ASIN",
                    source: "FbaInventory.asin",
                    // type: "autocomplete",
                    // attributes: {
                    //     itemText: "ASIN",
                    //     multiple: true,
                    //     reference: "CatalogItem",
                    //     minChars: 1,
                    // },
                    alwaysOn: true,
                },
                {
                    label: "Supplier",
                    source: "supplier_id",
                    type: "select",
                    attributes: {
                        itemText: "name",
                        multiple: false,
                        reference: "Supplier",
                    },
                    alwaysOn: true,
                },
                {
                    label: "PIC",
                    source: "pic_id",
                    type: "select",
                    attributes: {
                        itemText: "name",
                        multiple: false,
                        reference: "users",
                    },
                    alwaysOn: true,
                },
                // {
                //     source: "quantity",
                //     type: "number",
                //     alwaysOn: true,
                // },
                {
                    label: "COGs",
                    source: "cog",
                    type: "number",
                    alwaysOn: true,
                },
                {
                    source: "marketplace_id",
                    type: "select",
                    attributes: {
                        multiple: true,
                        choices: MarketplaceIds,
                    },
                    alwaysOn: true,
                },
                {
                    source: "SaleInventory.label",
                    type: "select",
                    attributes: {
                        multiple: true,
                        choices: SaleInventoryLabels,
                    },
                    alwaysOn: true,
                },
                {
                    label: "Tags",
                    source: "tags",
                    type: "select",
                    attributes: {
                        multiple: true,
                        choices: CogCatalogTagTypes,
                    },
                    alwaysOn: true,
                },
                {
                    label: "ASIN Age",
                    source: "SaleInventory.asin_age",
                    type: "select",
                    attributes: {
                        multiple: false,
                        choices: AsinAgeLabels,
                    },
                    alwaysOn: true,
                },
            ],
            fields: [
                {
                    label: "Image",
                    source: "AttributeSets[0]",
                    sortable: false,
                },
                {
                    label: "SKU",
                    source: "sku",
                    type: "text",
                    sortable: false,
                },
                {
                    label: "FnSKU",
                    source: "fnSku",
                    type: "text",
                    sortable: false,
                },
                {
                    label: "ASIN",
                    source: "ASIN",
                    sortable: false,
                },
                { label: "Label", source: "label", sortable: false },
                { label: "Tags", source: "tags", sortable: false },
                {
                    label: "Supplier",
                    source: "supplier.name",
                    sortable: false,
                },
                {
                    label: "PIC",
                    source: "pic.name",
                    sortable: false,
                },
                {
                    label: "Original Price",
                    source: "original_price",
                    sortable: true,
                },
                // {
                //     source: "quantity",
                //     sortable: true,
                // },
                {
                    label: "COGs",
                    source: "cog",
                    sortable: true,
                },
                {
                    label: "Quantity",
                    source: "currentQuantity",
                    sortable: true,
                },
                {
                    label: "Total COGs",
                    source: "totalCog",
                    sortable: true,
                },
                {
                    label: "Listing price",
                    source: "listing_price",
                    sortable: true,
                },
                {
                    label: "Referral fee",
                    source: "referral_fee",
                    sortable: true,
                },
                {
                    label: "FBA fee",
                    source: "fba_fee",
                    sortable: true,
                },
                {
                    label: "GP",
                    source: "gp",
                    sortable: true,
                },
                // {
                //     label: "Barcode",
                //     source: "barcode_file",
                //     sortable: false,
                // },
                {
                    label: "Created at",
                    source: "created_at",
                    sortable: true,
                },
            ],
            showImport: false,
            showBulk: false,
            showEdit: false,
            asideTitle: null,
            id: null,
            item: null,
            exportFileName: "cogs.csv",
            debounceGetSummary: debounce(this.updateSummary, 500),
        };
    },
    methods: {
        onClickTab(id) {
            this.filter["parent_id"] = id ? id : "";
            this.asideTitle = null;
            this.showEdit = false;
            this.showImport = false;
            this.showBulk = false;
            if (id) {
                const mwsConfigurationOption =
                    this.mwsConfigurationOptions.find(
                        (mwsConfiguration) => mwsConfiguration.value === id
                    );
                this.filter["marketplace_id"] =
                    mwsConfigurationOption[this.marketplaceIdKey];
            } else {
                this.filter["marketplace_id"] = "";
            }
        },
        async onAction({ action, id, item }) {
            this.asideTitle = `Edit SKU: ${item.sku}`;
            this.id = id;
            this.showEdit = action === "edit";
            this.showImport = false;
            this.showBulk = false;
            this.item = item;
        },
        onSaved() {
            this.asideTitle = null;
            this.$refs.list.fetchData();
        },
        findSelectedTab(tabs) {
            const { filter } = this.$route.query;
            if (!filter) {
                return;
            }
            const currentFilter = JSON.parse(filter);
            if (!currentFilter["parent_id"]) {
                return;
            }
            this.selectedTab = parseInt(currentFilter["parent_id"], 10);
            if (!this.selectedTab) {
                return;
            }
            this.selectedTab = tabs.findIndex(
                (item) => item.value === this.selectedTab
            );
        },
        async downloadBarcode(item) {
            const { sku, AttributeSets, barcode_file } = item;
            const imageURL =
                AttributeSets &&
                AttributeSets[0] &&
                (item.image_file || AttributeSets[0]?.SmallImage.URL);

            return downloadBarcode(sku, barcode_file, imageURL);
        },
        async updateSummary() {
            const { data } = await this.$store.dispatch(
                "CogCatalogItemSummary/getList",
                {
                    filter: this.filter,
                }
            );
            const { currentQuantity, totalCog } = data;
            const thead = this.$refs.table.$el.querySelector("thead");
            const row = thead.querySelector("#total-row") || thead.insertRow();
            row.setAttribute("id", "total-row");
            const rowLength = row.cells.length;
            let totalLabelCell = rowLength ? row.cells[0] : row.insertCell();
            totalLabelCell.innerHTML = "Total";
            totalLabelCell.colSpan = 9;
            let totalQtyCell = rowLength ? row.cells[1] : row.insertCell();
            totalQtyCell.innerHTML = currentQuantity;
            let totalCogCell = rowLength ? row.cells[2] : row.insertCell();
            totalCogCell.innerHTML = totalCog.toFixed(2);
        },
        openTransactionDialog(item) {
            this.item = item;
            this.dialog = true;
        },
        closeTransactionDialog() {
            this.dialog = false;
            this.item = false;
            if (!this.hasChanged) {
                return;
            }
            this.hasChanged = false;
            this.$store.dispatch("api/refresh", this.$route.meta.resource);
        },
        afterChangeCogCatalogItemTransaction() {
            const oldItem = { ...this.item };
            this.item = false;
            this.hasChanged = true;
            setTimeout(() => {
                this.item = oldItem;
            }, 100);
        },
        afterDeleteAndRevertCogCatalogItemTransaction() {
            this.afterChangeCogCatalogItemTransaction();
        },
        async exportCogs() {
            this.exportFileName = "cogs.csv";
            await this.generateLink();
            this.$refs.fileLinkElement.click();
        },
        async exportAll() {
            this.exportFileName = "products.csv";
            await this.generateLink(ALL);
            this.$refs.fileLinkElement.click();
        },
        async exportBulkUpdateCogsTemplate() {
            this.exportFileName = "bulk_update_cogs.csv";
            await this.generateLink(BULK_UPDATE_COGS);
            this.$refs.fileLinkElement.click();
        },
        getLabelColor(label) {
            return SaleInventoryLabelColorMap[label];
        },
        getUploadStatusColor(item) {
            if (
                !item.barcode_file ||
                !item.image_file ||
                !item.original_price
            ) {
                return UploadStatusColors.ONE;
            }
            if (item.barcode_file && item.image_file && item.original_price) {
                return UploadStatusColors.BOTH;
            }
            return UploadStatusColors.NONE;
        },
        getImage(item, defaultImage) {
            if (!item.image_file) {
                return defaultImage;
            }
            return item.image_file;
        },
    },
};
</script>
<style lang="sass">
$filter-height: 150px
.cog-catalog-item-list
    > header
        height: $filter-height !important

        > div
            height: $filter-height !important

            > form
                > div
                    > div:nth-child(2)
                        display: none !important

            > header
                > div
                    > form
                        > div
                            > div:nth-child(2)
                                display: none !important

    > form
        > div
            > div
                > table
                    > thead
                        > tr#total-row
                            td
                                font-weight: bold
                                text-align: right !important

                            td:first-child
                                text-align: left !important

                        > tr
                            > th:nth-child(1)
                                width: 116px

                            > th:last-child
                                width: 50px

                    > tbody
                        > tr
                            > td:nth-child(1)
                                button
                                    background: transparent !important

                            > td:nth-child(8), td:nth-child(9), td:nth-child(10), td:nth-child(11), td:nth-child(12), td:nth-child(13), td:nth-child(14), td:nth-child(15)
                                text-align: right !important
</style>
