const types = [
    "AdjustmentEvent",
    "AffordabilityExpenseEvent",
    "AffordabilityExpenseReversalEvent",
    "ChargebackEvent",
    "CouponPaymentEvent",
    "DebtRecoveryEvent",
    "FBALiquidationEvent",
    "GuaranteeClaimEvent",
    "ImagingServicesFeeEvent",
    "LoanServicingEvent",
    "NetworkComminglingTransactionEvent",
    "PayWithAmazonEvent",
    "ProductAdsPaymentEvent",
    "RefundEvent",
    "RentalTransactionEvent",
    "RetrochargeEvent",
    "SAFETReimbursementEvent",
    "SellerDealPaymentEvent",
    "SellerReviewEnrollmentPaymentEvent",
    "ServiceFeeEvent",
    "ServiceProviderCreditEvent",
    "ShipmentEvent",
    "SolutionProviderCreditEvent",
];
const FinancialEventTypes = types.map((value) => ({
    value,
    text: value,
}));

export default FinancialEventTypes;
