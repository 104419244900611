<template>
    <va-form
        :id="id"
        :item="item"
        v-model="model"
        disable-redirect
        @saved="$emit('saved')"
    >
        <va-text-input
            label="App ID"
            source="parent_id"
            v-show="false"
        ></va-text-input>
        <va-text-input source="quantity" v-show="false"></va-text-input>
        <va-text-field source="sku" v-show="false"></va-text-field>
        <va-text-input label="COGs" source="cog" v-show="false"></va-text-input>
        <va-select-input
            reference="Supplier"
            label="Supplier"
            source="supplier_id"
            model="supplier_id"
            item-value="id"
            item-text="name"
        ></va-select-input>
        <va-select-input
            reference="users"
            label="PIC"
            source="pic_id"
            model="pic_id"
            item-value="id"
            item-text="name"
            :filter="{ active: true }"
        ></va-select-input>
        <va-number-input
            label="Original Price"
            source="original_price"
        ></va-number-input>
        <va-text-input multiline label="Note" source="note"></va-text-input>
        <v-file-input
            counter
            :value="model.barcode_file"
            @change="selectBarcodeFile"
            accept=".pdf,.jpg"
            label="Barcode file"
        ></v-file-input>
        <div class="d-flex flex-column justify-space-between align-center">
            <v-img
                v-if="preview_barcode_file && !barcodeFilePdf"
                max-width="350"
                :src="preview_barcode_file"
            ></v-img>
            <a
                v-if="barcodeFilePdf"
                target="_blank"
                :href="preview_barcode_file"
                >View</a
            >
            <vue-pdf-embed
                v-if="barcodeFilePdf"
                width="350"
                :source="barcodeFilePdf"
            />
        </div>
        <v-file-input
            counter
            :value="model.image_file"
            @change="selectImageFile"
            accept=".png,.jpg"
            label="Image file"
        ></v-file-input>
        <div class="d-flex flex-column justify-space-between align-center">
            <v-img
                v-if="preview_image_file"
                max-width="350"
                :src="preview_image_file"
            ></v-img>
        </div>
        <va-save-button></va-save-button>
    </va-form>
</template>

<script>
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";

export default {
    components: {
        VuePdfEmbed,
    },
    props: ["id", "title", "item"],
    mounted() {
        this.$watch(
            () => {
                return this.item;
            },
            (newItem, oldItem) => {
                if (!newItem?.id) {
                    return;
                }
                if (!oldItem?.id) {
                    return;
                }
                if (oldItem.id === newItem.id) {
                    return;
                }
                this.setBarcodeFile(newItem);
                this.setImageFile(newItem);
            }
        );
        this.setBarcodeFile(this.item);
        this.setImageFile(this.item);
    },
    data() {
        return {
            filter: {},
            model: {
                barcode_file: null,
                image_file: null,
            },
            preview_barcode_file: null,
            barcodeFilePdf: null,
            preview_image_file: null,
        };
    },
    methods: {
        setBarcodeFile(item) {
            if (!item || (!item.barcode_link && !item.barcode_file)) {
                this.model.barcode_file = null;
                this.preview_barcode_file = null;
                this.barcodeFilePdf = null;
                return;
            }
            const filePath = item.barcode_link || item.barcode_file;
            this.model.barcode_file = new File([""], filePath);
            this.preview_barcode_file = filePath;
            if (!filePath.includes("pdf")) {
                this.barcodeFilePdf = null;
                return;
            }
            this.barcodeFilePdf = filePath;
        },
        setImageFile(item) {
            if (!item || (!item.image_link && !item.image_file)) {
                this.model.image_file = null;
                this.preview_image_file = null;
                return;
            }
            const filePath = item.image_link || item.image_file;
            this.model.image_file = new File([""], filePath);
            this.preview_image_file = filePath;
        },
        async selectBarcodeFile(file) {
            this.model.barcode_file = file;
            if (file.type !== "application/pdf") {
                this.preview_barcode_file = URL.createObjectURL(file);
                this.barcodeFilePdf = null;
                return;
            }
            this.preview_barcode_file = null;
            this.barcodeFilePdf = await this.toBase64(file);
        },
        selectImageFile(file) {
            this.model.image_file = file;
            this.preview_image_file = URL.createObjectURL(file);
        },
        toBase64: (file) =>
            new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            }),
    },
};
</script>
