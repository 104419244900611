<template>
    <va-form :id="id" :item="item">
        <va-select-input
            label="App"
            reference="MwsConfiguration"
            source="parent_id"
        ></va-select-input>
        <va-autocomplete-input
            reference="CatalogItem"
            label="Product"
            source="sku"
            model="sku"
            item-value="SellerSKU"
            item-text="SellerSKU"
            :min-chars="1"
            :filter.sync="filter"
        ></va-autocomplete-input>
        <va-text-input source="quantity"></va-text-input>
        <va-text-input label="COG" source="cog"></va-text-input>
        <va-save-button></va-save-button>
    </va-form>
</template>

<script>
export default {
    props: ["id", "title", "item"],
    data() {
        return {
            filter: {},
        };
    },
};
</script>
