import moment from "moment-timezone";
import get from "lodash/get";

const AsinAgeTypes = [
    { value: 0, text: "All" },
    { value: 90, text: "New90" },
    { value: 180, text: "New180" },
    { value: 365, text: "New365" },
    { value: 366, text: "Over1Y" },
];

export const AsinAgeLabels = [
    { value: "New90", text: "New90" },
    { value: "New180", text: "New180" },
    { value: "New365", text: "New365" },
    { value: "Over1Y", text: "Over1Y" },
];

export function getAsinAge(item, field = "created_at") {
    const createdAt = moment(get(item, field));
    const diffDays = Math.abs(createdAt.diff(moment(), "days"));
    const asinAge = AsinAgeTypes.find((item) => diffDays <= item.value);
    return asinAge?.text || "Over1Y";
}

export default AsinAgeTypes;
