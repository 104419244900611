<template>
    <v-container fill-height fluid>
        <v-row align="center" justify="center">
            <v-col>
                <v-file-input
                    dense
                    counter
                    show-size
                    accept=".csv,.xlsx"
                    @change="onFileInput($event)"
                ></v-file-input>
                <a
                    :href="sampleFileLink"
                    class="text-decoration-none"
                    download="sample.csv"
                >
                    {{
                        sampleFileLink === "#"
                            ? "Generating sample file...."
                            : "Download sample file."
                    }}
                </a>
                <va-date-input
                    v-bind="dateRanges"
                    source="from"
                    required
                    @input="updateDateRanges($event, 'from')"
                ></va-date-input>
                <va-date-input
                    v-bind="dateRanges"
                    source="to"
                    required
                    @input="updateDateRanges($event, 'to')"
                ></va-date-input>
            </v-col>
        </v-row>
        <v-row align="center" justify="center">
            <v-col class="text-center">
                <va-action-button
                    :loading="isUploading"
                    @click="upload"
                    label="Upload"
                    icon="mdi-upload"
                ></va-action-button>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Import from "@/resources/cog-catalog-item/Import";
import moment from "moment-timezone";
import {tz} from "@/constants/Time";

export default {
    mixins: [Import],
    data() {
        return {
            isUploading: false,
            rows: [],
            file: null,
            dateRanges: {
                from: moment().tz(tz).startOf("day"),
                to: moment().tz(tz).endOf("day"),
            },
            sampleFileLink: "#",
        };
    },
    methods: {
        updateDateRanges(newDate, type) {
            if (type === "from") {
                this.dateRanges.from = moment(newDate).tz(tz).startOf("day");
                return;
            }
            this.dateRanges.to = moment(newDate).tz(tz).endOf("day");
        },
        async upload() {
            this.isUploading = true;
            try {
                await this.$store.dispatch("FinancialTransaction/create", {
                    data: {
                        file: this.file,
                        from: this.dateRanges.from.format(
                            "YYYY-MM-DD 00:00:00"
                        ),
                        to: this.dateRanges.to.format("YYYY-MM-DD 23:59:59"),
                    },
                });
            } catch (e) {
                console.log(e);
            } finally {
                this.isUploading = false;
                this.$emit("saved");
            }
        },
    },
};
</script>
