<template>
    <va-form
        :id="id"
        :item="item"
        v-model="model"
        disable-redirect
        @saved="$emit('saved')"
    >
        <va-select-input
            label="Brand"
            source="mws_configuration_id"
            :choices="mwsConfigurationMapOptions"
            required
        ></va-select-input>
        <va-select-input
            label="MarketplaceId"
            source="marketplace_id"
            :choices="MarketplaceIds"
            required
        ></va-select-input>
        <va-text-input source="parent_id"></va-text-input>
        <va-text-input source="asin" required></va-text-input>
        <va-text-input source="sellerSku"></va-text-input>
        <va-text-input source="fnSku"></va-text-input>
        <va-text-input source="small_image.URL"></va-text-input>
        <va-number-input source="Quantity_NewItem"></va-number-input>
        <va-boolean-input source="is_archived"></va-boolean-input>
        <va-save-button></va-save-button>
    </va-form>
</template>

<script>
import MarketplaceIds from "@/constants/MarketplaceIds";

export default {
    props: ["id", "item", "mwsConfigurationId", "marketplaceIdKey"],
    computed: {
        mwsConfigurationOptions() {
            return this.$store.getters.mwsConfigurationOptions;
        },
        MarketplaceIds() {
            return MarketplaceIds;
        },
    },
    watch: {
        mwsConfigurationOptions: {
            immediate: true,
            handler(newVal = []) {
                if (!newVal || !newVal.length) {
                    return;
                }
                this.mwsConfigurationMapOptions = newVal.map((option) => {
                    return {
                        value: parseInt(option.value, 10),
                        text: option.text,
                    };
                });
            },
        },
        mwsConfigurationId: {
            immediate: true,
            handler(newVal) {
                if (!newVal) {
                    return;
                }
                this.model.mws_configuration_id = parseInt(newVal, 10);
            },
        },
        marketplaceIdKey: {
            immediate: true,
            handler(newVal) {
                if (!newVal) {
                    return;
                }
                const mwsConfiguration = this.mwsConfigurationOptions.find(
                    (option) => option.value === this.model.mws_configuration_id
                );
                if (!mwsConfiguration) {
                    return;
                }
                this.model.marketplace_id =
                    mwsConfiguration[this.marketplaceIdKey];
            },
        },
    },
    data() {
        return {
            mwsConfigurationMapOptions: {},
            model: {},
        };
    },
    methods: {
        hasRole(role) {
            return (this.model.roles || []).includes(role);
        },
    },
};
</script>
