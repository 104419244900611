import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        mwsConfigurationOptions: [{ text: "All", value: 0 }],
    },
    getters: {
        mwsConfigurationOptions(state) {
            return state.mwsConfigurationOptions;
        },
        "auth/getMwsConfigurationIds"(state) {
            return state.auth.user.mws_configuration_ids;
        },
    },
    mutations: {
        mwsConfigurationOptions(state, payload) {
            state.mwsConfigurationOptions = [
                { text: "All", value: 0 },
                ...payload.mwsConfigurationOptions,
            ];
            return state.mwsConfigurationOptions;
        },
    },
    actions: {
        setMwsConfigurationOptions(context, payload) {
            context.commit("mwsConfigurationOptions", payload);
        },
    },
    modules: {},
});
