var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('va-aside-layout',{attrs:{"title":_vm.asideTitle}},[(_vm.show)?_c('fba-inventory-form',{attrs:{"id":_vm.id,"item":_vm.item,"mwsConfigurationId":_vm.filter.mws_configuration_id,"marketplaceIdKey":_vm.marketplaceIdKey},on:{"saved":_vm.onSaved}}):_vm._e()],1),_c('base-material-card',{attrs:{"icon":_vm.resource.icon,"title":_vm.title}},[_c('v-tabs',{model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},_vm._l((_vm.mwsConfigurationOptions),function(tab){return _c('v-tab',{key:tab.value,staticClass:"mr-3",on:{"click":function($event){return _vm.onClickTab(tab.value)}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-amazon")]),_vm._v(" "+_vm._s(tab.text)+" ")],1)}),1),_c('v-tabs-items',{staticClass:"transparent",model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}}),_c('va-list',{ref:"list",staticClass:"fba-inventory-list",attrs:{"filters":_vm.filters,"disable-export":"","disable-create-redirect":"","filter":_vm.filter},on:{"action":_vm.onAction,"update:filter":function($event){_vm.filter=$event}}},[_c('va-data-table',{ref:"table",staticClass:"fba-inventory-main-table",attrs:{"fields":_vm.fields,"disable-clone":"","disable-select":"","disable-edit":"","disable-delete":_vm.disableDelete,"disable-show":"","disable-edit-redirect":"","show-expand":"","single-expand":""},scopedSlots:_vm._u([{key:"field.asin",fn:function(ref){
var value = ref.value;
return [_c('a',{attrs:{"target":"_blank","href":'https://amazon.com/dp/' + value}},[_vm._v(_vm._s(value))])]}},{key:"field.small_image",fn:function(ref){
var value = ref.value;
return [_c('img',{attrs:{"width":"75","alt":"small_image","src":value ? value.URL : ''}})]}},{key:"field.is_archived",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"false-value":false,"true-value":true,"input-value":item.is_archived},on:{"change":function (value) { return _vm.changeIsArchived(item.id, value); }}})]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [(item.children.length)?_c('v-data-table',{staticClass:"fba-inventory-item-headers",attrs:{"hide-default-header":"","hide-default-footer":"","headers":_vm.item_headers,"items":item.children},scopedSlots:_vm._u([{key:"item.is_archived",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"false-value":false,"true-value":true,"input-value":item.is_archived},on:{"change":function (value) { return _vm.changeIsArchived(item.id, value); }}})]}}],null,true)}):_vm._e()]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }