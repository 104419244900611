<template>
    <v-container fill-height fluid>
        <v-row align="center" justify="center">
            <v-col>
                <v-file-input
                    dense
                    counter
                    show-size
                    accept=".csv,.xlsx"
                    @change="onFileInput($event)"
                ></v-file-input>
                <a
                    :href="fileLink"
                    class="text-decoration-none"
                    download="cogs.csv"
                >
                    {{
                        fileLink === "#"
                            ? "Generating file...."
                            : "Download exported file."
                    }}
                </a>
            </v-col>
        </v-row>
        <v-row align="center" justify="center">
            <v-col class="text-center">
                <va-action-button
                    :loading="isUploading"
                    @click="upload"
                    label="Upload"
                    icon="mdi-upload"
                ></va-action-button>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Export from "@/resources/cog-catalog-item/Export";

export default {
    props: ["mwsConfigurationOptions", "filter"],
    mixins: [Export],
    beforeMount() {
        this.generateLink();
    },
    data() {
        return {
            isUploading: false,
            rows: [],
        };
    },
    methods: {
        onFileInput(file) {
            this.file = file;
            let reader = new FileReader();
            reader.readAsText(file, "UTF-8");
            reader.onload = (evt) => {
                this.rows = this.csvToArray(evt.target.result);
            };
            reader.onerror = function (evt) {
                console.log(evt, "error reading file");
            };
        },
        async upload() {
            this.isUploading = true;
            try {
                const { errors } = await this.$store.dispatch(
                    "CogCatalogItem/create",
                    {
                        data: {
                            file: this.file,
                        },
                    }
                );
                if (errors.length) {
                    const errorMessage = `Error at lines: ${errors.join(", ")}`;
                    this.$admin.toast.error(errorMessage);
                }
            } catch (e) {
                console.log(e);
            } finally {
                this.isUploading = false;
                this.$emit("saved");
            }
        },
    },
};
</script>
