<template>
    <div>
        <v-data-table
            ref="table"
            hide-default-footer
            class="report-items-table"
            :headers="headers"
            :items="items"
        >
        </v-data-table>
    </div>
</template>

<script>
export default {
    props: ["items"],
    watch: {
        items: {
            immediate: true,
            handler(newVal = []) {
                if (!newVal || !newVal.length) {
                    return;
                }
                this.headers = Object.keys(this.items[0]).map((key) => {
                    return {
                        text: key,
                        value: key,
                        sortable: true,
                    };
                });
            },
        },
    },
    data: () => ({
        headers: [],
    }),
    beforeMount() {},
    methods: {},
};
</script>
<style lang="sass">

.report-items-table
    table
        thead
            tr#total-row
                td
                    font-weight: bold
</style>
