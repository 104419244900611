<template>
    <base-material-card :icon="resource.icon" :title="title">
        <va-list :filters="filters">
            <va-data-table :fields="fields"> </va-data-table>
        </va-list>
    </base-material-card>
</template>

<script>
export default {
    props: ["resource", "title"],
    data() {
        return {
            filters: [
                "id",
                { source: "name", type: "text" },
                { source: "countryCode", type: "text" },
                { source: "defaultCurrencyCode", type: "text" },
                { source: "defaultLanguageCode", type: "text" },
                { source: "domainName", type: "text" },
                { source: "created_at", type: "date" },
                { source: "updated_at", type: "date" },
            ],
            fields: [
                { source: "id", type: "text", sortable: true },
                { source: "name", type: "text", sortable: true },
                { source: "countryCode", type: "text", sortable: true },
                { source: "defaultCurrencyCode", type: "text", sortable: true },
                { source: "defaultLanguageCode", type: "text", sortable: true },
                { source: "domainName", type: "text", sortable: true },
                { source: "created_at", type: "date", sortable: true },
                { source: "updated_at", type: "date", sortable: true },
            ],
        };
    },
};
</script>
