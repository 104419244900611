const MarketplaceIds = [
    {
        value: "A2ZV50J4W1RKNI",
        text: "US: Non-Amazon",
    },
    {
        value: "ATVPDKIKX0DER",
        text: "US: Amazon.com",
    },
    {
        value: "AHRY1CZE9ZY4H",
        text: "US: Amazon.com Invoicing Shadow Marketplace",
    },
    {
        value: "A1AM78C64UM0Y8",
        text: "MX: Amazon.com.mx",
    },
    {
        value: "A3H6HPSLHAK3XG",
        text: "MX: Non-Amazon",
    },
    {
        value: "A2Q3Y263D00KWC",
        text: "Amazon.com.br",
    },
    {
        value: "A1MQXOICRS2Z7M",
        text: "CA: SI CA Prod Marketplace",
    },
    {
        value: "A2EUQ1WTGCTBG2",
        text: "CA: Amazon.ca",
    },
    {
        value: "A1F83G8C2ARO7P",
        text: "GB: Amazon.co.uk",
    },
    {
        value: "AZMDEXL2RVFNN",
        text: "GB: SI UK Prod Marketplace",
    },
    {
        value: "A1ZFFQZ3HTUKT9",
        text: "FR: Non-Amazon",
    },
    {
        value: "A13V1IB3VIYZZH",
        text: "FR: Amazon.fr",
    },
    {
        value: "A62U237T8HV6N",
        text: "IT: SI Prod IT Marketplace",
    },
    {
        value: "APJ6JRA9NG5V4",
        text: "IT: Amazon.it",
    },
    {
        value: "A1RKKUPIHCS9HS",
        text: "ES: Amazon.es",
    },
    {
        value: "AFQLKURYRPEL8",
        text: "ES: SI Prod ES Marketplace",
    },
    {
        value: "A1PA6795UKMFR9",
        text: "DE: Amazon.de",
    },
    {
        value: "A1805IZSGTT6HS",
        text: "NL: Amazon.nl",
    },
    {
        value: "A1C3SOZRARQ6R3",
        text: "PL: Amazon.pl",
    },
    {
        value: "A2NODRKZP88ZB9",
        text: "SE: Amazon.se",
    },
    {
        value: "A38D8NSA03LJTC",
        text: "DE: Non-Amazon",
    },
];

export const vTableHeaders = MarketplaceIds.map((item) => ({
    text: item.text,
    value: item.value,
    sortable: true,
}));

export default MarketplaceIds;
