<template>
    <base-material-card :icon="resource.icon" :title="title">
        <v-tabs
            v-model="selectedTab"
            @change="
                filter['parent.id'] = mwsConfigurationOptions[$event].value
            "
        >
            <v-tab
                class="mr-3"
                v-for="tab in mwsConfigurationOptions"
                :key="tab.value"
            >
                <v-icon class="mr-2">mdi-amazon</v-icon>
                {{ tab.text }}
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="selectedTab" class="transparent"> </v-tabs-items>
        <va-list
            class="mws-synchronization-list"
            :filters="filters"
            :filter.sync="filter"
            :sort-by.sync="sort.by"
            :sort-desc.sync="sort.desc"
            disable-global-search
            disable-create
            disable-export
        >
            <va-data-table :fields="fields" disable-actions disable-select>
                <template v-slot:item.additional_data="{ item }">
                    <json-viewer
                        :value="item.additional_data"
                        :expand-depth="5"
                        copyable
                    ></json-viewer>
                </template>
            </va-data-table>
        </va-list>
    </base-material-card>
</template>

<script>
import MwsSynchronizationStatuses from "@/constants/MwsSynchronizationStatuses";

export default {
    props: ["resource", "title"],
    computed: {
        mwsConfigurationOptions() {
            return this.$store.getters.mwsConfigurationOptions;
        },
    },
    watch: {
        mwsConfigurationId: {
            immediate: true,
            handler(newVal) {
                if (!newVal) {
                    return;
                }
                const current = this.filter["parent.id"] || [];
                if (current.includes(newVal)) {
                    return;
                }
                this.filter["parent.id"] = [newVal];
            },
        },
        mwsConfigurationOptions: {
            immediate: true,
            handler(newVal) {
                if (!newVal || !newVal.length) {
                    return;
                }
                const filter = this.filters.find(
                    (filter) => filter.source === "parent.id"
                );
                filter.attributes.choices = newVal;
            },
        },
    },
    mounted() {},
    data() {
        return {
            selectedTab: 0,
            filter: {},
            sort: {
                by: ["before"],
                desc: [true],
            },
            filters: [
                {
                    label: "App",
                    source: "parent.id",
                    type: "select",
                    attributes: {
                        choices: [],
                        multiple: true,
                    },
                    alwaysOn: true,
                },
                {
                    source: "status",
                    type: "select",
                    attributes: {
                        choices: MwsSynchronizationStatuses,
                        multiple: true,
                    },
                    alwaysOn: true,
                },
                { source: "before", type: "date", alwaysOn: true },
                { source: "after", type: "date", alwaysOn: true },
            ],
            fields: [
                { source: "parent.name", sortable: true },
                { source: "status", sortable: true },
                { source: "before", type: "date", sortable: true },
                { source: "after", type: "date", sortable: true },
                { source: "additional_data", type: "text", sortable: false },
            ],
        };
    },
    methods: {},
};
</script>

<style>
#app
    > div
    > main
    > div
    > div
    > div.container.flex.container--fluid
    > div
    > div.d-flex.grow.flex-wrap,
#app
    > div
    > main
    > div
    > div
    > div.container.flex.container--fluid
    > div
    > div.v-data-iterator.mws-synchronization-list
    > header
    > div
    > form
    > div
    > div:nth-child(1) {
    display: none !important;
}
#app > div > main > div > div > div.container.flex.container--fluid > div {
    margin-top: 0;
}
</style>
