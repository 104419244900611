import * as zip from "@zip.js/zip.js/dist/zip.min";

const baseURL = process.env.VUE_APP_API_URL;

function getImageUrl(item) {
    if (item.image_link) {
        return item.image_link;
    }

    if (item.image_file) {
        return item.image_file;
    }

    const { AttributeSets } = item;

    return (
        AttributeSets && AttributeSets[0] && AttributeSets[0]?.SmallImage.URL
    );
}

export async function downloadBarcode(label, item) {
    try {
        const zipWriter = new zip.ZipWriter(
            new zip.Data64URIWriter("application/zip")
        );
        const imageURL = getImageUrl(item);
        if (imageURL) {
            await addFileToZipper(
                zipWriter,
                imageURL + "?" + Math.random() * 100000000000000000,
                label
            );
        }

        if (item.barcode_link || item.barcode_file) {
            const barcodeFileUrl = item.barcode_link
                ? item.barcode_link
                : baseURL + item.barcode_file;
            await addFileToZipper(
                zipWriter,
                barcodeFileUrl,
                label + "_barcode"
            );
        }
        const dataURI = await zipWriter.close();
        const anchor = document.createElement("a");
        const clickEvent = new MouseEvent("click");
        anchor.href = dataURI;
        anchor.download = label + ".zip";
        anchor.dispatchEvent(clickEvent);
        // window.open(dataURI);
    } catch (e) {
        console.log(e);
    }
}

export async function downloadBarcodes(
    label,
    items = [],
    additionalFiles = []
) {
    try {
        const zipWriter = new zip.ZipWriter(
            new zip.Data64URIWriter("application/zip")
        );

        await Promise.all(
            items.map(async (item) => {
                const { asin, sku, barcode_file } = item;
                const filePath = `${asin}/${sku}`;
                const imageURL = getImageUrl(item);

                if (imageURL) {
                    await addFileToZipper(
                        zipWriter,
                        imageURL + "?" + Math.random() * 100000000000000000,
                        filePath
                    );
                }
                if (barcode_file) {
                    const barcodeFileUrl = baseURL + barcode_file;
                    await addFileToZipper(
                        zipWriter,
                        barcodeFileUrl,
                        filePath + "_barcode"
                    );
                }
            })
        );

        if (additionalFiles.length) {
            await Promise.all(
                additionalFiles.map(async (file) => {
                    const { fileName, ext, blob } = file;
                    await zipWriter.add(
                        `${fileName}.${ext}`,
                        new zip.BlobReader(blob)
                    );
                })
            );
        }

        const dataURI = await zipWriter.close();
        const anchor = document.createElement("a");
        const clickEvent = new MouseEvent("click");
        anchor.href = dataURI;
        anchor.download = label + ".zip";
        anchor.dispatchEvent(clickEvent);
        // window.open(dataURI);
    } catch (e) {
        console.log(e);
    }
}

export async function addFileToZipper(zipper, url, fileName) {
    let ext = url.split("?").shift();
    ext = ext.split(".").pop();
    const res = await fetch(url);
    const blob = await res.blob();
    await zipper.add(`${fileName}.${ext}`, new zip.BlobReader(blob));
}
