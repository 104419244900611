export const US = "US";
export const CA = "CA";
export const MX = "MX";
export const EU = "EU";
export const BR = "BR";
export const NonAmazon = "Non-Amazon";
export const REFUND = "Refund";

const Marketplaces = [
    {
        ids: ["ATVPDKIKX0DER"],
        value: US,
        text: US,
    },
    {
        ids: ["A2EUQ1WTGCTBG2"],
        value: CA,
        text: CA,
    },
    {
        ids: ["A1AM78C64UM0Y8"],
        value: MX,
        text: MX,
    },
    {
        ids: ["A2Q3Y263D00KWC"],
        value: BR,
        text: BR,
    },
    {
        ids: [
            "A2ZV50J4W1RKNI",
            "AHRY1CZE9ZY4H",
            "A3H6HPSLHAK3XG",
            "A1MQXOICRS2Z7M",
            "AZMDEXL2RVFNN",
            "A1ZFFQZ3HTUKT9",
            "A62U237T8HV6N",
            "AFQLKURYRPEL8",
            "A38D8NSA03LJTC",
        ],
        value: NonAmazon,
        text: NonAmazon,
    },
    {
        ids: [REFUND],
        value: REFUND,
        text: REFUND,
    },
];

export const vTableHeaders = Marketplaces.map((item) => ({
    text: item.text,
    value: item.value,
    sortable: true,
}));

export const idsMapMarketplaces = {};

Marketplaces.forEach((item) => {
    item.ids.forEach((id) => {
        idsMapMarketplaces[id] = item.value;
    });
});

export default Marketplaces;
