<template>
    <va-form :id="id" :item="item">
        <v-row justify="center">
            <v-col sm="6">
                <base-material-card>
                    <template v-slot:heading>
                        <div class="display-2">
                            {{ title }}
                        </div>
                    </template>
                    <v-card-text>
                        <va-text-input source="AmazonOrderId"></va-text-input>
                        <va-date-input source="PurchaseDate"></va-date-input>
                        <va-date-input source="LastUpdateDate"></va-date-input>
                        <va-text-input source="OrderStatus"></va-text-input>
                        <va-text-input
                            source="FulfillmentChannel"
                        ></va-text-input>
                        <va-text-input source="SalesChannel"></va-text-input>
                        <va-text-input source="OrderChannel"></va-text-input>
                        <va-text-input
                            source="OrderTotal_Amount"
                        ></va-text-input>
                        <va-text-input
                            source="NumberOfItemsShipped"
                        ></va-text-input>
                        <va-text-input
                            source="NumberOfItemsUnshipped"
                        ></va-text-input>
                        <va-text-input source="PaymentMethod"></va-text-input>
                        <va-text-input
                            source="PaymentMethodDetails"
                        ></va-text-input>
                        <va-text-input source="ReplacedOrderId"></va-text-input>
                        <va-text-input source="BuyerEmail"></va-text-input>
                        <va-text-input source="BuyerName"></va-text-input>
                        <va-text-input source="OrderType"></va-text-input>
                        <va-text-input
                            source="PurchaseOrderNumber"
                        ></va-text-input>
                        <va-date-input
                            source="EarliestShipDate"
                        ></va-date-input>
                        <va-date-input source="LatestShipDate"></va-date-input>
                        <va-date-input
                            source="EarliestDeliveryDate"
                        ></va-date-input>
                        <va-date-input
                            source="LatestDeliveryDate"
                        ></va-date-input>
                        <va-date-input
                            source="PromiseResponseDueDate"
                        ></va-date-input>
                    </v-card-text>
                    <va-save-button></va-save-button>
                </base-material-card>
            </v-col>
        </v-row>
    </va-form>
</template>

<script>
export default {
    props: ["id", "title", "item"],
};
</script>
