import moment from "moment-timezone";

let today = new Date();
today.setHours(0, 0, 0, 0);
let todayEnd = new Date();
todayEnd.setHours(11, 59, 59, 999);
let yesterdayStart = new Date();
yesterdayStart.setDate(today.getDate() - 1);
yesterdayStart.setHours(0, 0, 0, 0);
let yesterdayEnd = new Date();
yesterdayEnd.setDate(today.getDate() - 1);
yesterdayEnd.setHours(11, 59, 59, 999);
let startOfWeek = moment().startOf("week").startOf("day").toDate();
let endOfWeek = moment().endOf("week").endOf("day").toDate();
let startOfLastWeek = moment()
    .subtract(1, "week")
    .startOf("week")
    .startOf("day")
    .toDate();
let endOfLastWeek = moment()
    .subtract(1, "week")
    .endOf("week")
    .endOf("day")
    .toDate();
let thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
let thisMonthEnd = new Date(
    today.getFullYear(),
    today.getMonth() + 1,
    0,
    11,
    59,
    59,
    999
);
const currentQuarter = moment().quarter();
let startOfQuarter = moment()
    .quarter(currentQuarter)
    .startOf("quarter")
    .startOf("day")
    .toDate();
let endOfQuarter = moment()
    .quarter(currentQuarter)
    .endOf("quarter")
    .endOf("day")
    .subtract(1, "day")
    .toDate();
let startOfLastQuarter = moment()
    .quarter(currentQuarter - 1)
    .startOf("quarter")
    .startOf("day")
    .toDate();
let endOfLastQuarter = moment()
    .quarter(currentQuarter - 1)
    .endOf("quarter")
    .endOf("day")
    .subtract(1, "day")
    .toDate();
let startOfLastYear = moment()
    .subtract(1, "year")
    .startOf("year")
    .startOf("day")
    .toDate();
let endOfLastYear = moment()
    .subtract(1, "year")
    .endOf("year")
    .endOf("day")
    .toDate();

export const DateRanges = {
    Today: [today, todayEnd],
    Yesterday: [yesterdayStart, yesterdayEnd],
    "This week": [startOfWeek, endOfWeek],
    "Last week": [startOfLastWeek, endOfLastWeek],
    "This month": [thisMonthStart, thisMonthEnd],
    "Last month": [
        new Date(today.getFullYear(), today.getMonth() - 1, 1),
        new Date(today.getFullYear(), today.getMonth(), 0, 11, 59, 59, 999),
    ],
    "This quarter": [startOfQuarter, endOfQuarter],
    "Last quarter": [startOfLastQuarter, endOfLastQuarter],
    "This year": [
        new Date(today.getFullYear(), 0, 1),
        new Date(today.getFullYear(), 11, 31, 11, 59, 59, 999),
    ],
    "Last year": [startOfLastYear, endOfLastYear],
};

export default DateRanges;
