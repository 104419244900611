<template>
    <va-show :item="item">
        <va-field source="AmazonOrderId"></va-field>
        <va-field source="PurchaseDate" type="date"></va-field>
        <va-field source="LastUpdateDate" type="date"></va-field>
        <va-field source="OrderStatus"></va-field>
        <va-field source="FulfillmentChannel"></va-field>
        <va-field source="SalesChannel"></va-field>
        <va-field source="OrderChannel"></va-field>
        <va-field source="OrderTotal_Amount"></va-field>
        <va-field source="NumberOfItemsShipped"></va-field>
        <va-field source="NumberOfItemsUnshipped"></va-field>
        <va-field source="PaymentMethod"></va-field>
        <va-field source="PaymentMethodDetails"></va-field>
        <va-field source="ReplacedOrderId"></va-field>
        <va-field source="BuyerEmail"></va-field>
        <va-field source="BuyerName"></va-field>
        <va-field source="OrderType"></va-field>
        <va-field source="PurchaseOrderNumber"></va-field>
        <va-field source="EarliestShipDate" type="date"></va-field>
        <va-field source="LatestShipDate" type="date"></va-field>
        <va-field source="EarliestDeliveryDate" type="date"></va-field>
        <va-field source="LatestDeliveryDate" type="date"></va-field>
        <va-field source="PromiseResponseDueDate" type="date"></va-field>
    </va-show>
</template>

<script>
export default {
    props: ["title", "item"],
};
</script>
