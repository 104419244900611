<template>
    <base-material-card :icon="resource.icon" :title="title">
        <va-list :filters="filters">
            <va-data-table
                :fields="fields"
                :disable-clone="true"
                :disable-edit="true"
            >
            </va-data-table>
        </va-list>
    </base-material-card>
</template>

<script>
import FinancialEventStatuses from "@/constants/FinancialEventStatuses";

export default {
    props: ["resource", "title"],
    data() {
        return {
            filters: [
                "id",
                { source: "before", type: "date" },
                { source: "after", type: "date" },
                {
                    source: "status",
                    type: "select",
                    attributes: {
                        choices: FinancialEventStatuses,
                    },
                },
                { source: "created_at", type: "date" },
                { source: "updated_at", type: "date" },
            ],
            fields: [
                { source: "id", sortable: true },
                { source: "before", type: "date", sortable: true },
                { source: "after", type: "date", sortable: true },
                { source: "status", type: "text", sortable: true },
                { source: "additional_data", type: "text" },
                { source: "created_at", type: "date", sortable: true },
                { source: "updated_at", type: "date", sortable: true },
            ],
        };
    },
};
</script>
