<template>
    <div>
        <va-aside-layout :title="asideTitle">
            <users-show v-if="show" :item="item"></users-show>
            <users-form
                v-else
                :id="id"
                :item="item"
                @saved="onSaved"
            ></users-form>
        </va-aside-layout>
        <base-material-card :icon="resource.icon" :title="title">
            <va-list
                ref="list"
                disable-create-redirect
                :filters="filters"
                @action="onAction"
            >
                <template v-slot:[`bulk.actions`]>
                    <va-bulk-action-button
                        :label="$t('users.enable')"
                        icon="mdi-publish"
                        color="success"
                        :action="{ active: true }"
                        text
                    ></va-bulk-action-button>
                    <va-bulk-action-button
                        :label="$t('users.disable')"
                        icon="mdi-download"
                        color="orange"
                        :action="{ active: false }"
                        text
                    ></va-bulk-action-button>
                </template>
                <va-data-table
                    :fields="fields"
                    disable-create-redirect
                    disable-show-redirect
                    disable-edit-redirect
                    disable-clone
                    @item-action="onAction"
                >
                    <template v-slot:[`field.roles`]="{ value }">
                        <v-chip-group column>
                            <v-chip small v-for="(item, i) in value" :key="i">
                                <va-select-field
                                    source="roles"
                                    :item="roleMap[item]"
                                ></va-select-field>
                            </v-chip>
                        </v-chip-group>
                    </template>
                    <template
                        v-slot:[`field.mws_configuration_ids`]="{ value }"
                    >
                        <v-chip-group column>
                            <v-chip small v-for="(item, i) in value" :key="i">
                                <va-select-field
                                    source="roles"
                                    :item="mwsConfigurationMap[item]"
                                ></va-select-field>
                            </v-chip>
                        </v-chip-group>
                    </template>
                    <template v-slot:[`item.actions`]="{ resource, item }">
                        <impersonate-button
                            :resource="resource"
                            :item="item"
                            icon
                        ></impersonate-button>
                    </template>
                </va-data-table>
            </va-list>
        </base-material-card>
    </div>
</template>

<script>
import ImpersonateButton from "@/components/buttons/ImpersonateButton";
import Roles from "@/constants/UserRoles";

export default {
    components: {
        ImpersonateButton,
    },
    props: ["resource", "title"],
    computed: {
        mwsConfigurationOptions() {
            return this.$store.getters.mwsConfigurationOptions;
        },
    },
    watch: {
        mwsConfigurationOptions: {
            immediate: true,
            handler(newVal = []) {
                if (!newVal || !newVal.length) {
                    return;
                }
                const mwsConfigurationMap = {};
                newVal.forEach((e) => {
                    mwsConfigurationMap[e.value] = e.text;
                });
                this.mwsConfigurationMap = mwsConfigurationMap;
            },
        },
    },
    beforeMount() {
        const roleMap = {};
        Roles.forEach((role) => {
            roleMap[role.value] = role.text;
        });
        this.roleMap = roleMap;
    },
    data() {
        return {
            filters: [
                { source: "active", type: "boolean" },
                {
                    source: "roles",
                    type: "select",
                    attributes: {
                        multiple: true,
                        choices: Roles,
                    },
                },
            ],
            fields: [
                { source: "name", sortable: true },
                { source: "email", type: "email" },
                { source: "active", type: "boolean", editable: true },
                "roles",
                "mws_configuration_ids",
                {
                    source: "created_at",
                    type: "date",
                    sortable: true,
                },
                {
                    source: "updated_at",
                    type: "date",
                    sortable: true,
                },
            ],
            asideTitle: null,
            id: null,
            item: null,
            show: false,
            roleMap: {},
            mwsConfigurationMap: {},
        };
    },
    methods: {
        async onAction({ action, title, id, item }) {
            this.asideTitle = title;
            this.id = id;
            this.show = action === "show";
            this.item = item;
        },
        onSaved() {
            this.asideTitle = null;
            this.$refs.list.fetchData();
        },
    },
};
</script>
