<template>
    <div class="us-warehouse-inventory-edit-form">
        <va-form
            :id="id"
            :item="item"
            v-model="model"
            disable-redirect
            @saved="$emit('saved')"
        >
            <va-number-input
                v-if="!item.children.length"
                source="stock_available"
                label="Stock Available"
            ></va-number-input>
            <va-number-input
                v-if="!item.children.length"
                source="stock_inbound"
                label="Stock Inbound"
            ></va-number-input>
            <va-array-input
                v-if="item.children.length"
                source="children"
                label="Children"
                v-slot="props"
            >
                <v-row>
                    <v-col sm="12">
                        <va-text-field
                            v-bind="props"
                            source="sku"
                        ></va-text-field>
                    </v-col>
                    <va-text-input
                        v-show="false"
                        v-bind="props"
                        source="id"
                    ></va-text-input>
                </v-row>
                <v-row>
                    <v-col sm="6">
                        <va-number-input
                            v-bind="props"
                            source="stock_available"
                            label="Stock Available"
                        ></va-number-input>
                    </v-col>
                    <v-col sm="6">
                        <va-number-input
                            v-bind="props"
                            source="stock_inbound"
                            label="Stock Inbound"
                        ></va-number-input>
                    </v-col>
                </v-row>
            </va-array-input>
            <va-number-input
                source="other_stock_available"
                label="Other Stock Available"
            ></va-number-input>
            <va-number-input
                source="other_stock_inbound"
                label="Other Stock Inbound"
            ></va-number-input>
            <va-save-button></va-save-button>
        </va-form>
    </div>
</template>

<script>
export default {
    props: ["id", "item"],
    data() {
        return {
            model: {},
        };
    },
    methods: {
        hasRole(role) {
            return (this.model.roles || []).includes(role);
        },
    },
};
</script>
<style lang="sass">
.us-warehouse-inventory-edit-form
    .va-array-input
        .d-flex, button
            display: none !important
</style>
