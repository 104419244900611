import snakeCase from "lodash/snakeCase";

export const SAMPLE_INQUIRY = "Sample inquiry";
export const STOCK_INQUIRY = "Stock inquiry";
export const BARCODE = "barcode";
export const BILLING = "billing";

const types = [SAMPLE_INQUIRY, STOCK_INQUIRY, BARCODE, BILLING];
export const OrderRequestExportTypes = types.map((value) => ({
    value: snakeCase(value).toLowerCase(),
    text: `Export ${value}`,
}));

export default OrderRequestExportTypes;
