const OrderStatuses = [
    "PendingAvailability",
    "Pending",
    "Unshipped",
    "PartiallyShipped",
    "Shipped",
    "InvoiceUnconfirmed",
    // "Canceled",
    "Unfulfillable",
].map((value) => ({
    value,
    text: value,
}));

export default OrderStatuses;
