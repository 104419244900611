<template>
    <div>
        <va-aside-layout :title="asideTitle">
            <us-warehouse-inventory-form
                v-if="show"
                :id="id"
                :item="item"
                @saved="onSaved"
            ></us-warehouse-inventory-form>
        </va-aside-layout>
        <base-material-card :icon="resource.icon" :title="title">
            <v-tabs v-model="selectedTab">
                <v-tab
                    class="mr-3"
                    v-for="tab in mwsConfigurationOptions"
                    :key="tab.value"
                    @click="onClickTab(tab.value)"
                >
                    <v-icon class="mr-2">mdi-amazon</v-icon>
                    {{ tab.text }}
                </v-tab>
            </v-tabs>
            <v-tabs-items v-model="selectedTab" class="transparent">
            </v-tabs-items>
            <va-list
                :filters="filters"
                ref="list"
                class="us-warehouse-inventory-list"
                disable-create
                disable-export
                @action="onAction"
                :filter.sync="filter"
            >
                <va-data-table
                    ref="table"
                    :fields="fields"
                    class="us-warehouse-inventory-main-table"
                    show-expand
                    single-expand
                    disable-clone
                    disable-delete
                    disable-show
                    disable-select
                    disable-edit-redirect
                    @item-action="onAction"
                >
                    <template v-slot:[`field.asin`]="{ value }">
                        <a
                            target="_blank"
                            :href="'https://amazon.com/dp/' + value"
                            >{{ value }}</a
                        >
                    </template>
                    <template
                        v-slot:[`field.stock_available`]="{ value, item }"
                    >
                        {{ value + item.other_stock_available }}
                    </template>
                    <template v-slot:[`field.stock_inbound`]="{ value, item }">
                        {{ value + item.other_stock_inbound }}
                    </template>
                    <template v-slot:expanded-item="{ item }">
                        <v-data-table
                            v-if="item.children.length"
                            hide-default-header
                            hide-default-footer
                            class="us-warehouse-inventory-item-headers"
                            :headers="item_headers"
                            :items="item.children"
                            show-expand
                        >
                        </v-data-table>
                    </template>
                </va-data-table>
            </va-list>
        </base-material-card>
    </div>
</template>

<script>
export default {
    props: ["resource", "title"],
    computed: {
        mwsConfigurationOptions() {
            return this.$store.getters.mwsConfigurationOptions;
        },
    },
    watch: {
        mwsConfigurationId: {
            immediate: true,
            handler(newVal) {
                if (!newVal) {
                    return;
                }
                const current = this.filter["mws_configuration_id"] || [];
                if (current.includes(newVal)) {
                    return;
                }
                this.filter["mws_configuration_id"] = [newVal];
            },
        },
        mwsConfigurationOptions: {
            immediate: true,
            handler(newVal) {
                if (!newVal || !newVal.length) {
                    return;
                }
                const filter = this.filters.find(
                    (filter) => filter.source === "mws_configuration_id"
                );
                filter.attributes.choices = newVal;
                this.findSelectedTab(newVal);
            },
        },
    },
    data() {
        return {
            selectedTab: 0,
            filter: {},
            filters: [
                {
                    label: "App Configuration",
                    source: "mws_configuration_id",
                    type: "select",
                    attributes: {
                        choices: this.mwsConfigurationOptions,
                        multiple: true,
                    },
                    alwaysOn: true,
                },
                { source: "asin", type: "text", alwaysOn: true },
                { source: "sku", type: "text", alwaysOn: true },
                {
                    label: "FnSku",
                    source: "fnSku",
                    type: "text",
                    alwaysOn: true,
                },
            ],
            fields: [
                {
                    label: "Image",
                    source: "small_image",
                    attributes: {
                        src: "URL",
                    },
                    type: "image",
                    sortable: false,
                },
                { source: "asin", sortable: true },
                { source: "sku", sortable: true },
                { label: "FnSku", source: "fnSku", sortable: true },
                { source: "stock_available", sortable: true },
                { source: "stock_inbound", sortable: true },
            ],
            item_headers: [
                { text: "none", value: "none", sortable: true },
                { text: "none", value: "none", sortable: true },
                { text: "sku", value: "sku", sortable: true },
                { text: "fnSku", value: "fnSku", sortable: true },
                {
                    text: "stock_available",
                    value: "stock_available",
                    sortable: true,
                },
                {
                    text: "stock_inbound",
                    value: "stock_inbound",
                    sortable: true,
                },
                { text: "none", value: "none", sortable: true },
            ],
            asideTitle: null,
            id: null,
            item: null,
            show: false,
            mwsConfigurationMap: {},
        };
    },
    methods: {
        onClickTab(id) {
            this.filter["mws_configuration_id"] = id ? id : "";
            if (!this.show) {
                return;
            }
            this.show = false;
        },
        findSelectedTab(tabs) {
            const { filter } = this.$route.query;
            if (!filter) {
                return;
            }
            const currentFilter = JSON.parse(filter);
            if (!currentFilter["mws_configuration_id"]) {
                return;
            }
            this.selectedTab = parseInt(
                currentFilter["mws_configuration_id"],
                10
            );
            if (!this.selectedTab) {
                return;
            }
            this.selectedTab = tabs.findIndex(
                (item) => item.value === this.selectedTab
            );
        },
        async onAction({ action, id, item }) {
            this.asideTitle = `Edit ASIN: ${item.asin}`;
            this.id = id;
            this.show = ["create", "edit"].includes(action);
            this.item = item;
        },
        onSaved() {
            this.asideTitle = null;
            this.$refs.list.fetchData();
        },
    },
};
</script>
<style lang="sass">
$filter-height: 80px

.menuable__content__active
    z-index: 9999 !important
.us-warehouse-inventory-list
     > header
        height: $filter-height !important
        > div
            height: $filter-height !important
            > form
                > div
                    > div:nth-child(2)
                        display: none !important
     > form
        > div
            > div
                height: 65vh
                overflow-y: auto
                > table
                    > thead
                        position: sticky
                        top: 0
                        background: white
                        z-index: 9999
                    > tbody
                        > tr
                            > td
                                button
                                    background: transparent !important
                            td:nth-child(2)
                                width: 108px
                            td:nth-child(5)
                                width: 15% !important
                                text-align: left !important
                            td:nth-child(6), td:nth-child(7)
                                width: 10% !important
                                text-align: right !important
                            td:last-child
                                width: 5%
</style>
