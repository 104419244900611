<template>
    <base-material-card :icon="resource.icon" :title="title">
        <v-tabs v-model="selectedTab">
            <v-tab
                class="mr-3"
                v-for="tab in mwsConfigurationOptions"
                :key="tab.value"
                @click="onClickTab(tab.value)"
            >
                <v-icon class="mr-2">mdi-amazon</v-icon>
                {{ tab.text }}
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="selectedTab" class="transparent"></v-tabs-items>
        <va-list
            :filters="filters"
            ref="list"
            class="sale-inventory-list"
            disable-create
            disable-export
            :filter.sync="filter"
            :sort-by.sync="sort.by"
            :sort-desc.sync="sort.desc"
            :items-per-page="100"
            :items-per-page-options="[100, 200, 300, 500]"
        >
            <template v-slot:actions>
                <va-action-button
                    label="Export"
                    icon="mdi-download"
                    text
                    :loading="isGenerating"
                    @click="exportAll"
                ></va-action-button>
                <a
                    :href="fileLink"
                    ref="fileLinkElement"
                    class="d-none"
                    download="sale-inventory.csv"
                    >Download exported file.</a
                >
            </template>
            <va-data-table
                ref="table"
                :fields="fields"
                class="sale-inventory-list-main-table"
                disable-edit
                disable-clone
                disable-show
                disable-select
                show-expand
                single-expand
            >
                <template v-slot:[`field.asin`]="{ value, item }">
                    <a @click="openAsinReport(item)">{{ value }}</a>
                </template>
                <template v-slot:[`field.sku`]="{ value }">
                    <div class="col-sku">
                        {{ value.includes(",") ? "Multiple" : value }}
                    </div>
                </template>
                <template v-slot:[`field.fnSku`]="{ value }">
                    <div class="col-fn-sku">
                        {{ value && value.includes(",") ? "Multiple" : value }}
                    </div>
                </template>
                <template v-slot:[`field.pics`]="{ value }">
                    <div class="col-pics">
                        {{ value ? value.map((i) => i.name).join(", ") : "" }}
                    </div>
                </template>
                <template v-slot:[`field.suppliers`]="{ value }">
                    <div class="col-suppliers">
                        {{ value ? value.map((i) => i.name).join(", ") : "" }}
                    </div>
                </template>
                <template v-slot:[`field.asin_age`]="{ value }">
                    {{ value }}
                </template>
                <template v-slot:[`field.sum_sale_0_day`]="{ value }">
                    {{ value }}
                </template>
                <template v-slot:[`field.sum_sale_1_day`]="{ value, item }">
                    {{ value }}
                    <br />
                    <span :class="getDiffCssClass(item, 'diff_sum_sale_1_day')">
                        {{ getDiffLabel(item, "diff_sum_sale_1_day") }}
                    </span>
                </template>
                <template v-slot:[`field.sum_sale_3_day`]="{ value, item }">
                    {{ value }}
                    <br />
                    <span :class="getDiffCssClass(item, 'diff_sum_sale_3_day')">
                        {{ getDiffLabel(item, "diff_sum_sale_3_day") }}
                    </span>
                </template>
                <template v-slot:[`field.sum_sale_7_day`]="{ value, item }">
                    {{ value }}
                    <br />
                    <span :class="getDiffCssClass(item, 'diff_sum_sale_7_day')">
                        {{ getDiffLabel(item, "diff_sum_sale_7_day") }}
                    </span>
                </template>
                <template v-slot:[`field.sum_sale_15_day`]="{ value, item }">
                    {{ value }}
                    <br />
                    <span
                        :class="getDiffCssClass(item, 'diff_sum_sale_15_day')"
                    >
                        {{ getDiffLabel(item, "diff_sum_sale_15_day") }}
                    </span>
                </template>
                <template v-slot:[`field.sum_sale_30_day`]="{ value, item }">
                    {{ value }}
                    <br />
                    <span
                        :class="getDiffCssClass(item, 'diff_sum_sale_30_day')"
                    >
                        {{ getDiffLabel(item, "diff_sum_sale_30_day") }}
                    </span>
                </template>
                <template v-slot:[`field.label`]="{ value }">
                    <v-chip text-color="white" :color="getLabelColor(value)">
                        {{ value }}
                    </v-chip>
                </template>
                <template v-slot:[`field.tags`]="{ value, item }">
                    <v-chip v-if="item.is_low_price">
                        {{ "LowPrice" }}
                    </v-chip>
                    <v-chip v-if="item.is_neg_gp">
                        {{ "NegGP" }}
                    </v-chip>
                </template>
                <template v-slot:expanded-item="{ item }">
                    <v-data-table
                        v-if="item.children.length"
                        hide-default-header
                        hide-default-footer
                        class="sale-inventory-list-item-headers"
                        :headers="item_headers"
                        :items="item.children"
                    >
                        <template v-slot:item.first="{}">
                            <button
                                type="button"
                                class="
                                    v-icon
                                    v-data-table__expand-icon
                                    v-icon--link
                                    mdi mdi-square-small
                                "
                            ></button>
                        </template>
                        <template v-slot:item.small_image="{}">
                            <!--                            <va-image-field-->
                            <!--                                :item="item"-->
                            <!--                                source="small_image"-->
                            <!--                                src="URL"-->
                            <!--                            ></va-image-field>-->
                        </template>
                        <template v-slot:item.sku="{ value }">
                            <div class="col-sku">
                                {{ value }}
                            </div>
                        </template>
                        <template v-slot:item.fnSku="{ value }">
                            <div class="col-fn-sku">
                                {{ value }}
                            </div>
                        </template>
                        <template v-slot:item.pics="{ value }">
                            <div class="col-pics">
                                {{
                                    value
                                        ? value.map((i) => i.name).join(", ")
                                        : ""
                                }}
                            </div>
                        </template>
                        <template v-slot:item.suppliers="{ value }">
                            <div class="col-suppliers">
                                {{
                                    value
                                        ? value.map((i) => i.name).join(", ")
                                        : ""
                                }}
                            </div>
                        </template>
                        <template v-slot:item.sum_sale_0_day="{ value }">
                            {{ value }}
                        </template>
                        <template v-slot:item.sum_sale_1_day="{ value, item }">
                            {{ value }}
                            <br />
                            <span
                                :class="
                                    getDiffCssClass(item, 'diff_sum_sale_1_day')
                                "
                            >
                                {{ getDiffLabel(item, "diff_sum_sale_1_day") }}
                            </span>
                        </template>
                        <template v-slot:item.sum_sale_3_day="{ value, item }">
                            {{ value }}
                            <br />
                            <span
                                :class="
                                    getDiffCssClass(item, 'diff_sum_sale_3_day')
                                "
                            >
                                {{ getDiffLabel(item, "diff_sum_sale_3_day") }}
                            </span>
                        </template>
                        <template v-slot:item.sum_sale_7_day="{ value, item }">
                            {{ value }}
                            <br />
                            <span
                                :class="
                                    getDiffCssClass(item, 'diff_sum_sale_7_day')
                                "
                            >
                                {{ getDiffLabel(item, "diff_sum_sale_7_day") }}
                            </span>
                        </template>
                        <template v-slot:item.sum_sale_15_day="{ value, item }">
                            {{ value }}
                            <br />
                            <span
                                :class="
                                    getDiffCssClass(
                                        item,
                                        'diff_sum_sale_15_day'
                                    )
                                "
                            >
                                {{ getDiffLabel(item, "diff_sum_sale_15_day") }}
                            </span>
                        </template>
                        <template v-slot:item.sum_sale_30_day="{ value, item }">
                            {{ value }}
                            <br />
                            <span
                                :class="
                                    getDiffCssClass(
                                        item,
                                        'diff_sum_sale_30_day'
                                    )
                                "
                            >
                                {{ getDiffLabel(item, "diff_sum_sale_30_day") }}
                            </span>
                        </template>
                        <template v-slot:item.label="{ value }">
                            <v-chip
                                text-color="white"
                                :color="getLabelColor(value)"
                            >
                                {{ value }}
                            </v-chip>
                        </template>
                        <template v-slot:item.tags="{ item }">
                            <v-chip v-if="item.is_low_price">
                                {{ "LowPrice" }}
                            </v-chip>
                            <v-chip v-if="item.is_neg_gp">
                                {{ "NegGP" }}
                            </v-chip>
                        </template>
                    </v-data-table>
                </template>
            </va-data-table>
        </va-list>
        <v-dialog v-model="dialog">
            <v-card>
                <v-toolbar dark color="primary">
                    <v-btn icon dark @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>
                        ASIN:
                        <a
                            v-if="selected"
                            target="_blank"
                            :href="'https://amazon.com/dp/' + selected.asin"
                            >{{ selected.asin }}</a
                        >
                    </v-toolbar-title>
                </v-toolbar>
                <v-list>
                    <v-list-item>
                        <v-list-item-content>
                            <v-card-text v-if="!report">
                                Loading...
                                <v-progress-linear
                                    indeterminate
                                ></v-progress-linear>
                            </v-card-text>
                            <date-range-picker
                                ref="picker"
                                :opens="'right'"
                                :timePicker="false"
                                :showWeekNumbers="true"
                                :showDropdowns="true"
                                :ranges="dateRanges"
                                v-if="report"
                                v-model="dateRange"
                                @update="(val) => applyAsinReportFilter(val)"
                                :locale-data="{
                                    firstDay: 1,
                                }"
                            >
                                <template
                                    v-slot:input="picker"
                                    style="min-width: 350px"
                                >
                                    <label
                                        v-if="
                                            !picker.startDate ||
                                            !picker.startDate
                                        "
                                        class="v-label theme--light"
                                    >
                                        Date Range
                                    </label>
                                    <template v-else>
                                        {{
                                            picker.startDate
                                                | moment("timezone", tz, "L")
                                        }}
                                        -
                                        {{
                                            picker.endDate
                                                | moment("timezone", tz, "L")
                                        }}
                                    </template>
                                </template>
                            </date-range-picker>
                            <highcharts
                                v-if="report"
                                :options="chartOptions"
                            ></highcharts>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-card>
        </v-dialog>
    </base-material-card>
</template>

<script>
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import Highcharts from "highcharts";
import { Chart } from "highcharts-vue";
import DateRangePicker from "vue2-daterange-picker";
import moment from "moment-timezone";
import { tz, sourceTz } from "@/constants/Time";
import StockRemainingDayRanges from "@/constants/StockRemainingDayRanges";
import dateRanges from "@/constants/DateRanges";
import MarketplaceIds from "@/constants/MarketplaceIds";
import SaleInventoryLabels, {
    SaleInventoryLabelColorMap,
} from "@/constants/SaleInventoryLabels";
import Export from "@/resources/sale-inventory/Export";
import { AsinAgeLabels } from "@/constants/AsinAgeTypes";
import CogCatalogTagTypes from "@/constants/CogCatalogItemTagTypes";

export default {
    mixins: [Export],
    props: ["resource", "title"],
    components: {
        highcharts: Chart,
        DateRangePicker,
    },
    computed: {
        mwsConfigurationOptions() {
            return this.$store.getters.mwsConfigurationOptions;
        },
    },
    watch: {
        mwsConfigurationId: {
            immediate: true,
            handler(newVal) {
                if (!newVal) {
                    return;
                }
                const current = this.filter["mws_configuration_id"] || [];
                if (current.includes(newVal)) {
                    return;
                }
                this.filter["mws_configuration_id"] = [newVal];
            },
        },
        mwsConfigurationOptions: {
            immediate: true,
            handler(newVal) {
                if (!newVal || !newVal.length) {
                    return;
                }
                const filter = this.filters.find(
                    (filter) => filter.source === "mws_configuration_id"
                );
                filter.attributes.choices = newVal;
                this.findSelectedTab(newVal);
            },
        },
    },
    data() {
        return {
            tz: tz,
            sourceTz: sourceTz,
            dateFormat: this.$admin.options.dateFormat,
            selectedTab: 0,
            isGenerating: false,
            marketplaceIdKey: "main_marketplace_id",
            filter: {
                second_marketplace: 0,
            },
            sort: {
                by: ["sum_sale_30_day"],
                desc: [true],
            },
            filters: [
                {
                    label: "App Configuration",
                    source: "mws_configuration_id",
                    type: "select",
                    attributes: {
                        choices: this.mwsConfigurationOptions,
                        multiple: true,
                    },
                    alwaysOn: true,
                },
                { label: "ASIN", source: "asin", type: "text", alwaysOn: true },
                { label: "SKU", source: "sku", type: "text", alwaysOn: true },
                {
                    label: "FnSKU",
                    source: "fnSku",
                    type: "text",
                    alwaysOn: true,
                },
                {
                    label: "Supplier",
                    source: "supplier_ids",
                    type: "select",
                    attributes: {
                        itemText: "name",
                        multiple: true,
                        reference: "Supplier",
                    },
                    alwaysOn: true,
                },
                {
                    label: "PIC",
                    source: "pic_ids",
                    type: "select",
                    attributes: {
                        itemText: "name",
                        multiple: true,
                        reference: "users",
                    },
                    alwaysOn: true,
                },
                {
                    label: "Stock Remaining for Available Inventory",
                    source: "stock_remaining_for_available",
                    type: "select",
                    attributes: {
                        choices: StockRemainingDayRanges,
                    },
                    alwaysOn: true,
                },
                // {
                //     label: "Stock Remaining for All Inventory",
                //     source: "stock_remaining_for_all_stock",
                //     type: "select",
                //     attributes: {
                //         choices: StockRemainingDayRanges,
                //     },
                //     alwaysOn: true,
                // },
                {
                    label: "Out of Stock",
                    source: "out_of_stock",
                    type: "boolean",
                    alwaysOn: true,
                },
                // {
                //     label: "Restock processing",
                //     source: "restock_status",
                //     type: "boolean",
                //     alwaysOn: true,
                // },
                {
                    source: "marketplace_id",
                    type: "select",
                    attributes: {
                        multiple: true,
                        choices: MarketplaceIds,
                    },
                    alwaysOn: true,
                },
                {
                    source: "label",
                    type: "select",
                    attributes: {
                        multiple: true,
                        choices: SaleInventoryLabels,
                    },
                    alwaysOn: true,
                },
                {
                    label: "Tags",
                    source: "tags",
                    type: "select",
                    attributes: {
                        multiple: true,
                        choices: CogCatalogTagTypes,
                    },
                    alwaysOn: true,
                },
                {
                    label: "ASIN Age",
                    source: "asin_age",
                    type: "select",
                    attributes: {
                        multiple: false,
                        choices: AsinAgeLabels,
                    },
                    alwaysOn: true,
                },
            ],
            fields: [
                {
                    label: "Image",
                    source: "small_image",
                    attributes: {
                        src: "URL",
                    },
                    type: "image",
                    sortable: false,
                },
                { label: "ASIN", source: "asin", sortable: true },
                { label: "Label", source: "label", sortable: false },
                { label: "Tags", source: "tags", sortable: false },
                { label: "SKU", source: "sku", sortable: true },
                { label: "FnSKU", source: "fnSku", sortable: true },
                {
                    label: "Supplier",
                    source: "suppliers",
                    sortable: false,
                },
                {
                    label: "PIC",
                    source: "pics",
                    sortable: false,
                },
                {
                    label: "ASIN Age",
                    source: "asin_age",
                    sortable: true,
                },
                {
                    label: "Today",
                    source: "sum_sale_0_day",
                    sortable: true,
                },
                {
                    label: "Yesterday",
                    source: "sum_sale_1_day",
                    sortable: true,
                },
                {
                    label: "Last 3 days",
                    source: "sum_sale_3_day",
                    sortable: true,
                },
                {
                    label: "Last 7 days",
                    source: "sum_sale_7_day",
                    sortable: true,
                },
                {
                    label: "Last 15 days",
                    source: "sum_sale_15_day",
                    sortable: true,
                },
                {
                    label: "Last 30 days",
                    source: "sum_sale_30_day",
                    sortable: true,
                },
                {
                    label: "Average per day (EMA)",
                    source: "ema",
                    sortable: true,
                },
                {
                    label: "Average per day (SMA)",
                    source: "sma",
                    sortable: true,
                },
                {
                    label: "Available",
                    source: "stock_available",
                    sortable: true,
                },
                { label: "Inbound", source: "stock_inbound", sortable: true },
                { label: "Reserved", source: "stock_reserved", sortable: true },
                { label: "Prepare", source: "stock_prepare", sortable: true },
                {
                    label: "For available inventory (EMA)",
                    source: "ema_remaining_for_available",
                    sortable: true,
                },
                {
                    label: "For available inventory (SMA)",
                    source: "sma_remaining_for_available",
                    sortable: true,
                },
            ],
            item_headers: [
                { text: "first", value: "first", sortable: false },
                { text: "small_image", value: "small_image", sortable: false },
                { text: "asin", value: "asin", sortable: false },
                { text: "label", value: "label", sortable: false },
                { label: "Tags", value: "tags", sortable: false },
                { text: "SKU", value: "sku", sortable: true },
                { text: "FnSKU", value: "fnSku", sortable: true },
                { text: "Supplier", value: "suppliers", sortable: true },
                { text: "PIC", value: "pics", sortable: true },
                { text: "ASIN Age", value: "created_at", sortable: true },
                { text: "Today", value: "sum_sale_0_day", sortable: true },
                { text: "Yesterday", value: "sum_sale_1_day", sortable: true },
                { text: "3 days", value: "sum_sale_3_day", sortable: true },
                { text: "7 days", value: "sum_sale_7_day", sortable: true },
                { text: "15 days", value: "sum_sale_15_day", sortable: true },
                { text: "30 days", value: "sum_sale_30_day", sortable: true },
                {
                    text: "ema",
                    value: "ema",
                    sortable: true,
                },
                {
                    text: "sma",
                    value: "sma",
                    sortable: true,
                },
                {
                    text: "stock_available",
                    value: "stock_available",
                    sortable: true,
                },
                {
                    text: "stock_inbound",
                    value: "stock_inbound",
                    sortable: true,
                },
                {
                    text: "stock_reserved",
                    value: "stock_reserved",
                    sortable: true,
                },
                {
                    text: "stock_prepare",
                    value: "stock_prepare",
                    sortable: true,
                },
                {
                    text: "ema_remaining_for_available",
                    value: "ema_remaining_for_available",
                    sortable: true,
                },
                {
                    text: "sma_remaining_for_available",
                    value: "sma_remaining_for_available",
                    sortable: true,
                },
            ],
            isGroupedHeader: false,
            dialog: false,
            selected: false,
            report: false,
            cachedReport: {},
            dateRange: {},
            dateRanges,
            chartOptions: {
                credits: {
                    enabled: false,
                },
                chart: {
                    zoomType: "xy",
                },
                title: {
                    text: "",
                },
                subtitle: {
                    text: "",
                },
                xAxis: [
                    {
                        categories: [],
                        crosshair: true,
                    },
                ],
                yAxis: [
                    {
                        // Primary yAxis
                        labels: {
                            format: "{value} items",
                            style: {
                                color: Highcharts.getOptions().colors[1],
                            },
                        },
                        title: {
                            text: "Items",
                            style: {
                                color: Highcharts.getOptions().colors[1],
                            },
                        },
                    },
                    {
                        // Secondary yAxis
                        title: {
                            text: "Rev",
                            style: {
                                color: Highcharts.getOptions().colors[0],
                            },
                        },
                        labels: {
                            format: "{value}$",
                            style: {
                                color: Highcharts.getOptions().colors[0],
                            },
                        },
                        opposite: true,
                    },
                ],
                tooltip: {
                    shared: true,
                },
                legend: {
                    layout: "vertical",
                    align: "left",
                    x: 120,
                    verticalAlign: "top",
                    y: 100,
                    floating: true,
                    backgroundColor:
                        Highcharts.defaultOptions.legend.backgroundColor || // theme
                        "rgba(255,255,255,0.25)",
                },
                series: [
                    {
                        name: "Rev",
                        type: "column",
                        yAxis: 1,
                        data: [],
                        tooltip: {
                            valueSuffix: "$",
                        },
                    },
                    {
                        name: "Items",
                        type: "spline",
                        data: [],
                        tooltip: {
                            valueSuffix: " items",
                        },
                    },
                ],
            },
        };
    },
    methods: {
        onClickTab(id) {
            this.filter["mws_configuration_id"] = id ? id : "";
            if (id) {
                const mwsConfigurationOption =
                    this.mwsConfigurationOptions.find(
                        (mwsConfiguration) => mwsConfiguration.value === id
                    );
                this.filter["marketplace_id"] =
                    mwsConfigurationOption[this.marketplaceIdKey];
            } else {
                this.filter["marketplace_id"] = "";
            }
        },
        findSelectedTab(tabs) {
            const { filter } = this.$route.query;
            if (!filter) {
                return;
            }
            const currentFilter = JSON.parse(filter);
            if (!currentFilter["mws_configuration_id"]) {
                return;
            }
            this.selectedTab = parseInt(
                currentFilter["mws_configuration_id"],
                10
            );
            if (!this.selectedTab) {
                return;
            }
            this.selectedTab = tabs.findIndex(
                (item) => item.value === this.selectedTab
            );
        },
        openAsinReport(item) {
            this.dialog = true;
            this.selected = item;
            this.applyAsinReportFilter({
                startDate: this.dateRange.startDate,
                endDate: this.dateRange.endDate,
            });
        },
        async applyAsinReportFilter(value) {
            const marketplace_id = this.filter["marketplace_id"];
            const start = moment(value.startDate);
            const end = moment(value.endDate);
            const filter = {
                asin: this.selected.asin,
                marketplace_id,
                start: start.clone().tz(tz).format("YYYY-MM-DD"),
                end: end.clone().tz(tz).format("YYYY-MM-DD"),
            };
            this.report = false;
            if (
                this.cachedReport[
                    `${filter.asin}_${filter.start}_${filter.end}_${marketplace_id}`
                ]
            ) {
                this.report =
                    this.cachedReport[
                        `${filter.asin}_${filter.start}_${filter.end}_${marketplace_id}`
                    ];
            } else {
                const response = await this.$store.dispatch(
                    "SaleInventoryReport/getList",
                    {
                        filter,
                    }
                );
                this.report = response.data;
                this.cachedReport[
                    `${filter.asin}_${filter.start}_${filter.end}_${marketplace_id}`
                ] = { ...response.data };
            }

            this.chartOptions.xAxis[0].categories =
                this.report.total_quantity.map((i) => {
                    if (i.year) {
                        return moment()
                            .year(i.year)
                            .month(i.month - 1)
                            .tz(tz)
                            .format("MMM YYYY");
                    }
                    return moment(i.at_date).tz(tz).format("ll");
                });
            this.chartOptions.series[1].data = this.report.total_quantity.map(
                (i) => 1 * i.count
            );
            this.chartOptions.series[0].data = this.report.total_amount.map(
                (i) => 1 * i.count
            );
        },
        async generate() {
            this.isGenerating = true;
            try {
                await this.$store.dispatch("SaleInventory/create", {
                    data: {},
                });
            } catch (e) {
                console.log(e);
            } finally {
                this.isGenerating = false;
                this.$emit("saved");
            }
        },
        async exportAll() {
            await this.generateLink();
            this.$refs.fileLinkElement.click();
        },
        buildHeader(newValue) {
            if (!newValue.length) {
                this.isGroupedHeader = false;
                return;
            }
            if (this.isGroupedHeader) {
                return;
            }
            const table = this.$refs.table.$el.querySelector("table");
            const defaultColGroup = table.querySelector("colgroup");
            defaultColGroup.span = 3;
            defaultColGroup.childNodes.forEach((child, index) => {
                if (index < 3) {
                    return;
                }
                child.remove();
            });
            const col1 = document.createElement("col");
            table.insertBefore(col1, defaultColGroup);
            // image,asin,label,tags,sku,fnSku,pic,supplier,asin_age
            const colGroup1 = document.createElement("colgroup");
            colGroup1.span = 9;
            colGroup1.append(
                document.createElement("col"),
                document.createElement("col"),
                document.createElement("col"),
                document.createElement("col"),
                document.createElement("col"),
                document.createElement("col"),
                document.createElement("col"),
                document.createElement("col"),
                document.createElement("col")
            );
            table.insertBefore(colGroup1, defaultColGroup);
            // Sale
            const colGroup2 = document.createElement("colgroup");
            colGroup2.span = 8;
            colGroup2.append(
                document.createElement("col"),
                document.createElement("col"),
                document.createElement("col"),
                document.createElement("col"),
                document.createElement("col"),
                document.createElement("col"),
                document.createElement("col"),
                document.createElement("col")
            );
            table.insertBefore(colGroup2, defaultColGroup);
            // Amz Inventory
            const colGroup3 = document.createElement("colgroup");
            colGroup3.span = 4;
            colGroup3.append(
                document.createElement("col"),
                document.createElement("col"),
                document.createElement("col"),
                document.createElement("col")
            );
            table.insertBefore(colGroup3, defaultColGroup);
            // action
            const colGroup4 = document.createElement("colgroup");
            colGroup4.span = 1;
            colGroup4.append(document.createElement("col"));
            defaultColGroup.parentNode.insertBefore(
                colGroup4,
                defaultColGroup.nextSibling
            );
            //
            const thead = table.tHead;
            const defaultHeadRow = thead.rows[0];
            defaultHeadRow.cells[0].remove();
            const newHeadRow = thead.insertRow(0);
            const newHeadRowCell1 = newHeadRow.insertCell(0);
            newHeadRowCell1.rowSpan = 2;
            const newHeadRowCell2 = newHeadRow.insertCell(1);
            newHeadRowCell2.scope = "colgroup";
            newHeadRowCell2.colSpan = 9;
            const newHeadRowCell3 = newHeadRow.insertCell(2);
            newHeadRowCell3.scope = "colgroup";
            newHeadRowCell3.className = "text-center";
            newHeadRowCell3.colSpan = 8;
            newHeadRowCell3.innerText = "Sales (unit)";
            const newHeadRowCell4 = newHeadRow.insertCell(3);
            newHeadRowCell4.scope = "colgroup";
            newHeadRowCell4.className = "text-center";
            newHeadRowCell4.colSpan = 4;
            newHeadRowCell4.innerText = "Amz Inventory (unit)";
            const newHeadRowCell5 = newHeadRow.insertCell(4);
            newHeadRowCell5.scope = "colgroup";
            newHeadRowCell5.className = "text-center";
            newHeadRowCell5.colSpan = 2;
            newHeadRowCell5.innerText = "Stock Remaining (day)";
            const newHeadRowCell6 = newHeadRow.insertCell(5);
            newHeadRowCell6.scope = "colgroup";
            newHeadRowCell6.colSpan = 1;
            this.isGroupedHeader = true;
        },
        getLabelColor(label) {
            return SaleInventoryLabelColorMap[label];
        },
        getDiffCssClass(item, key) {
            if (item[key] === 0) {
                return {};
            }

            if (item[key] < 0) {
                return { "red--text": true };
            }

            return { "blue--text": true };
        },
        getDiffLabel(item, key) {
            if (item[key] === 0) {
                return `-`;
            }

            if (item[key] < 0) {
                return item[key];
            }

            return `+${item[key]}`;
        },
    },
    beforeMount() {
        this.dateRange.startDate = moment().tz(tz).startOf("month");
        this.dateRange.endDate = moment().tz(tz).endOf("month");
    },
    mounted() {
        this.$watch(
            () => {
                return this.$refs.list.listState.items;
            },
            (newValue) => this.buildHeader(newValue)
        );
    },
};
</script>
<style lang="sass">
$filter-height: 160px
$number-col-width: 80px
$image-col-width: 108px
$sale-color: antiquewhite
$sale-color-ma: moccasin
$inventory-color: aliceblue
$us-inventory-color: floralwhite
$stock-remaining-color: powderblue

.menuable__content__active
    z-index: 9999 !important

.sale-inventory-list
    > header
        height: $filter-height !important

        > div
            height: $filter-height !important

            > form
                > div
                    > div:nth-child(2)
                        display: none !important

    .v-data-table-header
        tr
            th:nth-child(n+10):nth-child(-n+22)
                width: $number-col-width !important
                min-width: $number-col-width !important

    > form
        > div
            > div
                height: 65vh
                overflow-y: auto

                > table
                    > thead
                        position: sticky
                        top: 0
                        background: white
                        z-index: 99

                        td:nth-child(3), th:nth-child(10), th:nth-child(11), th:nth-child(12), th:nth-child(13), th:nth-child(14), th:nth-child(15)
                            background: $sale-color

                        th:nth-child(16), th:nth-child(17)
                            background: $sale-color-ma

                        td:nth-child(4), th:nth-child(18), th:nth-child(19), th:nth-child(20), th:nth-child(21)
                            background: $inventory-color

                        td:nth-child(5), th:nth-child(22), th:nth-child(23), th:nth-child(24)
                            background: $stock-remaining-color

                    > colgroup:nth-child(3)
                        background: $sale-color

                    > colgroup:nth-child(4)
                        background: $inventory-color

                    > colgroup:nth-child(5)
                        background: $stock-remaining-color

                    > tbody
                        > tr
                            > td
                                button
                                    background: transparent !important

                            td:nth-child(2), td:nth-child(3), td:nth-child(4), td:nth-child(5), td:nth-child(6),
                            td:nth-child(7), td:nth-child(8), td:nth-child(9), td:nth-child(10)
                                width: $image-col-width
                                min-width: $image-col-width
                                max-width: $image-col-width

                            td
                                .col-pics, .col-suppliers
                                    min-width: 60px !important

                            td:nth-child(12), td:nth-child(13),
                            td:nth-child(14), td:nth-child(15), td:nth-child(16), td:nth-child(17), td:nth-child(18),
                            td:nth-child(19), td:nth-child(20), td:nth-child(21), td:nth-child(22), td:nth-child(23),
                            td:nth-child(24)
                                text-align: right !important

                            td:last-child
                                //width: 85px !important

                                label
                                    display: none !important

                            td:nth-child(17), td:nth-child(18)
                                background: $sale-color-ma

                            table
                                td:nth-child(11), td:nth-child(12), td:nth-child(13), td:nth-child(14), td:nth-child(15), td:nth-child(16)
                                    background: $sale-color

                                td:nth-child(17), td:nth-child(18)
                                    background: $sale-color-ma

                                td:nth-child(19), td:nth-child(20), td:nth-child(21), td:nth-child(22)
                                    background: $inventory-color

                                td:nth-child(23), td:nth-child(24)
                                    background: $stock-remaining-color

                                td:nth-child(n+10):nth-child(-n+24)
                                    width: $number-col-width !important
                                    min-width: $number-col-width !important

.v-toolbar__title a
    color: white !important
</style>
