<template>
    <OrderRequest-form
        :id="id"
        :title="title"
        :item="item"
        :cogCatalogItemMap="cogCatalogItemMap"
        :supplierMap="supplierMap"
        :exporting="exporting"
        @saved="$emit('saved')"
        @discarded="$emit('discarded')"
        @deleted="$emit('deleted')"
        @exported="onExport"
    ></OrderRequest-form>
</template>

<script>
import qs from "qs";
import axios from "axios";
import {
    BARCODE,
    OrderRequestExportTypes,
} from "@/constants/OrderRequestExportTypes";
import { downloadBarcodes } from "@/services/zipper";

export default {
    props: ["id", "title", "item", "cogCatalogItemMap", "supplierMap"],
    data() {
        return {
            exporting: false,
        };
    },
    methods: {
        async onExport(type) {
            this.exporting = true;
            let query = {
                filter: {
                    id: this.id,
                    name: this.item.name,
                    type,
                },
            };

            const response = await axios.get(
                `/api/export_order_requests?${qs.stringify(query, {
                    arrayFormat: "comma",
                })}`,
                {
                    ...query,
                    responseType: "blob", // important
                }
            );

            if (type === BARCODE) {
                this.exporting = false;
                const fileName = `${this.item.name} - Barcode`;
                return downloadBarcodes(fileName, this.item.children, [
                    {
                        fileName: `${this.item.name} - Product List`,
                        ext: "xlsx",
                        blob: response.data,
                    },
                ]);
            }

            this.exporting = false;
            // create file link in browser's memory
            const href = URL.createObjectURL(response.data);

            // create "a" HTML element with href to file & click
            const link = document.createElement("a");
            link.href = href;
            const typeLabel = OrderRequestExportTypes.find(
                (option) => option.value === type
            ).text.replace("Export ", "");
            link.setAttribute(
                "download",
                `${this.item.name} - ${typeLabel}.xlsx`
            ); //or any other extension
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        },
    },
};
</script>
