<template>
    <va-show-layout>
        <va-show :item="item">
            <v-row justify="center">
                <v-col sm="6">
                    <base-material-card>
                        <template v-slot:heading>
                            <div class="display-2">
                                {{ title }}
                            </div>
                        </template>
                        <v-card-text>
                            <va-field source="id"></va-field>
                            <va-field source="before" type="date"></va-field>
                            <va-field source="after" type="date"></va-field>
                            <va-select-field
                                source="status"
                                type="select"
                                chip
                                :choices="choices"
                            ></va-select-field>
                            <va-field
                                source="additional_data"
                                type="text"
                            ></va-field>
                            <va-field
                                source="updated_at"
                                type="date"
                            ></va-field>
                        </v-card-text>
                    </base-material-card>
                </v-col>
            </v-row>
        </va-show>
    </va-show-layout>
</template>

<script>
import FinancialEventStatuses from "@/constants/FinancialEventStatuses";

export default {
    props: ["title", "item"],
    data() {
        return {
            choices: FinancialEventStatuses,
        };
    },
};
</script>
