<template>
    <va-form
        :id="id"
        :item="item"
        v-model="model"
        disable-redirect
        @saved="$emit('saved')"
    >
        <va-text-input source="name"></va-text-input>
        <va-text-input source="email" type="email"></va-text-input>
        <va-password-input source="password"></va-password-input>
        <va-password-input source="password_confirmation"></va-password-input>
        <va-boolean-input source="active"></va-boolean-input>
        <va-select-input
            source="roles"
            multiple
            :choices="roles"
        ></va-select-input>
        <va-select-input
            source="mws_configuration_ids"
            multiple
            :choices="mwsConfigurationMapOptions"
            v-if="!hasRole('admin')"
        ></va-select-input>
        <va-save-button></va-save-button>
    </va-form>
</template>

<script>
import Roles from "@/constants/UserRoles";

export default {
    props: ["id", "item"],
    computed: {
        mwsConfigurationOptions() {
            return this.$store.getters.mwsConfigurationOptions;
        },
    },
    watch: {
        mwsConfigurationOptions: {
            immediate: true,
            handler(newVal = []) {
                if (!newVal || !newVal.length) {
                    return;
                }
                this.mwsConfigurationMapOptions = newVal.map((option) => {
                    return { value: String(option.value), text: option.text };
                });
            },
        },
    },
    data() {
        return {
            roles: Roles,
            mwsConfigurationMapOptions: {},
            model: {
                active: true,
                roles: [],
                mws_configuration_ids: [],
            },
        };
    },
    methods: {
        hasRole(role) {
            return (this.model.roles || []).includes(role);
        },
    },
};
</script>
