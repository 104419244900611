<template>
    <va-form
        ref="form"
        :id="id"
        :item="item"
        v-model="model"
        disable-redirect
        @saved="$emit('saved')"
        :class="{ 'is-dirty': isDirty }"
    >
        <va-text-input
            label="App ID"
            source="mws_configuration_id"
            v-show="false"
        ></va-text-input>
        <v-row>
            <v-col sm="5" class="text-center">
                <va-text-input
                    required
                    label="Name"
                    source="name"
                ></va-text-input>
            </v-col>
            <v-col sm="2" class="text-center">
                <va-select-input
                    label="Supplier"
                    source="supplier_id"
                    model="supplier_id"
                    item-value="id"
                    item-text="name"
                    :choices="supplierMap"
                ></va-select-input>
            </v-col>
            <v-col sm="5" class="d-flex justify-center align-content-center">
                <va-delete-button
                    v-if="id"
                    :item="item"
                    @deleted="$emit('deleted')"
                    class="mr-5"
                ></va-delete-button>
                <v-btn @click="$emit('discarded')" class="mr-5">Discard</v-btn>
                <div v-show="showSave">
                    <va-save-button></va-save-button>
                </div>
            </v-col>
        </v-row>
        <custom-array-input source="children" label="Items" v-slot="props">
            <v-row class="ma-0">
                <v-col sm="2" class="pa-1 d-flex">
                    <div
                        class="
                            barcode-status-block
                            d-flex
                            justify-center
                            align-center
                        "
                        :style="{
                            width: '10%',
                            backgroundColor: getUploadStatusColor(props.item),
                        }"
                    >
                        <div class="barcode-status-indicator">
                            {{ props.index + 1 }}
                        </div>
                    </div>
                    <div class="sku-search-block ml-2" style="width: 90%">
                        <v-combobox
                            v-model="props.item.sku"
                            :items="cogCatalogItemMap"
                            label="SKU or Name"
                            item-value="sku"
                            item-text="sku"
                            :rules="[required]"
                            :ref="'sku' + props.index"
                            @change="selectSearchItem('sku', $event.sku, props)"
                        ></v-combobox>
                        <p class="mb-0" v-if="props.item.asin">
                            {{ props.item.asin }}
                        </p>
                        <p class="mb-0" v-if="props.item.fn_sku">
                            {{ props.item.fn_sku }}
                        </p>
                    </div>
                </v-col>
                <v-col sm="2" class="pa-1">
                    <v-combobox
                        v-show="false"
                        v-model="props.item.asin"
                        :items="cogCatalogItemMap"
                        label="ASIN"
                        item-value="asin"
                        item-text="asin"
                        :ref="'asin' + props.index"
                        @change="selectSearchItem('asin', $event.asin, props)"
                    ></v-combobox>
                    <v-file-input
                        v-model="props.item.image_file"
                        @change="selectImageFile($event, props.item)"
                        @click:clear="selectImageFile(null, props.item)"
                        accept=".png,.jpg"
                        label="Image file"
                        :ref="'image_file' + props.index"
                    ></v-file-input>
                    <div class="d-flex justify-center">
                        <v-img
                            v-if="props.item.preview_image_file"
                            max-width="100"
                            :src="props.item.preview_image_file"
                        ></v-img>
                    </div>
                </v-col>
                <v-col sm="2" class="pa-1">
                    <v-file-input
                        v-model="props.item.barcode_file"
                        @change="selectBarcodeFile($event, props.item)"
                        @click:clear="selectBarcodeFile(null, props.item)"
                        accept=".pdf,.jpg"
                        label="Barcode file"
                        :ref="'barcode_file' + props.index"
                    ></v-file-input>
                    <div class="d-flex justify-center">
                        <a
                            v-if="props.item.preview_barcode_file"
                            target="_blank"
                            :href="props.item.preview_barcode_file"
                            >View</a
                        >
                    </div>
                </v-col>
                <v-col sm="2" class="pa-1">
                    <v-combobox
                        v-show="false"
                        v-model="props.item.fn_sku"
                        :items="cogCatalogItemMap"
                        label="FNSKU"
                        item-value="fn_sku"
                        item-text="fn_sku"
                        :ref="'fn_sku' + props.index"
                        @change="
                            selectSearchItem('fn_sku', $event.fn_sku, props)
                        "
                    ></v-combobox>
                    <v-text-field
                        v-model="props.item.link"
                        label="Product Link"
                        :ref="'link' + props.index"
                    ></v-text-field>
                </v-col>
                <v-col sm="1" class="pa-1">
                    <v-text-field
                        v-model="props.item.original_price"
                        label="Unit Price"
                        :ref="'original_price' + props.index"
                        type="number"
                        prefix="$"
                        reverse
                    ></v-text-field>
                </v-col>
                <v-col sm="1" class="pa-1">
                    <v-text-field
                        v-model="props.item.qty"
                        label="Quantity"
                        :ref="'qty' + props.index"
                        type="number"
                        prefix="pcs"
                        reverse
                    ></v-text-field>
                </v-col>
                <v-col sm="2" class="text-right pa-1 d-flex">
                    <div class="d-flex flex-column" style="width: 50%">
                        <span style="font-size: 9px">Total price</span>
                        <span
                            >{{
                                (
                                    props.item.qty *
                                        props.item.original_price || 0
                                ).toFixed(2)
                            }}{{ " " }}$</span
                        >
                    </div>
                    <div style="width: 50%">
                        <v-btn
                            outlined
                            fab
                            x-small
                            :color="props.item.note ? 'blue' : 'grey'"
                            @click.stop="showNoteModal(props)"
                        >
                            <v-icon>mdi-note</v-icon>
                        </v-btn>
                        <va-text-input
                            v-show="false"
                            v-bind="props"
                            source="note"
                            label="Note"
                            :ref="'note' + props.index"
                            clearable
                        ></va-text-input>
                        <v-btn
                            outlined
                            fab
                            x-small
                            color="red"
                            @click.stop="removeItem(props)"
                        >
                            <v-icon>mdi-delete-outline</v-icon>
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
        </custom-array-input>
        <v-footer bottom fixed v-if="id">
            <v-row>
                <v-col sm="3"></v-col>
                <v-col sm="9" class="d-flex justify-center">
                    <v-btn
                        v-for="(item, index) in OrderRequestExportTypes"
                        @click="$emit('exported', item.value)"
                        :loading="exporting"
                        :key="index"
                        depressed
                        color="primary"
                        class="mr-5"
                    >
                        {{ item.text }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-footer>
        <v-dialog
            max-width="680"
            v-model="isOpenNoteModal"
            @click:outside="closeNoteModal"
        >
            <v-card>
                <v-toolbar dark color="primary">
                    <v-btn icon dark @click="closeNoteModal">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title v-if="selectedItem">
                        SKU:
                        <a
                            target="_blank"
                            :href="
                                'https://amazon.com/dp/' +
                                selectedItem.item.asin
                            "
                            >{{ selectedItem.item.sku }}</a
                        >
                    </v-toolbar-title>
                </v-toolbar>
                <v-list>
                    <v-list-item>
                        <v-list-item-content>
                            <v-textarea v-model="note" autofocus></v-textarea>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="green darken-1" text @click="closeNoteModal">
                        Close
                    </v-btn>
                    <v-btn color="primary darken-1" text @click="acceptNote">
                        Accept
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </va-form>
</template>

<script>
import { OrderRequestExportTypes } from "@/constants/OrderRequestExportTypes";
import CustomArrayInput from "@/components/inputs/CustomArrayInput";
import UploadStatusColors from "@/constants/UploadStatusColors";
import { omit } from "lodash/object";

export default {
    props: [
        "id",
        "title",
        "item",
        "mwsConfigurationId",
        "cogCatalogItemMap",
        "supplierMap",
        "exporting",
    ],
    components: {
        CustomArrayInput,
    },
    watch: {
        item: {
            immediate: true,
            handler(newVal) {
                if (!newVal) {
                    return;
                }

                this.originalItem = JSON.stringify(newVal);
            },
        },
    },
    mounted() {
        this.model.mws_configuration_id = this.model.mws_configuration_id
            ? this.model.mws_configuration_id
            : this.mwsConfigurationId;
        if (!this.model.children || !this.model.children.length) {
            return;
        }
        this.model.children = this.model.children.map((item) => {
            if (item.image_file) {
                item.preview_image_file = item.image_file;
                item.image_file = new File([""], item.image_file);
            }
            if (item.barcode_file) {
                item.preview_barcode_file = item.barcode_file;
                item.barcode_file = new File([""], item.barcode_file);
            }
            return item;
        });
    },
    computed: {
        showSave() {
            if (!this.model.children) {
                return false;
            }
            const hasEmptySku = this.model.children.find((i) => !i.sku);
            return !hasEmptySku;
        },
    },
    data() {
        return {
            OrderRequestExportTypes,
            filter: {},
            asinSearchResult: {},
            skuSearchResult: {},
            fnSkuSearchResult: {},
            model: {
                name: "",
                mws_configuration_id: 0,
            },
            selectedItem: null,
            isOpenNoteModal: false,
            note: "",
            isDirty: false,
        };
    },
    // beforeRouteLeave(to, from, next) {
    //     // If the form is dirty and the user did not confirm leave,
    //     // prevent losing unsaved changes by canceling navigation
    //     if (this.isDirtyForm()) {
    //         next(false);
    //     } else {
    //         // Navigate to next view
    //         next();
    //     }
    // },

    created() {
        // window.addEventListener("beforeunload", this.beforeWindowUnload);
        this.intervaler = setInterval(this.intervalCheck, 1000);
    },

    beforeDestroy() {
        // window.removeEventListener("beforeunload", this.beforeWindowUnload);
        clearInterval(this.intervaler);
        this.isDirty = false;
    },
    methods: {
        getUploadStatusColor(item) {
            if (
                !item.barcode_file ||
                !item.image_file ||
                !item.original_price
            ) {
                return UploadStatusColors.ONE;
            }
            if (item.barcode_file && item.image_file && item.original_price) {
                return UploadStatusColors.BOTH;
            }
            return UploadStatusColors.NONE;
        },
        removeItem(props) {
            props.remove(props.index);
        },
        selectSearchItem(key, value, itemProps) {
            const selected = this.cogCatalogItemMap.find((i) => {
                return i[key] === value;
            });
            if (!this.model.children) {
                return;
            }

            // sku is not existed
            if (!selected) {
                const converted = this.emptyItem();
                Object.keys(converted).forEach((key) => {
                    itemProps.item[key] = converted[key];
                });
                return;
            }

            const converted = this.convertCogCatalogItemToItem(
                selected,
                this.model.children[itemProps.index]
            );

            Object.keys(converted)
                .filter((k) => k !== "id")
                .forEach((key) => {
                    itemProps.item[key] = converted[key];
                });

            this.$set(this.model.children, itemProps.index, converted);
        },
        convertCogCatalogItemToItem(cogCatalogItem, old) {
            return {
                id: old?.id ? old?.id : 0,
                qty: old?.qty ? Number(old?.qty) : 0,
                row_total: old?.row_total ? Number(old?.row_total) : 0,
                note: old?.note ? old?.note : "",
                sku: cogCatalogItem.sku,
                preview_barcode_file: cogCatalogItem.barcode_file,
                barcode_file: new File([""], cogCatalogItem.barcode_file),
                preview_image_file: cogCatalogItem.image_file,
                image_file: new File([""], cogCatalogItem.image_file),
                asin: cogCatalogItem.asin,
                fn_sku: cogCatalogItem.fn_sku,
                original_price: cogCatalogItem.original_price
                    ? Number(cogCatalogItem.original_price)
                    : 0,
                link: "https://amazon.com/dp/" + cogCatalogItem.asin,
            };
        },
        emptyItem() {
            return {
                qty: 0,
                row_total: 0,
                note: "",
                preview_barcode_file: null,
                barcode_file: null,
                preview_image_file: null,
                image_file: null,
                asin: "",
                fn_sku: "",
                original_price: 0,
                link: "",
            };
        },
        selectImageFile(file, item) {
            item.image_file = file;
            item.preview_image_file = file ? URL.createObjectURL(file) : null;
        },
        async selectBarcodeFile(file, item) {
            item.barcode_file = file;
            item.preview_barcode_file = file ? URL.createObjectURL(file) : null;
        },
        showNoteModal(props) {
            this.selectedItem = props;
            this.isOpenNoteModal = true;
            this.note = this.selectedItem.item.note;
        },
        closeNoteModal() {
            this.selectedItem = null;
            this.isOpenNoteModal = false;
        },
        acceptNote() {
            this.$set(this.model.children, this.selectedItem.index, {
                ...this.selectedItem.item,
                note: this.note,
            });
            this.selectedItem.item.note = this.note;
            this.$refs["note" + this.selectedItem.index].value = this.note;
            this.closeNoteModal();
        },
        required(value) {
            if (value instanceof Array && value.length === 0) {
                return "Required.";
            }
            return !!value || "Required.";
        },

        isDirtyForm() {
            const convertedOriginal = omit(JSON.parse(this.originalItem), [
                "created_at",
                "updated_at",
                "id",
                "status",
                "total",
                "items_qty",
            ]);

            convertedOriginal.children = convertedOriginal.children.map(
                (child, index) => {
                    child.preview_image_file = child.image_file || null;
                    child.preview_barcode_file = child.barcode_file || null;
                    child.barcode_file = child.barcode_file ? {} : null;
                    child.image_file = child.image_file ? {} : null;
                    child.id = index;
                    return child;
                }
            );

            const currentItems = this.model.children.map((i) => {
                return {
                    ...i,
                    original_price: Number(i.original_price),
                    qty: Number(i.qty),
                    link: i.link ? i.link : null,
                    asin: i.asin ? i.asin : null,
                    name: i.name ? i.name : null,
                    fn_sku: i.fn_sku ? i.fn_sku : null,
                    note: i.note ? i.note : null,
                    preview_image_file: i.preview_image_file || null,
                    preview_barcode_file: i.preview_barcode_file || null,
                };
            });

            const keys = Object.keys(convertedOriginal);

            return (
                JSON.stringify(convertedOriginal, keys) !==
                    JSON.stringify(this.model, keys) ||
                JSON.stringify(convertedOriginal.children) !==
                    JSON.stringify(currentItems)
            );
        },

        // beforeWindowUnload(e) {
        //     if (this.isDirtyForm()) {
        //         // Cancel the event
        //         e.preventDefault();
        //         // Chrome requires returnValue to be set
        //         e.returnValue = "";
        //     }
        // },
        intervalCheck() {
            this.isDirty = this.isDirtyForm();
        },
    },
};
</script>
<style lang="sass">

form
    .row
        .v-text-field__details
            display: none
    &.is-dirty
        background: #ffff0040
</style>
