<template>
    <base-material-card :icon="resource.icon" :title="title">
        <va-list :filters="filters">
            <va-data-table :fields="fields"> </va-data-table>
        </va-list>
    </base-material-card>
</template>

<script>
export default {
    props: ["resource", "title"],
    data() {
        return {
            filters: [
                "mws_configuration_id",
                "parent_id",
                "name",
                "asin",
                "sku",
                "fn_sku",
            ],
            fields: [
                "mws_configuration_id",
                "parent_id",
                { source: "name", sortable: true },
                { source: "asin", sortable: true },
                { source: "sku", sortable: true },
                { source: "fn_sku", sortable: true },
                { source: "qty", sortable: true },
                "row_total",
            ],
        };
    },
};
</script>
