<template>
    <div>
        <va-aside-layout :title="asideTitle">
            <fba-inventory-form
                v-if="show"
                :id="id"
                :item="item"
                :mwsConfigurationId="filter.mws_configuration_id"
                :marketplaceIdKey="marketplaceIdKey"
                @saved="onSaved"
            ></fba-inventory-form>
        </va-aside-layout>
        <base-material-card :icon="resource.icon" :title="title">
            <v-tabs v-model="selectedTab">
                <v-tab
                    class="mr-3"
                    v-for="tab in mwsConfigurationOptions"
                    :key="tab.value"
                    @click="onClickTab(tab.value)"
                >
                    <v-icon class="mr-2">mdi-amazon</v-icon>
                    {{ tab.text }}
                </v-tab>
            </v-tabs>
            <v-tabs-items
                v-model="selectedTab"
                class="transparent"
            ></v-tabs-items>
            <va-list
                :filters="filters"
                ref="list"
                class="fba-inventory-list"
                disable-export
                disable-create-redirect
                @action="onAction"
                :filter.sync="filter"
            >
                <va-data-table
                    ref="table"
                    :fields="fields"
                    class="fba-inventory-main-table"
                    disable-clone
                    disable-select
                    disable-edit
                    :disable-delete="disableDelete"
                    disable-show
                    disable-edit-redirect
                    show-expand
                    single-expand
                >
                    <template v-slot:[`field.asin`]="{ value }">
                        <a
                            target="_blank"
                            :href="'https://amazon.com/dp/' + value"
                            >{{ value }}</a
                        >
                    </template>
                    <template v-slot:[`field.small_image`]="{ value }">
                        <img width="75" alt="small_image" :src="value ? value.URL : ''" />
                    </template>
                    <template v-slot:[`field.is_archived`]="{ item }">
                        <v-switch
                            :false-value="false"
                            :true-value="true"
                            :input-value="item.is_archived"
                            @change="
                                (value) => changeIsArchived(item.id, value)
                            "
                        ></v-switch>
                    </template>
                    <template v-slot:expanded-item="{ item }">
                        <v-data-table
                            v-if="item.children.length"
                            hide-default-header
                            hide-default-footer
                            class="fba-inventory-item-headers"
                            :headers="item_headers"
                            :items="item.children"
                        >
                            <template v-slot:item.is_archived="{ item }">
                                <v-switch
                                    :false-value="false"
                                    :true-value="true"
                                    :input-value="item.is_archived"
                                    @change="
                                        (value) =>
                                            changeIsArchived(item.id, value)
                                    "
                                ></v-switch>
                            </template>
                        </v-data-table>
                    </template>
                </va-data-table>
            </va-list>
        </base-material-card>
    </div>
</template>

<script>
import MarketplaceIds from "@/constants/MarketplaceIds";

export default {
    props: ["resource", "title"],
    computed: {
        mwsConfigurationOptions() {
            return this.$store.getters.mwsConfigurationOptions;
        },
    },
    watch: {
        mwsConfigurationId: {
            immediate: true,
            handler(newVal) {
                if (!newVal) {
                    return;
                }
                const current = this.filter["mws_configuration_id"] || [];
                if (current.includes(newVal)) {
                    return;
                }
                this.filter["mws_configuration_id"] = [newVal];
            },
        },
        mwsConfigurationOptions: {
            immediate: true,
            handler(newVal) {
                if (!newVal || !newVal.length) {
                    return;
                }
                const filter = this.filters.find(
                    (filter) => filter.source === "mws_configuration_id"
                );
                filter.attributes.choices = newVal;
                this.findSelectedTab(newVal);
            },
        },
    },
    data() {
        return {
            selectedTab: 0,
            marketplaceIdKey: "main_marketplace_id",
            filter: {
                second_marketplace: 0,
            },
            filters: [
                {
                    label: "App Configuration",
                    source: "mws_configuration_id",
                    type: "select",
                    attributes: {
                        choices: this.mwsConfigurationOptions,
                        multiple: true,
                    },
                    alwaysOn: true,
                },
                { source: "asin", type: "text", alwaysOn: true },
                { source: "sellerSku", type: "text", alwaysOn: true },
                { source: "fnSku", type: "text", alwaysOn: true },
                { source: "is_archived", type: "boolean", alwaysOn: true },
                {
                    source: "marketplace_id",
                    type: "select",
                    attributes: {
                        multiple: true,
                        choices: MarketplaceIds,
                    },
                    alwaysOn: true,
                },
            ],
            fields: [
                { source: "id", sortable: true },
                {
                    label: "Image",
                    source: "small_image",
                    sortable: false,
                },
                { source: "asin", sortable: true },
                { source: "sellerSku", sortable: true },
                { source: "fnSku", sortable: true },
                {
                    label: "Quantity",
                    source: "Quantity_NewItem",
                    sortable: true,
                },
                {
                    source: "is_archived",
                    sortable: true,
                },
            ],
            item_headers: [
                { value: "none", sortable: true },
                { value: "none", sortable: true },
                { value: "none", sortable: true },
                { value: "none", sortable: true },
                { text: "sellerSku", value: "sellerSku", sortable: true },
                { text: "fnSku", value: "fnSku", sortable: true },
                {
                    text: "totalQuantity",
                    value: "Quantity_NewItem",
                    sortable: true,
                },
                { value: "is_archived", sortable: true },
            ],
            asideTitle: null,
            id: null,
            item: null,
            show: false,
            disableDelete: false,
            mwsConfigurationMap: {},
        };
    },
    methods: {
        onClickTab(id) {
            this.filter["mws_configuration_id"] = id ? id : "";
            if (id) {
                const mwsConfigurationOption =
                    this.mwsConfigurationOptions.find(
                        (mwsConfiguration) => mwsConfiguration.value === id
                    );
                this.filter["marketplace_id"] =
                    mwsConfigurationOption[this.marketplaceIdKey];
            } else {
                this.filter["marketplace_id"] = "";
            }
            if (!this.show) {
                return;
            }
            this.show = false;
        },
        findSelectedTab(tabs) {
            const { filter } = this.$route.query;
            if (!filter) {
                return;
            }
            const currentFilter = JSON.parse(filter);
            if (!currentFilter["mws_configuration_id"]) {
                return;
            }
            this.selectedTab = parseInt(
                currentFilter["mws_configuration_id"],
                10
            );
            if (!this.selectedTab) {
                return;
            }
            this.selectedTab = tabs.findIndex(
                (item) => item.value === this.selectedTab
            );
        },
        async changeIsArchived(id, is_archived) {
            try {
                await this.$store.dispatch("FbaInventory/update", {
                    id,
                    data: { is_archived },
                });
            } catch (e) {
                console.log(e);
            } finally {
                await this.$store.dispatch(
                    "api/refresh",
                    this.$route.meta.resource
                );
            }
        },
        async onAction({ action, id, item }) {
            this.asideTitle = item
                ? `Edit FBA Inventory: ${item.name}`
                : "New FBA Inventory";
            this.id = id;
            this.show = ["create", "edit"].includes(action);
            this.item = item;
        },
        onSaved() {
            this.asideTitle = null;
            this.$refs.list.fetchData();
        },
    },
};
</script>
<style lang="sass">
$filter-height: 120px
.fba-inventory-list
    > header
        height: $filter-height !important

        > div
            height: $filter-height !important

            > form
                > div
                    > div:nth-child(2)
                        display: none !important

    > form
        > div
            > div
                height: 65vh
                overflow-y: auto

                > table
                    > thead
                        position: sticky
                        top: 0
                        background: white
                        z-index: 9999

                    > tbody
                        > tr
                            > td
                                button
                                    background: transparent !important
                            td:nth-child(2)
                                width: 50px
                            td:nth-child(3)
                                width: 108px
                            td:nth-child(5)
                                width: 250px !important
                                text-align: left !important

                            td:nth-child(6), td:nth-child(7)
                                width: 150px !important
                                text-align: right !important

                            td:last-child
                                width: 85px !important

                                label
                                    display: none !important
</style>
