<script>
import Export from "@/resources/cog-catalog-item/Export";
import { getAsinAge } from "@/constants/AsinAgeTypes";

export default {
    mixins: [Export],

    data() {
        return {
            entity: "SaleInventory",
        };
    },
    methods: {
        getExportHeader() {
            return [
                "No",
                "Brand",
                "marketplace_id",
                "ASIN",
                "SKU",
                "FnSKU",
                "tags",
                "Supplier",
                "PIC",
                "ASIN Age",
                "Yesterday",
                "Last 3 days",
                "Last 7 days",
                "Last 15 days",
                "Last 30 days",
                "Average per day (EMA)",
                "Average per day (SMA)",
                "Available",
                "Inbound",
                "Reserved",
                "Prepare",
                "For available inventory (EMA)",
                "For available inventory (SMA)",
            ];
        },
        getExportRowData(item, index, brandMap) {
            let tags = [
                item.is_low_price ? "LowPrice" : false,
                item.is_neg_gp ? "NegGP" : false,
            ]
                .filter(Boolean)
                .join("|");

            const rows = [
                [
                    index,
                    brandMap[item.mws_configuration_id],
                    item.marketplace_id,
                    item.asin,
                    item.sku,
                    item.fnSku,
                    tags,
                    item.suppliers
                        ? item.suppliers.map((i) => i.name).join(", ")
                        : "",
                    item.pics ? item.pics.map((i) => i.name).join(", ") : "",
                    getAsinAge(item),
                    item.sale_1_day,
                    item.sum_sale_3_day,
                    item.sum_sale_7_day,
                    item.sum_sale_15_day,
                    item.sum_sale_30_day,
                    item.ema,
                    item.sma,
                    item.stock_available,
                    item.stock_inbound,
                    item.stock_reserved,
                    item.stock_prepare,
                    item.ema_remaining_for_available,
                    item.sma_remaining_for_available,
                ],
            ];

            if (item.children && item.children.length) {
                item.children.forEach((child) => {
                    const childRows = this.getExportRowData(
                        child,
                        "",
                        brandMap
                    );
                    rows.push(...childRows);
                });
            }

            return rows;
        },
    },
};
</script>
