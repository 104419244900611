<template>
    <base-material-card :icon="resource.icon" :title="title">
        <va-list :filters="filters" :filter.sync="filter">
            <va-data-table :fields="fields" disable-clone> </va-data-table>
        </va-list>
    </base-material-card>
</template>

<script>
export default {
    props: ["resource", "title", "filter"],
    data() {
        return {
            filters: ["cog_catalog_item_id", "quantity", "cog"],
            fields: [
                { source: "cog_catalog_item_id", sortable: false },
                { source: "quantity", sortable: true },
                { label: "COGs", source: "cog", sortable: true },
            ],
        };
    },
};
</script>
