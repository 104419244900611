<template>
    <div>
        <va-aside-layout :title="asideTitle">
            <financial-transaction-adjust-cog
                v-show="showAdjustCog"
                @saved="onSaved"
                :mwsConfigurationOptions="mwsConfigurationOptions"
            ></financial-transaction-adjust-cog>
        </va-aside-layout>
        <base-material-card :icon="resource.icon" :title="title">
            <v-tabs v-model="selectedTab">
                <v-tab
                    class="mr-3"
                    v-for="tab in mwsConfigurationOptions"
                    :key="tab.value"
                    @click="onClickTab(tab.value)"
                >
                    <v-icon class="mr-2">mdi-amazon</v-icon>
                    {{ tab.text }}
                </v-tab>
            </v-tabs>
            <v-tabs-items v-model="selectedTab" class="transparent">
            </v-tabs-items>
            <va-list
                ref="list"
                class="financial-transaction-list"
                :resource="resource.name"
                :filters="filters"
                :items-per-page="perPage"
                :filter.sync="filter"
                :sort-by.sync="sort.by"
                :sort-desc.sync="sort.desc"
                disable-create
                disable-export
            >
                <template v-slot:actions>
                    <va-action-button
                        label="Adjust COGs"
                        icon="mdi-upload"
                        text
                        @click="
                            asideTitle = 'Adjust COGs';
                            showAdjustCog = true;
                        "
                    ></va-action-button>
                </template>
                <template v-slot:filter.date="props">
                    <date-range-picker
                        ref="picker"
                        :opens="'right'"
                        :timePicker="false"
                        :showWeekNumbers="true"
                        :showDropdowns="true"
                        :autoApply="true"
                        v-model="dateRange"
                        v-bind="props"
                        @update="(val) => update(val)"
                        :locale-data="{
                            firstDay: 1,
                        }"
                    >
                        <template
                            v-slot:input="picker"
                            style="min-width: 350px"
                        >
                            <label
                                v-if="!picker.startDate || !picker.startDate"
                                class="v-label theme--light"
                            >
                                Date Range
                            </label>
                            <template v-else>
                                {{
                                    picker.startDate
                                        | moment("timezone", tz, "L")
                                }}
                                -
                                {{
                                    picker.endDate | moment("timezone", tz, "L")
                                }}
                            </template>
                        </template>
                    </date-range-picker>
                </template>
                <va-data-table
                    ref="table"
                    :fields="fields"
                    class="main-table"
                    disable-actions
                    disable-select
                    show-expand
                >
                    <template v-slot:[`field.date`]="{ value }">
                        <span>{{
                            value | moment("timezone", sourceTz, "LLL")
                        }}</span>
                    </template>
                    <template v-slot:[`field.NET`]="{ item }">
                        <span>{{ getTotalNet(item).toFixed(2) }}</span>
                    </template>
                    <template v-slot:[`expanded-item`]="{ item }">
                        <template v-if="item.entity_type !== 'OperationFee'">
                            <v-data-table
                                v-if="
                                    item.entity_type !== 'AdjustmentEvent' &&
                                    item.children &&
                                    item.children.length &&
                                    item.children[0].SellerSKU
                                "
                                hide-default-header
                                hide-default-footer
                                show-expand
                                class="item-headers"
                                :items-per-page="100"
                                :headers="item_headers"
                                :items="item.children"
                            >
                                <template v-slot:item.NET="{ item }">
                                    {{
                                        (item.amount - (item.cog || 0)).toFixed(
                                            2
                                        )
                                    }}
                                </template>
                                <template v-slot:expanded-item="{ item }">
                                    <td colspan="4" v-if="item.children.length">
                                        <v-data-table
                                            hide-default-header
                                            hide-default-footer
                                            class="transition-headers"
                                            :items-per-page="100"
                                            :headers="transition_headers"
                                            :items="item.children"
                                        ></v-data-table>
                                    </td>
                                </template>
                            </v-data-table>
                            <v-data-table
                                v-else-if="
                                    item.entity_type !== 'AdjustmentEvent' &&
                                    item.children &&
                                    item.children.length
                                "
                                hide-default-header
                                hide-default-footer
                                class="transition-headers"
                                :items-per-page="100"
                                :headers="transition_headers"
                                :items="item.children"
                            ></v-data-table>
                            <v-data-table
                                v-else-if="item.transaction_type"
                                hide-default-header
                                hide-default-footer
                                class="transition-headers"
                                :items-per-page="100"
                                :headers="transition_headers"
                                :items="[item]"
                            ></v-data-table>
                        </template>
                        <template v-if="item.entity_type === 'OperationFee'">
                            <v-data-table
                                hide-default-header
                                hide-default-footer
                                class="operation-fee-headers"
                                :items-per-page="100"
                                :headers="operation_fee_headers"
                                :items="item.children"
                            ></v-data-table>
                        </template>
                    </template>
                </va-data-table>
            </va-list>
        </base-material-card>
    </div>
</template>

<script>
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import DateRangePicker from "vue2-daterange-picker";
import moment from "moment-timezone";
import { tz, sourceTz } from "@/constants/Time";
import FinancialEventEntityTypes from "@/constants/FinancialEventEntityTypes";
import MarketplaceIds from "@/constants/MarketplaceIds";

export default {
    props: {
        resource: {
            type: Object,
        },
        mwsConfigurationId: {
            type: Number,
        },
        title: {
            type: String,
        },
        perPage: {
            type: Number,
            default: 15,
        },
    },
    components: { DateRangePicker },
    computed: {
        mwsConfigurationOptions() {
            return this.$store.getters.mwsConfigurationOptions;
        },
    },
    watch: {
        mwsConfigurationId: {
            immediate: true,
            handler(newVal) {
                if (!newVal) {
                    return;
                }
                const current = this.filter["mws_configuration_id"] || [];
                if (current.includes(newVal)) {
                    return;
                }
                this.filter["mws_configuration_id"] = [newVal];
            },
        },
        mwsConfigurationOptions: {
            immediate: true,
            handler(newVal) {
                if (!newVal || !newVal.length) {
                    return;
                }
                const filter = this.filters.find(
                    (filter) => filter.source === "mws_configuration_id"
                );
                filter.attributes.choices = newVal;
            },
        },
    },
    beforeMount() {
        const { filter } = this.$route.query;
        if (!filter) {
            this.dateRange.startDate = moment().tz(tz).startOf("month");
            this.dateRange.endDate = moment().tz(tz).endOf("month");
            return;
        }
        const currentFilter = JSON.parse(filter);
        if (!currentFilter["date_between"]) {
            return;
        }
        const [start, end] = currentFilter["date_between"].split(",");
        this.dateRange.startDate = moment(start);
        this.dateRange.endDate = moment(end);
    },
    async mounted() {
        this.$watch(
            () => {
                return this.$refs.list.listState.items;
            },
            () => {
                this.updateSummary();
            }
        );
    },
    data() {
        return {
            tz: tz,
            sourceTz: sourceTz,
            dateFormat: this.$admin.options.dateFormat,
            selectedTab: 0,
            dateRange: {},
            filter: {
                date_between: `${moment()
                    .startOf("month")
                    .format("YYYY-MM-DD 00:00:00")},${moment()
                    .endOf("month")
                    .format("YYYY-MM-DD 23:59:59")}`,
                mws_configuration_id: this.mwsConfigurationId
                    ? [this.mwsConfigurationId]
                    : [],
            },
            sort: {
                by: ["date"],
                desc: [true],
            },
            filters: [
                {
                    label: "App Configuration",
                    source: "mws_configuration_id",
                    type: "select",
                    attributes: {
                        choices: this.mwsConfigurationOptions,
                        multiple: true,
                    },
                    alwaysOn: true,
                },
                {
                    source: "entity_type",
                    type: "select",
                    attributes: {
                        choices: FinancialEventEntityTypes,
                        multiple: true,
                    },
                    alwaysOn: true,
                },
                { source: "date", type: "date", alwaysOn: true },
                { source: "AmazonOrderId", type: "text", alwaysOn: true },
                { source: "SellerSKU", type: "text", alwaysOn: true },
                { source: "ASIN", type: "text", alwaysOn: true },
                {
                    source: "MarketplaceId",
                    type: "select",
                    attributes: {
                        multiple: true,
                        choices: MarketplaceIds,
                    },
                    alwaysOn: true,
                },
                {
                    label: "PIC",
                    source: "pic_ids",
                    type: "select",
                    attributes: {
                        itemText: "name",
                        multiple: true,
                        reference: "users",
                    },
                    alwaysOn: true,
                },
                // { source: "quantity", type: "text" },
                // { source: "amount", type: "text" },
            ],
            fields: [
                { source: "entity_type", sortable: true },
                { source: "AmazonOrderId", sortable: true },
                { source: "SellerSKU", sortable: true },
                { source: "ASIN", sortable: true },
                { source: "date", sortable: true },
                {
                    source: "MarketplaceId",
                    sortable: false,
                    type: "select",
                    attributes: { choices: MarketplaceIds },
                },
                { source: "quantity", sortable: true },
                { source: "amount", sortable: true },
                { label: "COGs", source: "cog", sortable: true },
                { label: "NET", source: "NET", sortable: false },
            ],
            item_headers: [
                { text: "SellerSKU", value: "SellerSKU", sortable: true },
                { text: "MarketplaceId", value: "MarketplaceId", sortable: false },
                { text: "quantity", value: "quantity", sortable: true },
                { text: "amount", value: "amount", sortable: true },
                { text: "COGs", value: "cog", sortable: true },
                {
                    text: "NET",
                    value: "NET",
                    sortable: true,
                },
            ],
            transition_headers: [
                {
                    text: "transaction_type",
                    value: "transaction_type",
                    sortable: true,
                },
                { text: "amount", value: "amount", sortable: true },
            ],
            operation_fee_headers: [
                { value: "none", sortable: true },
                {
                    value: "transaction_type",
                    sortable: true,
                },
                { value: "AmazonOrderId", sortable: true },
                { value: "SellerSKU", sortable: true },
                { value: "ASIN", sortable: true },
                { value: "none", sortable: true },
                { value: "quantity", sortable: true },
                { value: "amount", sortable: true },
                { value: "none", sortable: false },
                { value: "none", sortable: false },
            ],
            showAdjustCog: false,
            asideTitle: null,
            id: null,
            item: null,
        };
    },
    methods: {
        update(value) {
            const start = moment(value.startDate);
            const end = moment(value.endDate);
            this.filter = {
                ...this.filter,
                date_between: `${start
                    .clone()
                    .tz(tz)
                    .format("YYYY-MM-DD 00:00:00")},${end
                    .clone()
                    .tz(tz)
                    .format("YYYY-MM-DD 23:59:59")}`,
            };
        },
        async updateSummary() {
            const { data } = await this.$store.dispatch(
                "FinancialTransactionSummary/getList",
                {
                    filter: this.filter,
                }
            );
            const { amount, quantity, cog } = data;
            const thead = this.$refs.table.$el.querySelector("thead");
            const row = thead.querySelector("#total-row") || thead.insertRow();
            row.setAttribute("id", "total-row");
            const rowLength = row.cells.length;
            let totalLabelCell = rowLength ? row.cells[0] : row.insertCell();
            totalLabelCell.innerHTML = "Total";
            totalLabelCell.colSpan = 7;
            let totalQtyCell = rowLength ? row.cells[1] : row.insertCell();
            totalQtyCell.innerHTML = quantity;
            let totalValueCell = rowLength ? row.cells[2] : row.insertCell();
            totalValueCell.innerHTML = amount.toFixed(2);
            let totalCogsCell = rowLength ? row.cells[3] : row.insertCell();
            totalCogsCell.innerHTML = cog.toFixed(2);
            let netCell = rowLength ? row.cells[4] : row.insertCell();
            netCell.innerHTML = (amount - cog).toFixed(2);
        },
        onClickTab(id) {
            this.filter["mws_configuration_id"] = id ? id : "";
        },
        findSelectedTab(tabs) {
            const { filter } = this.$route.query;
            if (!filter) {
                return;
            }
            const currentFilter = JSON.parse(filter);
            if (!currentFilter["mws_configuration_id"]) {
                return;
            }
            this.selectedTab = parseInt(
                currentFilter["mws_configuration_id"],
                10
            );
            if (!this.selectedTab) {
                return;
            }
            this.selectedTab = tabs.findIndex(
                (item) => item.value === this.selectedTab
            );
        },
        getTotalNet(item) {
            return item.amount - (item.cog || 0);
        },
        onSaved() {
            this.asideTitle = null;
            this.$refs.list.fetchData();
        },
    },
};
</script>
<style lang="sass">
$filter-height: 120px
$arrow-width: 52px
$number-width: 120px
$number-align: right
$date-width: 11%
$sku-width: 10%
$order-width: 10%
$entity-width: 12%
#app
    main
        div.container.flex.container--fluid
            > div
                > div
                    margin-top: 0
                div.d-flex.grow.flex-wrap
                    display: none !important
                div.v-data-iterator.financial-transaction-list
                    > header
                        height: $filter-height !important
                        > div
                            height: $filter-height !important
                            > form
                                > div
                                    > div:nth-child(2)
                                        display: none !important
                    .v-data-table__expanded.v-data-table__expanded__content
                        > td
                            padding-left: 0 !important
                            padding-right: 0 !important
                    .main-table
                        > .v-data-table
                            > .v-data-table__wrapper
                                > table
                                    thead
                                        > tr#total-row
                                            td
                                                font-weight: bold
                                            > td:nth-child(2), > td:nth-child(3), > td:nth-child(4), > td:nth-child(5)
                                                width: $number-width !important
                                                text-align: $number-align !important
                                    > tbody
                                        > tr
                                            > td:nth-child(1)
                                                width: $arrow-width
                                            > td:nth-child(2)
                                                width: $entity-width
                                            > td:nth-child(3)
                                                //width: $order-width
                                            > td:nth-child(4), td:nth-child(5)
                                                width: $sku-width !important
                                            > td:nth-child(6), td:nth-child(7)
                                                width: $date-width !important
                                            > td:nth-child(8), > td:nth-child(9) , > td:nth-child(10), > td:nth-child(11)
                                                width: $number-width !important
                                                text-align: $number-align !important
                        .item-headers
                             > .v-data-table__wrapper
                                > table
                                    > tbody
                                        > tr
                                            > td:nth-child(1)
                                                width: $arrow-width
                                            > td:nth-child(2)
                                                padding-left: 50px
                                            > td:nth-child(4), > td:nth-child(5), > td:nth-child(6), > td:nth-child(7)
                                                width: $number-width !important
                                                text-align: $number-align !important
                             .transition-headers
                                 > .v-data-table__wrapper
                                    > table
                                        > tbody
                                            > tr
                                                > td:nth-child(1)
                                                    padding-left: 200px
                        .transition-headers
                             > .v-data-table__wrapper
                                > table
                                    > tbody
                                        > tr
                                            > td:nth-child(1)
                                                padding-left: 100px
                                            > td:nth-child(2)
                                                width: $number-width !important
                                                text-align: $number-align !important
                        .operation-fee-headers
                            > .v-data-table__wrapper
                                > table
                                    > tbody
                                        > tr
                                            > td:nth-child(1)
                                                width: $arrow-width
                                            > td:nth-child(2)
                                                padding-left: 50px
                                                width: $entity-width
                                            > td:nth-child(4), td:nth-child(5)
                                                width: $sku-width !important
                                            > td:nth-child(6), td:nth-child(7)
                                                    width: $date-width !important
                                            > td:nth-child(8), > td:nth-child(9) , > td:nth-child(10), > td:nth-child(11)
                                                width: $number-width !important
                                                text-align: $number-align !important
</style>
<style>
@media screen and (min-width: 768px) {
    .daterangepicker.show-ranges.show-weeknumbers,
    .daterangepicker.show-ranges {
        min-width: 720px !important;
    }
}

.reportrange-text {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
}
</style>
