<template>
    <va-form
        :id="id"
        :item="item"
        v-model="model"
        disable-redirect
        @saved="$emit('saved')"
    >
        <va-select-input
            label="Brand"
            source="mws_configuration_id"
            :choices="mwsConfigurationMapOptions"
            required
        ></va-select-input>
        <va-text-input source="name" required></va-text-input>
        <va-text-input source="code"></va-text-input>
        <va-text-input :multiline="true" source="address"></va-text-input>
        <va-text-input source="contact"></va-text-input>
        <va-text-input
            :multiline="true"
            source="payment_information"
        ></va-text-input>
        <va-text-input :multiline="true" source="note"></va-text-input>
        <va-save-button></va-save-button>
    </va-form>
</template>

<script>
export default {
    props: ["id", "item", "mwsConfigurationId"],
    computed: {
        mwsConfigurationOptions() {
            return this.$store.getters.mwsConfigurationOptions;
        },
    },
    watch: {
        mwsConfigurationOptions: {
            immediate: true,
            handler(newVal = []) {
                if (!newVal || !newVal.length) {
                    return;
                }
                this.mwsConfigurationMapOptions = newVal.map((option) => {
                    return {
                        value: parseInt(option.value, 10),
                        text: option.text,
                    };
                });
            },
        },
        mwsConfigurationId: {
            immediate: true,
            handler(newVal) {
                if (!newVal) {
                    return;
                }
                this.model.mws_configuration_id = parseInt(newVal, 10);
            },
        },
    },
    data() {
        return {
            mwsConfigurationMapOptions: {},
            model: {},
        };
    },
    methods: {
        hasRole(role) {
            return (this.model.roles || []).includes(role);
        },
    },
};
</script>
