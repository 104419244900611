const AdvertisingRegions = [
    {
        value: "eu",
        text: "eu",
    },
    {
        value: "na",
        text: "na",
    },
    {
        value: "fe",
        text: "fe",
    },
];

export const vTableHeaders = AdvertisingRegions.map((item) => ({
    text: item.text,
    value: item.value,
    sortable: true,
}));

export default AdvertisingRegions;
