<template>
    <v-form ref="form" v-model="valid" lazy-validation>
        <v-container fluid>
            <v-row>
                <v-col cols="12" md="3">
                    <v-text-field
                        v-model="quantity"
                        :counter="9"
                        :rules="quantityRules"
                        label="Quantity"
                        required
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                    <v-text-field
                        v-model="cog"
                        :counter="9"
                        :rules="cogRules"
                        label="COGs"
                        required
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                    <v-text-field
                        v-model="shipment_code"
                        label="Shipment Code"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                    <v-btn
                        :loading="isLoading"
                        :disabled="!valid"
                        color="success"
                        class="mr-4"
                        @click="save"
                    >
                        Save
                    </v-btn>

                    <v-btn
                        :disabled="isLoading"
                        color="info"
                        class="mr-4"
                        @click="reset"
                    >
                        Reset Form
                    </v-btn>
                </v-col>
            </v-row></v-container
        >
    </v-form>
</template>

<script>
export default {
    props: ["cogCatalogItemId"],
    data: () => ({
        valid: true,
        isLoading: false,
        quantity: "",
        quantityRules: [
            (v) => !!v || "Quantity is required",
            (v) => (v && v > 0) || "Quantity must be greater than 0",
        ],
        cog: "",
        cogRules: [
            (v) => !!v || "COGs is required",
            (v) => (v && v > 0) || "COGs must be greater than 0",
        ],
        shipment_code: "",
    }),

    mounted() {
        this.$root.$on("afterDeleteAndRevert", (item) => {
            this.quantity = item.quantity;
            this.cog = item.cog;
            this.shipment_code = item.shipment_code;
        });
    },

    methods: {
        async save() {
            const valid = this.$refs.form.validate();
            if (!valid) {
                return;
            }
            this.isLoading = true;
            try {
                await this.$store.dispatch("CogCatalogItemTransaction/create", {
                    data: {
                        cog_catalog_item_id: this.cogCatalogItemId,
                        quantity: this.quantity,
                        cog: this.cog,
                        shipment_code: this.shipment_code,
                    },
                });
            } catch (e) {
                console.log(e);
            } finally {
                this.isLoading = false;
                this.reset();
                this.$emit("afterSave");
            }
        },
        reset() {
            this.$refs.form.reset();
        },
    },
};
</script>
