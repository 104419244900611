<script>
import List from "@/resources/fba-inventory/List";

export default {
    mixins: [List],
    computed: {
        mwsConfigurationOptions() {
            return this.$store.getters.mwsConfigurationOptions.filter(
                (option) => option.second_marketplace_id || !option.value
            );
        },
    },
    data() {
        return {
            marketplaceIdKey: "second_marketplace_id",
            filter: {
                second_marketplace: 1,
            },
        };
    },
};
</script>
