<template>
    <div>
        <va-aside-layout :title="asideTitle">
            <supplier-form
                v-if="show"
                :id="id"
                :item="item"
                :mwsConfigurationId="filter.mws_configuration_id"
                @saved="onSaved"
            ></supplier-form>
        </va-aside-layout>
        <base-material-card :icon="resource.icon" :title="title">
            <v-tabs v-model="selectedTab">
                <v-tab
                    class="mr-3"
                    v-for="tab in mwsConfigurationOptions"
                    :key="tab.value"
                    @click="onClickTab(tab.value)"
                >
                    <v-icon class="mr-2">mdi-amazon</v-icon>
                    {{ tab.text }}
                </v-tab>
            </v-tabs>
            <v-tabs-items v-model="selectedTab" class="transparent">
            </v-tabs-items>
            <va-list
                :filters="filters"
                ref="list"
                class="supplier-list"
                disable-create-redirect
                disable-export
                @action="onAction"
                :filter.sync="filter"
            >
                <va-data-table
                    ref="table"
                    :fields="fields"
                    class="supplier-main-table"
                    disable-clone
                    disable-show
                    disable-select
                    disable-edit-redirect
                    @item-action="onAction"
                >
                </va-data-table>
            </va-list>
        </base-material-card>
    </div>
</template>

<script>
export default {
    props: ["resource", "title"],
    computed: {
        mwsConfigurationOptions() {
            return this.$store.getters.mwsConfigurationOptions;
        },
    },
    watch: {
        mwsConfigurationId: {
            immediate: true,
            handler(newVal) {
                if (!newVal) {
                    return;
                }
                const current = this.filter["mws_configuration_id"] || [];
                if (current.includes(newVal)) {
                    return;
                }
                this.filter["mws_configuration_id"] = [newVal];
            },
        },
        mwsConfigurationOptions: {
            immediate: true,
            handler(newVal) {
                if (!newVal || !newVal.length) {
                    return;
                }
                const filter = this.filters.find(
                    (filter) => filter.source === "mws_configuration_id"
                );
                filter.attributes.choices = newVal;
                this.findSelectedTab(newVal);
            },
        },
    },
    data() {
        return {
            selectedTab: 0,
            filter: {},
            filters: [
                {
                    label: "Brand",
                    source: "mws_configuration_id",
                    type: "select",
                    attributes: {
                        choices: this.mwsConfigurationOptions,
                        multiple: true,
                    },
                    alwaysOn: true,
                },
                { source: "name", type: "text", alwaysOn: true },
                { source: "code", type: "text", alwaysOn: true },
            ],
            fields: [
                { source: "name", sortable: true },
                { source: "code", sortable: true },
                { source: "address", sortable: false },
                { source: "contact", sortable: false },
                { source: "payment_information", sortable: false },
                { source: "note", sortable: false },
            ],
            asideTitle: null,
            id: null,
            item: null,
            show: false,
            mwsConfigurationMap: {},
        };
    },
    methods: {
        onClickTab(id) {
            this.filter["mws_configuration_id"] = id ? id : "";
            if (!this.show) {
                return;
            }
            this.show = false;
        },
        findSelectedTab(tabs) {
            const { filter } = this.$route.query;
            if (!filter) {
                return;
            }
            const currentFilter = JSON.parse(filter);
            if (!currentFilter["mws_configuration_id"]) {
                return;
            }
            this.selectedTab = parseInt(
                currentFilter["mws_configuration_id"],
                10
            );
            if (!this.selectedTab) {
                return;
            }
            this.selectedTab = tabs.findIndex(
                (item) => item.value === this.selectedTab
            );
        },
        async onAction({ action, id, item }) {
            this.asideTitle = item
                ? `Edit Supplier: ${item.name}`
                : "New Supplier";
            this.id = id;
            this.show = ["create", "edit"].includes(action);
            this.item = item;
        },
        onSaved() {
            this.asideTitle = null;
            this.$refs.list.fetchData();
        },
    },
};
</script>
<style lang="sass">
$filter-height: 80px

.menuable__content__active
    z-index: 9999 !important
.supplier-list
     > header
        height: $filter-height !important
        > div
            height: $filter-height !important
            > form
                > div
                    > div:nth-child(2)
                        display: none !important
     > form
        > div
            > div
                height: 65vh
                overflow-y: auto
                > table
                    > thead
                        position: sticky
                        top: 0
                        background: white
                        z-index: 9999
                    > tbody
                            > tr
                                td:last-child
                                    width: 80px
</style>
