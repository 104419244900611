const NONE = "NONE";
const ONE = "ONE";
const BOTH = "BOTH";

export const ColorMap = {
    [NONE]: "red",
    [ONE]: "orange",
    [BOTH]: "green",
};

export default ColorMap;
