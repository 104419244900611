const GET_CAMPAIGNS_REPORT_TYPE = "spCampaigns";
const GET_TARGETING_REPORT_TYPE = "spTargeting";
const GET_SEARCH_TERM_REPORT_TYPE = "spSearchTerm";
const GET_ADVERTISED_PRODUCT_REPORT_TYPE = "spAdvertisedProduct";
const GET_PURCHASED_PRODUCT_REPORT_TYPE = "spPurchasedProduct";

const AdsReportTypes = [
    {
        value: GET_CAMPAIGNS_REPORT_TYPE,
        text: GET_CAMPAIGNS_REPORT_TYPE.replace("sp", ""),
    },
    {
        value: GET_TARGETING_REPORT_TYPE,
        text: GET_TARGETING_REPORT_TYPE.replace("sp", ""),
    },
    {
        value: GET_SEARCH_TERM_REPORT_TYPE,
        text: GET_SEARCH_TERM_REPORT_TYPE.replace("sp", ""),
    },
    {
        value: GET_ADVERTISED_PRODUCT_REPORT_TYPE,
        text: GET_ADVERTISED_PRODUCT_REPORT_TYPE.replace("sp", ""),
    },
    {
        value: GET_PURCHASED_PRODUCT_REPORT_TYPE,
        text: GET_PURCHASED_PRODUCT_REPORT_TYPE.replace("sp", ""),
    },
];

export const vTableHeaders = AdsReportTypes.map((item) => ({
    text: item.text,
    value: item.value,
    sortable: true,
}));

export default AdsReportTypes;
