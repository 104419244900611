<template>
    <va-form :id="id" :item="item">
        <v-row justify="center">
            <v-col sm="6">
                <base-material-card>
                    <template v-slot:heading>
                        <div class="display-2">
                            {{ title }}
                        </div>
                    </template>
                    <v-card-text>
                        <MwsConfigurationSelectInput
                            source="parent_id"
                        ></MwsConfigurationSelectInput>
                        <va-date-input source="before" required></va-date-input>
                        <va-date-input source="after" required></va-date-input>
                        <va-text-input source="additional_data"></va-text-input>
                    </v-card-text>
                    <va-save-button></va-save-button>
                </base-material-card>
            </v-col>
        </v-row>
    </va-form>
</template>

<script>
import MwsConfigurationSelectInput from "@/components/MwsConfigurationSelectInput";

export default {
    components: {
        MwsConfigurationSelectInput,
    },
    props: ["id", "title", "item"],
};
</script>
