<template>
    <va-form
        :id="id"
        :item="item"
        v-model="model"
        disable-redirect
        @saved="$emit('saved')"
    >
        <va-select-input
            source="mws_configuration_id"
            :choices="mwsConfigurationMapOptions"
        ></va-select-input>
        <va-text-input v-show="false" source="entity_type"></va-text-input>
        <va-date-input source="date"></va-date-input>
        <custom-array-input source="children" label="Details" v-slot="props">
            <v-row>
                <v-col sm="6">
                    <va-text-input
                        v-bind="props"
                        source="SellerSKU"
                        label="SKU"
                    ></va-text-input>
                </v-col>
                <v-col sm="6">
                    <va-text-input
                        v-bind="props"
                        source="ASIN"
                        label="ASIN"
                    ></va-text-input>
                </v-col>
            </v-row>
            <v-row>
                <v-col sm="6">
                    <va-text-input
                        v-bind="props"
                        source="transaction_type"
                        label="Fee Type"
                    ></va-text-input>
                </v-col>
                <v-col sm="6">
                    <va-number-input
                        v-bind="props"
                        source="amount"
                        label="Amount"
                    ></va-number-input>
                </v-col>
            </v-row>
            <v-row>
                <v-col sm="12">
                    <va-number-input
                        v-bind="props"
                        source="quantity"
                        label="Quantity"
                    ></va-number-input>
                    <va-text-input
                        v-show="false"
                        v-bind="props"
                        source="id"
                    ></va-text-input>
                </v-col>
            </v-row>
        </custom-array-input>
        <va-save-button></va-save-button>
    </va-form>
</template>

<script>
import CustomArrayInput from "@/components/inputs/CustomArrayInput";

export default {
    props: ["id", "item", "mwsConfigurationId"],
    components: {
        CustomArrayInput,
    },
    computed: {
        mwsConfigurationOptions() {
            return this.$store.getters.mwsConfigurationOptions;
        },
    },
    watch: {
        mwsConfigurationOptions: {
            immediate: true,
            handler(newVal = []) {
                if (!newVal || !newVal.length) {
                    return;
                }
                this.mwsConfigurationMapOptions = newVal.map((option) => {
                    return {
                        value: parseInt(option.value, 10),
                        text: option.text,
                    };
                });
            },
        },
        mwsConfigurationId: {
            immediate: true,
            handler(newVal) {
                if (!newVal) {
                    return;
                }
                this.model.mws_configuration_id = parseInt(newVal, 10);
            },
        },
    },
    data() {
        return {
            mwsConfigurationMapOptions: {},
            model: {
                entity_type: "OperationFee",
                // mws_configuration_id: String(
                //     this.mwsConfigurationId || this?.item?.mws_configuration_id
                // ),
            },
        };
    },
    methods: {
        hasRole(role) {
            return (this.model.roles || []).includes(role);
        },
    },
};
</script>
