<template>
    <base-material-card :icon="resource.icon" :title="title">
        <va-list
            :filters="filters"
            disable-export
            disable-create
            disable-global-search
            disable-create-redirect
        >
            <template v-slot:actions>
                <va-action-button
                    :loading="isRequesting"
                    label="Generate Report"
                    icon="mdi-sync"
                    text
                    @click="generateReport"
                ></va-action-button>
            </template>
            <va-data-table disable-select disable-actions :fields="fields">
            </va-data-table>
        </va-list>
    </base-material-card>
</template>

<script>
export default {
    props: ["resource", "title"],
    data() {
        return {
            isRequesting: false,
            filters: [
                {
                    source: "mws_configuration_id",
                    type: "select",
                    attributes: {
                        itemText: "name",
                        itemValue: "id",
                        multiple: true,
                        reference: "MwsConfiguration",
                    },
                    alwaysOn: true,
                },
                {
                    source: "created_at",
                    type: "date",
                    alwaysOn: true,
                },
            ],
            fields: [
                { source: "mws_configuration_id", sortable: true },
                "status",
                "revenue",
                "fba_fee",
                "product_cost",
                "refund",
                "advertising_cost",
                "other_cost",
                "net_profit",
                { source: "created_at", sortable: true },
            ],
        };
    },
    methods: {
        async generateReport() {
            this.isRequesting = true;
            try {
                await this.$store.dispatch("Report/create", {});
            } catch (e) {
                console.log(e);
            } finally {
                this.isRequesting = false;
                this.$store.dispatch("api/refresh", this.$route.meta.resource);
            }
        },
    },
};
</script>
