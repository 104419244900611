<template>
    <va-select-input
        :choices="configurations"
        :source="source"
        required
    ></va-select-input>
</template>

<script>
export default {
    name: "MwsConfigurationSelectInput",
    props: ["source"],
    async mounted() {
        const mwsConfigurations = await this.$store.dispatch(
            "MwsConfiguration/getList",
            {
                pagination: {
                    page: 1,
                    perPage: 100,
                },
            }
        );
        this.configurations = mwsConfigurations.data.map((item) => {
            return { text: item.name, value: item.id };
        });
    },
    data() {
        return {
            configurations: [],
        };
    },
};
</script>
