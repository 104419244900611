<template>
    <va-form :id="id" :item="item">
        <v-row justify="center">
            <v-col sm="6">
                <base-material-card>
                    <template v-slot:heading>
                        <div class="display-2">
                            {{ title }}
                        </div>
                    </template>
                    <v-card-text>
                        <va-text-input source="name" required></va-text-input>
                        <va-select-input
                            source="main_marketplace_id"
                            :choices="MarketplaceIds"
                            required
                        ></va-select-input>
                        <va-select-input
                            source="second_marketplace_id"
                            :choices="MarketplaceIds"
                            required
                        ></va-select-input>
                        <va-text-input
                            source="AWS_ACCESS_KEY_ID"
                        ></va-text-input>
                        <va-text-input
                            source="AWS_SECRET_ACCESS_KEY"
                            required
                        ></va-text-input>
                        <va-text-input
                            source="LWA_CLIENT_ID"
                            required
                        ></va-text-input>
                        <va-text-input
                            source="LWA_CLIENT_SECRET"
                            required
                        ></va-text-input>
                        <va-text-input
                            source="LWA_REFRESH_TOKEN"
                            required
                        ></va-text-input>
                        <va-text-input
                            source="SPAPI_AWS_REGION"
                            required
                        ></va-text-input>
                        <va-text-input source="SPAPI_ENDPOINT"></va-text-input>
                        <!--                        <va-boolean-input-->
                        <!--                            source="is_default"-->
                        <!--                        ></va-boolean-input>-->
                    </v-card-text>
                    <va-save-button></va-save-button>
                </base-material-card>
            </v-col>
        </v-row>
    </va-form>
</template>

<script>
import MarketplaceIds from "@/constants/MarketplaceIds";

export default {
    props: ["id", "title", "item"],
    data() {
        return {
            MarketplaceIds,
        };
    },
};
</script>
