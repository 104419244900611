// const types = [
//     "AdjustmentEvent",
//     "AdjustmentEventItem",
//     "CouponPaymentEvent",
//     "CouponPaymentEventCharge",
//     "CouponPaymentEventFee",
//     "ProductAdsPaymentEvent",
//     "RefundEventShipmentItemCharge",
//     "RefundEventShipmentItemFee",
//     "RefundEventShipmentItemTaxWithheldCharge",
//     "SellerDealPaymentEvent",
//     "SellerReviewEnrollmentPaymentEvent",
//     "SellerReviewEnrollmentPaymentEventCharge",
//     "SellerReviewEnrollmentPaymentEventFee",
//     "ServiceFeeEvent",
//     "ServiceFeeEventFee",
//     "ShipmentEventShipmentItemCharge",
//     "ShipmentEventShipmentItemFee",
//     "ShipmentEventShipmentItemTaxWithheldCharge",
// ];
const types = [
    "AdjustmentEvent",
    "ChargebackEvent",
    "CouponPaymentEvent",
    "PayWithAmazonEvent",
    "ProductAdsPaymentEvent",
    "RefundEvent",
    "SellerDealPaymentEvent",
    "SellerReviewEnrollmentPaymentEvent",
    "ServiceFeeEvent",
    "ShipmentEvent",
    "RetrochargeEvent",
    "OperationFee",
];
const FinancialEventEntityTypes = types.map((value) => ({
    value,
    text: value,
}));

export default FinancialEventEntityTypes;
