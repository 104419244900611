<template>
    <va-input v-bind="$props" class="va-array-input" v-if="input">
        <draggable
            :value="input"
            draggable=".item"
            handle=".handle"
            @input="update"
        >
            <div
                v-for="(item, i) in input"
                :key="item.id"
                class="item mb-1 pb-1"
            >
                <!--
          @slot Your repeatable group of inputs components.
          @binding {string} resource Name of resource.
          @binding {object} item Object item of array.
          @binding {string} parentSource Original array source.
          @binding {number} index Index of item inside array.
        -->
                <slot
                    :resource="resource"
                    :item="item"
                    :parent-source="source"
                    :index="i"
                    :remove="remove"
                ></slot>
            </div>
            <v-btn slot="footer" outlined color="green" @click.stop="add">
                <v-icon small>mdi-plus-circle-outline</v-icon>
                <span class="ml-2">{{ $t("va.actions.add") }}</span>
            </v-btn>
        </draggable>
    </va-input>
</template>

<script>
import ArrayInput from "vuetify-admin/src/components/ui/inputs/ArrayInput";

/**
 * Allow multiple embed inputs as array. See it as a repeater.
 * Support adding, removing, as well as moving via drag thanks to VueDraggable.
 */
export default {
    mixins: [ArrayInput],
    methods: {
        add() {
            this.input.push({
                id: Math.min(...this.input.map((o) => o.id), 0) - 1,
            });
            this.update(this.input);
        },
    },
};
</script>
<style>
.item {
    border-bottom: solid 1px rgb(128, 128, 128);
}
</style>
