<template>
    <va-show-layout>
        <va-show :item="item">
            <v-row justify="center">
                <v-col sm="6">
                    <base-material-card>
                        <template v-slot:heading>
                            <div class="display-2">
                                {{ title }}
                            </div>
                        </template>
                        <v-card-text>
                            <va-field source="AWS_ACCESS_KEY_ID"></va-field>
                            <va-field source="AWS_SECRET_ACCESS_KEY"></va-field>
                            <va-field source="LWA_CLIENT_ID"></va-field>
                            <va-field source="LWA_CLIENT_SECRET"></va-field>
                            <va-field source="LWA_REFRESH_TOKEN"></va-field>
                            <va-field source="SPAPI_AWS_REGION"></va-field>
                            <va-field source="SPAPI_ENDPOINT"></va-field>
                        </v-card-text>
                    </base-material-card>
                </v-col>
            </v-row>
        </va-show>
    </va-show-layout>
</template>

<script>
export default {
    props: ["title", "item"],
};
</script>
