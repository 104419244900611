<template>
    <base-material-card :icon="resource.icon" :title="title">
        <va-list :filters="filters" ref="list" disable-global-search>
            <va-data-table
                :fields="fields"
                disable-clone
                disable-delete
                disable-select
            >
            </va-data-table>
        </va-list>
    </base-material-card>
</template>

<script>

export default {
    props: ["resource", "title"],
    data() {
        return {
            filters: [],
            fields: [
                "name",
                "client_id",
                "region",
                { source: "is_activated", type: "boolean" },
                { source: "sandbox", type: "boolean" },
                { source: "created_at", type: "date", sortable: true },
            ],
        };
    },
};
</script>
