<template>
    <va-show :item="item">
        <va-field source="name"></va-field>
        <va-field source="email"></va-field>
        <va-field source="active" type="boolean"></va-field>
        <va-field source="roles" v-slot="{ value }">
            <v-chip-group>
                <v-chip v-for="(item, i) in value" :key="i">
                    <va-select-field
                        source="roles"
                        :item="roleMap[item]"
                    ></va-select-field>
                </v-chip>
            </v-chip-group>
        </va-field>

        <va-field source="mws_configuration_ids" v-slot="{ value }">
            <v-chip-group>
                <v-chip v-for="(item, i) in value" :key="i">
                    <va-select-field
                        source="roles"
                        :item="mwsConfigurationMap[item]"
                    ></va-select-field>
                </v-chip>
            </v-chip-group>
        </va-field>
    </va-show>
</template>

<script>
import Roles from "@/constants/UserRoles";

export default {
    props: ["item"],
    computed: {
        mwsConfigurationOptions() {
            return this.$store.getters.mwsConfigurationOptions;
        },
    },
    watch: {
        mwsConfigurationOptions: {
            immediate: true,
            handler(newVal = []) {
                if (!newVal || !newVal.length) {
                    return;
                }
                const mwsConfigurationMap = {};
                newVal.forEach((e) => {
                    mwsConfigurationMap[e.value] = e.text;
                });
                this.mwsConfigurationMap = mwsConfigurationMap;
            },
        },
    },
    beforeMount() {
        Roles.forEach((role) => {
            this.roleMap[role.value] = role.text;
        });
    },
    data() {
        return {
            roleMap: {},
            mwsConfigurationMap: {},
        };
    },
};
</script>
