<template>
    <base-material-card :icon="resource.icon" :title="title">
        <va-list :filters="filters" ref="list" disable-global-search>
            <va-data-table
                :fields="fields"
                disable-clone
                disable-delete
                disable-select
            >
            </va-data-table>
        </va-list>
    </base-material-card>
</template>

<script>
import MarketplaceIds from "@/constants/MarketplaceIds";

export default {
    props: ["resource", "title"],
    data() {
        return {
            filters: [],
            fields: [
                "name",
                "LWA_CLIENT_ID",
                // { source: "is_default", type: "boolean" },
                {
                    source: "main_marketplace_id",
                    type: "select",
                    attributes: { choices: MarketplaceIds },
                },
                {
                    source: "second_marketplace_id",
                    type: "select",
                    attributes: { choices: MarketplaceIds },
                },
                // "AWS_ACCESS_KEY_ID",
                // "AWS_SECRET_ACCESS_KEY",
                // "LWA_CLIENT_SECRET",
                // "LWA_REFRESH_TOKEN",
                // "SPAPI_AWS_REGION",
                // "SPAPI_ENDPOINT",
            ],
        };
    },
    async mounted() {
        this.$watch(
            () => {
                return this.$refs.list.listState.items;
            },
            (items) => {
                if (!items.length) {
                    return;
                }
                const mwsConfigurationOptions = items.map(
                    ({
                        id,
                        name,
                        second_marketplace_id,
                        main_marketplace_id,
                    }) => {
                        return {
                            text: name,
                            value: id,
                            main_marketplace_id,
                            second_marketplace_id,
                        };
                    }
                );
                this.$store.dispatch("setMwsConfigurationOptions", {
                    mwsConfigurationOptions: mwsConfigurationOptions,
                });
            }
        );
    },
};
</script>
