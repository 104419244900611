/* eslint-disable no-unused-vars */

export default (i18n, admin) => [
    {
        icon: "mdi-view-dashboard",
        text: i18n.t("menu.dashboard"),
        link: "/",
    },
    { divider: true },
    admin.getResourceLink("FinancialTransaction"),
    {
        ...admin.getResourceLink("OperationFee"),
        text: "Operation Fee",
    },
    // admin.getResourceLink("FinancialTransactionGroup"),

    // admin.getResourceLink("Marketplace"),
    // admin.getResourceLink("FinancialOrder"),
    // admin.getResourceLink("FinancialEvent"),
    // admin.getResourceLink("FinancialEventGroup"),
    // admin.getResourceLink("AffordabilityExpenseEvent"),
    // admin.getResourceLink("FinancialOrderDirectPayment"),
    // admin.getResourceLink("NetworkComminglingTransactionEvent"),
    // admin.getResourceLink("AffordabilityExpenseReversalEvent"),
    // admin.getResourceLink("ChargebackEvent"),
    // admin.getResourceLink("SAFETReimbursementEvent"),
    // admin.getResourceLink("GuaranteeClaimEvent"),
    // admin.getResourceLink("ImagingServicesFeeEventFee"),
    // admin.getResourceLink("RefundEventShipmentFee"),
    // admin.getResourceLink("ServiceFeeEvent"),
    // admin.getResourceLink("ServiceFeeEventFee"),
    // admin.getResourceLink("DebtRecoveryEventChargeInstrument"),
    // admin.getResourceLink("DebtRecoveryEventItem"),
    // admin.getResourceLink("RentalTransactionEvent"),
    // admin.getResourceLink("PayWithAmazonEventFee"),
    // admin.getResourceLink("ProductAdsPaymentEvent"),
    // admin.getResourceLink("PayWithAmazonEventCharge"),
    // admin.getResourceLink("ImagingServicesFeeEvent"),
    // admin.getResourceLink("CouponPaymentEventCharge"),
    // admin.getResourceLink("DebtRecoveryEvent"),
    // admin.getResourceLink("ShipmentEvent"),
    // admin.getResourceLink("AdjustmentEvent"),
    // admin.getResourceLink("AdjustmentEventItem"),
    // admin.getResourceLink("RefundEventShipmentItem"),
    // admin.getResourceLink("SolutionProviderCreditEvent"),
    // admin.getResourceLink("RefundEventOrderFee"),
    // admin.getResourceLink("CouponPaymentEvent"),
    // admin.getResourceLink("LoanServicingEvent"),
    // admin.getResourceLink("SellerDealPaymentEvent"),
    // admin.getResourceLink("RefundEvent"),
    // admin.getResourceLink("FBALiquidationEvent"),
    // admin.getResourceLink("SellerReviewEnrollmentPaymentEvent"),
    // admin.getResourceLink("PayWithAmazonEvent"),
    // admin.getResourceLink("RefundEventShipmentItemFee"),
    // admin.getResourceLink("ShipmentEventShipmentItemFee"),
    // admin.getResourceLink("RefundEventShipmentItemTaxWithheldCharge"),
    // admin.getResourceLink("ShipmentEventShipmentItemTaxWithheldCharge"),
    // admin.getResourceLink("CouponPaymentEventFee"),
    // admin.getResourceLink("SAFETReimbursementEventItemCharge"),
    // admin.getResourceLink("ShipmentEventShipmentItem"),
    // admin.getResourceLink("ShipmentEventShipmentItemCharge"),
    // admin.getResourceLink("RentalTransactionEventCharge"),
    // admin.getResourceLink("RefundEventOrderCharge"),
    // admin.getResourceLink("SellerReviewEnrollmentPaymentEventCharge"),
    // admin.getResourceLink("RentalTransactionEventTaxWithheldCharge"),
    // admin.getResourceLink("ShipmentEventOrderCharge"),
    // admin.getResourceLink("RentalTransactionEventFee"),
    // admin.getResourceLink("RetrochargeEventTaxWithheldCharge"),
    // admin.getResourceLink("SellerReviewEnrollmentPaymentEventFee"),
    // admin.getResourceLink("ShipmentEventShipmentFee"),
    // admin.getResourceLink("RetrochargeEvent"),
    // admin.getResourceLink("ShipmentEventOrderFee"),
    // admin.getResourceLink("RefundEventShipmentItemCharge"),
    // admin.getResourceLink("CatalogItem"),
    {
        ...admin.getResourceLink("SaleInventory"),
        text: "Sale & Inventory",
        // children: [
        //     {
        //         ...admin.getResourceLink("SaleInventory"),
        //         text: "1st Sale & Inventory",
        //     },
        //     {
        //         ...admin.getResourceLink("SecondSaleInventory"),
        //         text: "2nd Sale & Inventory",
        //     },
        // ],
    },
    admin.getResourceLink("Order"),
    {
        ...admin.getResourceLink("AsinProfit"),
        text: "Profit by ASIN",
    },
    // {
    //     ...admin.getResourceLink("SaleInventoryNewAsin"),
    //     text: "New Products",
    // },
    {
        ...admin.getResourceLink("CogCatalogItem"),
        text: "Product Management",
        // children: [
        //     {
        //         ...admin.getResourceLink("CogCatalogItem"),
        //         text: "1st Product Management",
        //     },
        //     {
        //         ...admin.getResourceLink("SecondCogCatalogItem"),
        //         text: "2nd Product Management",
        //     },
        // ],
    },
    // admin.getResourceLink("CogCatalogItemTransaction"),
    // {
    //     ...admin.getResourceLink("UsWarehouseInventory"),
    //     text: "US Warehouse Management",
    // },
    // {
    //     ...admin.getResourceLink("OrderRequest"),
    //     text: "Request",
    // },
    {
        ...admin.getResourceLink("Supplier"),
        text: "Supplier Management",
    },
    {
        ...admin.getResourceLink("FbaInventory"),
        text: "FBA Inventory",
        // children: [
        //     {
        //         ...admin.getResourceLink("FbaInventory"),
        //         text: "1st FBA Inventory",
        //     },
        //     {
        //         ...admin.getResourceLink("SecondFbaInventory"),
        //         text: "2nd FBA Inventory",
        //     },
        // ],
    },
    {
        ...admin.getResourceLink("FbaInboundShipment"),
        text: "FBA Inbound Shipment",
    },
    admin.getResourceLink("MwsConfiguration"),
    admin.getResourceLink("MwsSynchronization"),
    admin.getResourceLink("AdsConfiguration"),
    admin.getResourceLink("AdsListReport"),
    // admin.getResourceLink("Report"),
    // admin.getResourceLink("OrderRequestItem"),
    admin.getResourceLink("users"),
];
