<template>
    <v-progress-linear
        v-if="$store.state.api.loading && !isGettingStaffPerf"
        indeterminate
    ></v-progress-linear>
    <div v-else>
        <div class="text-right" v-if="reportItems.length">
            <date-range-picker
                ref="picker"
                :opens="'left'"
                :timePicker="false"
                :showWeekNumbers="true"
                :showDropdowns="true"
                :ranges="dateRanges"
                v-model="dateRange"
                :locale-data="{
                    firstDay: 1,
                }"
                @update="(val) => generate(val)"
            >
                <template v-slot:input="picker" style="min-width: 350px">
                    <va-action-button
                        v-if="!picker.startDate || !picker.startDate"
                        :loading="isGenerating"
                        label="Date Range"
                        icon="mdi-filter"
                        text
                    ></va-action-button>
                    <template v-else>
                        {{ picker.startDate | moment("timezone", tz, "L") }}
                        -
                        {{ picker.endDate | moment("timezone", tz, "L") }}
                    </template>
                </template>
            </date-range-picker>
            <va-action-button
                v-if="dateRange.startDate"
                :loading="isGenerating"
                @click="clearFilter"
                label="Clear"
                icon="mdi-clear"
                text
                color="error"
            ></va-action-button>
            <v-dialog v-model="staffPerfDialog">
                <v-card>
                    <v-list>
                        <v-list-item>
                            <v-list-item-content>
                                <v-card-text v-if="isGettingStaffPerf">
                                    Loading...
                                    <v-progress-linear
                                        indeterminate
                                    ></v-progress-linear>
                                </v-card-text>
                                <v-data-table
                                    v-else
                                    :headers="staffPerfHeaders"
                                    :items="staffPerfReport"
                                    disable-pagination
                                    disable-filtering
                                    hide-default-footer
                                    class="staff-perf-report-data-table"
                                ></v-data-table>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-dialog>
            <v-dialog v-model="newAsinDialog">
                <v-card>
                    <v-list>
                        <v-list-item>
                            <v-list-item-content>
                                <v-card-text v-if="isGettingNewAsin">
                                    Loading...
                                    <v-progress-linear
                                        indeterminate
                                    ></v-progress-linear>
                                </v-card-text>
                                <v-data-table
                                    v-else
                                    :headers="newAsinHeaders"
                                    :items="newAsinReport"
                                    disable-pagination
                                    disable-filtering
                                    hide-default-footer
                                ></v-data-table>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-dialog>
            <v-dialog v-model="customReportDialog" width="1000">
                <v-card>
                    <v-list>
                        <v-list-item>
                            <v-list-item-content>
                                <v-data-table
                                    :headers="customReportHeaders"
                                    :items="customReport"
                                    disable-pagination
                                    disable-filtering
                                    hide-default-footer
                                ></v-data-table>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-dialog>
            <v-dialog v-model="detailReportsDialog" width="500">
                <v-card>
                    <v-list>
                        <v-list-item>
                            <v-list-item-content>
                                <v-data-table
                                    :headers="detailsReportHeaders"
                                    :items="detailsReport"
                                    disable-pagination
                                    disable-filtering
                                    hide-default-footer
                                ></v-data-table>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-dialog>
        </div>
        <v-data-table
            v-if="reportItems.length"
            :headers="headers"
            :items="reportItems"
            disable-pagination
            disable-filtering
            hide-default-footer
            class="report-main-table"
        >
            <template v-slot:item.inventory_data="{ value }">
                <div class="inventory_data-table-wrapper">
                    <v-data-table
                        :headers="customReportAllHeaders"
                        :items="value"
                        disable-pagination
                        disable-filtering
                        hide-default-footer
                        hide-default-header
                    >
                        <template v-slot:item.value="{ value, item }">
                            <div
                                class="w-100 flex flex-column"
                                v-if="item.field === 'buttons'"
                            >
                                <v-btn
                                    block
                                    class="ma-2"
                                    @click="
                                        openCustomReport(
                                            'inventory',
                                            'staff',
                                            item,
                                            inventoryKeys
                                        )
                                    "
                                >
                                    {{ "By Staff" }}
                                </v-btn>
                                <v-btn
                                    block
                                    class="ma-2"
                                    @click="
                                        openCustomReport(
                                            'inventory',
                                            'supplier',
                                            item,
                                            inventoryKeys
                                        )
                                    "
                                >
                                    {{ "By Supplier" }}
                                </v-btn>
                                <v-btn
                                    block
                                    class="ma-2"
                                    @click="
                                        openCustomReport(
                                            'inventory',
                                            'sale_label',
                                            item,
                                            inventoryKeys
                                        )
                                    "
                                >
                                    {{ "By Sale Label" }}
                                </v-btn>
                                <v-btn
                                    block
                                    class="ma-2"
                                    @click="
                                        openCustomReport(
                                            'inventory',
                                            'asin_age',
                                            item,
                                            inventoryKeys
                                        )
                                    "
                                >
                                    {{ "By ASIN Age" }}
                                </v-btn>
                            </div>
                            <span v-else>{{ value }}</span>
                        </template>
                    </v-data-table>
                </div>
            </template>
            <template v-slot:item.price_and_fee_data="{ value }">
                <div class="price_and_fee_data-table-wrapper">
                    <v-data-table
                        :headers="customReportAllHeaders"
                        :items="value"
                        disable-pagination
                        disable-filtering
                        hide-default-footer
                        hide-default-header
                    >
                        <template v-slot:item.value="{ value, item }">
                            <div
                                class="w-100 flex flex-column"
                                v-if="item.field === 'buttons'"
                            >
                                <v-btn
                                    block
                                    class="ma-2"
                                    @click="
                                        openCustomReport(
                                            'price_and_fee',
                                            'staff',
                                            item,
                                            priceAndFeeKeys
                                        )
                                    "
                                >
                                    {{ "By Staff" }}
                                </v-btn>
                                <v-btn
                                    block
                                    class="ma-2"
                                    @click="
                                        openCustomReport(
                                            'price_and_fee',
                                            'supplier',
                                            item,
                                            priceAndFeeKeys
                                        )
                                    "
                                >
                                    {{ "By Supplier" }}
                                </v-btn>
                                <v-btn
                                    block
                                    class="ma-2"
                                    @click="
                                        openCustomReport(
                                            'price_and_fee',
                                            'sale_label',
                                            item,
                                            priceAndFeeKeys
                                        )
                                    "
                                >
                                    {{ "By Sale Label" }}
                                </v-btn>
                                <v-btn
                                    block
                                    class="ma-2"
                                    @click="
                                        openCustomReport(
                                            'price_and_fee',
                                            'asin_age',
                                            item,
                                            priceAndFeeKeys
                                        )
                                    "
                                >
                                    {{ "By ASIN Age" }}
                                </v-btn>
                            </div>
                            <span v-else>{{ value }}</span>
                        </template>
                    </v-data-table>
                </div>
            </template>
            <template v-slot:item.custom_data="{ value }">
                <v-data-table
                    :headers="subHeaders"
                    :items="value"
                    disable-pagination
                    disable-filtering
                    hide-default-footer
                    hide-default-header
                >
                    <template
                        v-slot:item.additional_information="{ value, item }"
                    >
                        <v-btn
                            v-if="item.field === 'buttons'"
                            @click="openNewAsinReport('custom', item)"
                        >
                            {{ "New ASIN" }}
                        </v-btn>
                        <v-btn
                            v-if="item.field === 'buttons'"
                            @click="openStaffPerfReport('custom', item)"
                        >
                            {{ "Staff" }}
                        </v-btn>
                        <span v-else>{{ value }}</span>
                    </template>
                </v-data-table>
            </template>
            <template v-slot:item.yesterday_data="{ value }">
                <v-data-table
                    :headers="subHeaders"
                    :items="value"
                    disable-pagination
                    disable-filtering
                    hide-default-footer
                    hide-default-header
                >
                    <template v-slot:item.value="{ value, item }">
                        <v-btn
                            v-if="item.field === 'buttons'"
                            @click="openNewAsinReport('yesterday', item)"
                        >
                            {{ "New ASIN" }}
                        </v-btn>
                        <a
                            v-else-if="item.details"
                            @click="openDetailsReport(item)"
                        >
                            {{ value }}
                        </a>
                        <span v-else>{{ value }}</span>
                    </template>
                    <template
                        v-slot:item.additional_information="{ value, item }"
                    >
                        <v-btn
                            v-if="item.field === 'buttons'"
                            @click="openStaffPerfReport('yesterday', item)"
                        >
                            {{ "Staff" }}
                        </v-btn>
                        <span v-else>{{ value }}</span>
                    </template>
                </v-data-table>
            </template>
            <template v-slot:item.this_month_data="{ value }">
                <v-data-table
                    :headers="subHeaders"
                    :items="value"
                    disable-pagination
                    disable-filtering
                    hide-default-footer
                    hide-default-header
                >
                    <template v-slot:item.value="{ value, item }">
                        <v-btn
                            v-if="item.field === 'buttons'"
                            @click="openNewAsinReport('this_month', item)"
                        >
                            {{ "New ASIN" }}
                        </v-btn>
                        <a
                            v-else-if="item.details"
                            @click="openDetailsReport(item)"
                        >
                            {{ value }}
                        </a>
                        <span v-else>{{ value }}</span>
                    </template>
                    <template
                        v-slot:item.additional_information="{ value, item }"
                    >
                        <v-btn
                            v-if="item.field === 'buttons'"
                            @click="openStaffPerfReport('this_month', item)"
                        >
                            {{ "Staff" }}
                        </v-btn>
                        <span v-else>{{ value }}</span>
                    </template>
                </v-data-table>
            </template>
            <template v-slot:item.last_month_data="{ value }">
                <v-data-table
                    :headers="subHeaders"
                    :items="value"
                    disable-pagination
                    disable-filtering
                    hide-default-footer
                    hide-default-header
                >
                    <template v-slot:item.value="{ value, item }">
                        <v-btn
                            v-if="item.field === 'buttons'"
                            @click="openNewAsinReport('last_month', item)"
                        >
                            {{ "New ASIN" }}
                        </v-btn>
                        <a
                            v-else-if="item.details"
                            @click="openDetailsReport(item)"
                        >
                            {{ value }}
                        </a>
                        <span v-else>{{ value }}</span>
                    </template>
                    <template
                        v-slot:item.additional_information="{ value, item }"
                    >
                        <v-btn
                            v-if="item.field === 'buttons'"
                            @click="openStaffPerfReport('last_month', item)"
                        >
                            {{ "Staff" }}
                        </v-btn>
                        <span v-else>{{ value }}</span>
                    </template>
                </v-data-table>
            </template>
            <template v-slot:item.this_year_data="{ value }">
                <v-data-table
                    :headers="subHeaders"
                    :items="value"
                    disable-pagination
                    disable-filtering
                    hide-default-footer
                    hide-default-header
                >
                    <template v-slot:item.value="{ value, item }">
                        <v-btn
                            v-if="item.field === 'buttons'"
                            @click="openNewAsinReport('this_year', item)"
                        >
                            {{ "New ASIN" }}
                        </v-btn>
                        <a
                            v-else-if="item.details"
                            @click="openDetailsReport(item)"
                        >
                            {{ value }}
                        </a>
                        <span v-else>{{ value }}</span>
                    </template>
                    <template
                        v-slot:item.additional_information="{ value, item }"
                    >
                        <v-btn
                            v-if="item.field === 'buttons'"
                            @click="openStaffPerfReport('this_year', item)"
                        >
                            {{ "Staff" }}
                        </v-btn>
                        <span v-else>{{ value }}</span>
                    </template>
                </v-data-table>
            </template>
        </v-data-table>
        <p v-else>{{ "This function only for administrators" }}</p>
    </div>
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";
import { tz, sourceTz } from "@/constants/Time";
import Marketplaces, {
    vTableHeaders,
} from "@/constants/StaffPerfMarketplaceIds";
import dateRanges from "@/constants/DateRanges";
import moment from "moment-timezone";
import get from "lodash/get";

const staffPerfHeaders = [
    {
        text: "Staff",
        value: "staff",
        sortable: true,
    },
    ...vTableHeaders,
];
const newAsinHeaders = [
    {
        text: "Staff",
        value: "staff",
        sortable: true,
    },
    {
        text: "New ASIN",
        value: "new_asin_count",
        sortable: true,
    },
];
const defaultHeaders = [
    {
        text: "Inventory",
        value: "inventory_data",
        align: "start",
        sortable: false,
    },
    {
        text: "Price and Fee",
        value: "price_and_fee_data",
        align: "start",
        sortable: false,
    },
    {
        text: "Yesterday",
        value: "yesterday_data",
        align: "start",
        sortable: false,
    },
    {
        text: "This month",
        value: "this_month_data",
        align: "start",
        sortable: false,
    },
    {
        text: "Last month",
        value: "last_month_data",
        align: "start",
        sortable: false,
    },
    {
        text: "This year",
        value: "this_year_data",
        align: "start",
        sortable: false,
    },
];

const customHeaders = [
    {
        text: "",
        value: "custom_data",
        align: "start",
        sortable: false,
    },
];

const inventoryKeys = [
    { field: "asin", label: "Total ASIN" },
    { field: "quantity", label: "Total Quantity" },
    { field: "cogs", label: "Total COGs" },
];

const priceAndFeeKeys = [
    { field: "asin", label: "Total ASIN" },
    { field: "avg_listing_price", label: "Avg Listing Price" },
    { field: "avg_fba_fee", label: "Avg FBA Fee" },
    { field: "avg_referral_fee", label: "Avg Referral Fee" },
    { field: "avg_cogs", label: "Avg Cogs" },
    { field: "avg_gp", label: "Avg GP" },
];

const detailsReportKeys = [
    { field: "field", label: "Field" },
    { field: "value", label: "Value" },
];

export default {
    components: {
        DateRangePicker,
    },
    async mounted() {
        try {
            await this.getStaffs();
            await this.getReport();
        } catch (e) {
            console.log(e);
        }
    },
    data() {
        return {
            title: "Report",
            customHeaders: [...customHeaders],
            headers: [...defaultHeaders],
            subHeaders: [
                {
                    text: "Key",
                    value: "key",
                    align: "start",
                    sortable: false,
                },
                {
                    text: "Value",
                    value: "value",
                    align: "end",
                    sortable: false,
                },
                {
                    text: "",
                    value: "additional_information",
                    align: "start",
                    sortable: false,
                },
            ],
            staffPerfHeaders,
            newAsinHeaders,
            inventoryHeaders: [
                {
                    text: "Total ASIN",
                    value: "asin",
                    align: "end",
                    sortable: false,
                },
                {
                    text: "Total Quantity",
                    value: "quantity",
                    align: "end",
                    sortable: false,
                },
                {
                    text: "Total COGs",
                    value: "cogs",
                    align: "end",
                    sortable: false,
                },
            ],
            customReportAllHeaders: [
                {
                    text: "Key",
                    value: "key",
                    align: "start",
                    sortable: false,
                },
                {
                    text: "Value",
                    value: "value",
                    align: "end",
                    sortable: false,
                },
            ],
            customReportHeaders: [],
            detailsReportHeaders: [],
            isGenerating: false,
            isGettingStaffPerf: false,
            staffPerfDialog: false,
            isGettingNewAsin: false,
            newAsinDialog: false,
            customReportDialog: false,
            detailReportsDialog: false,
            staffs: [],
            reportItems: [],
            appMap: [],
            staffPerfReport: {},
            newAsinReport: {},
            customReport: {},
            detailsReport: {},
            tz: tz,
            sourceTz: sourceTz,
            dateFormat: this.$admin.options.dateFormat,
            dateRange: {},
            dateRanges,
            dateRangeReport: false,
            cachedDateRangeReport: {},
            inventoryKeys,
            priceAndFeeKeys,
            detailsReportKeys,
        };
    },
    methods: {
        clearFilter() {
            this.dateRange = {};
            this.getReport();
        },
        async generate(value) {
            this.isGenerating = true;

            const start = moment(value.startDate);
            const end = moment(value.endDate);

            const filter = {
                start: start.clone().tz(tz).format("YYYY-MM-DD"),
                end: end.clone().tz(tz).format("YYYY-MM-DD"),
            };

            if (this.cachedDateRangeReport[`${filter.start}_${filter.end}`]) {
                this.reportItems =
                    this.cachedDateRangeReport[`${filter.start}_${filter.end}`];
                this.isGenerating = false;
                return;
            }

            try {
                const reportItems = await this.getReport(filter);
                this.reportItems = reportItems;
                this.cachedDateRangeReport[`${filter.start}_${filter.end}`] = [
                    ...reportItems,
                ];
            } catch (e) {
                console.log(e);
            } finally {
                this.isGenerating = false;
            }
        },
        async getReport(filter = {}) {
            const reportItemsResponse = await this.$store.dispatch(
                "Report/getList",
                {
                    filter,
                    pagination: {
                        page: 1,
                        perPage: 900000000,
                    },
                    sort: [{ by: "mws_configuration_id" }],
                }
            );

            if (!reportItemsResponse.data.length) {
                return [];
            }

            const timeKeys = filter.start
                ? ["custom"]
                : [
                      "inventory",
                      "price_and_fee",
                      "yesterday",
                      "this_month",
                      "last_month",
                      "this_year",
                  ];
            const valueKeys = [
                { field: "revenue" },
                { field: "fba_fee", label: "FBA fee" },
                { field: "commission", label: "Commission" },
                { field: "promotion_value", label: "Promotion value" },
                { field: "product_cost" },
                { field: "refund" },
                { field: "advertising_cost" },
                {
                    field: "other_earnings_cost",
                    label: "Total Other Earnings",
                },
                { field: "other_cost", label: "Total Other Cost" },
                { field: "net_profit", label: "NET Profit" },
            ];

            // const sumItem = {};
            let reportItems = reportItemsResponse.data
                .filter((report) => report.status === "generated")
                .map((item) => {
                    if (item.mwsConfiguration) {
                        this.appMap[item.mwsConfiguration.name] =
                            item.mwsConfiguration.id;
                    }

                    timeKeys.forEach((key) => {
                        item[`${key}_data`] = [
                            {
                                key: item.mwsConfiguration
                                    ? item.mwsConfiguration.name
                                    : "All",
                                value: "",
                                field: "buttons",
                                additional_information: "",
                            },
                        ];

                        if (key === "inventory") {
                            inventoryKeys.forEach((valueKey) => {
                                const fieldData = get(item, [key, "all"], {});
                                const info = {
                                    key:
                                        valueKey.label ||
                                        "Total " +
                                            valueKey.field.replaceAll("_", " "),
                                    value: fieldData[valueKey.field],
                                    field: valueKey.field,
                                    additional_information: "",
                                };

                                item[`${key}_data`].push(info);
                            });
                            return;
                        }

                        if (key === "price_and_fee") {
                            priceAndFeeKeys.forEach((valueKey) => {
                                const fieldData = get(item, [key, "all"], {});
                                const info = {
                                    key:
                                        valueKey.label ||
                                        "Total " +
                                            valueKey.field.replaceAll("_", " "),
                                    value: fieldData[valueKey.field],
                                    field: valueKey.field,
                                    additional_information: "",
                                };

                                item[`${key}_data`].push(info);
                            });
                            return;
                        }

                        valueKeys.forEach((valueKey) => {
                            const fieldData = item[valueKey.field]
                                ? item[valueKey.field][key]
                                : {};
                            const info = {
                                key:
                                    valueKey.label ||
                                    "Total " +
                                        valueKey.field.replaceAll("_", " "),
                                value: fieldData?.value.toFixed(2),
                                details: fieldData?.details,
                                field: valueKey.field,
                                additional_information: "",
                            };

                            if (fieldData.order_quantity) {
                                if (fieldData.percentage) {
                                    info.additional_information += ` ${fieldData.percentage}%, `;
                                }

                                info.additional_information += `${fieldData.order_quantity} orders, ${fieldData.item_quantity} items`;
                            } else {
                                if (fieldData.percentage) {
                                    info.additional_information += ` ${fieldData.percentage}%`;
                                }
                            }

                            item[`${key}_data`].push(info);
                        });
                    });

                    return item;
                });

            this.reportItems = reportItems;

            if (!reportItems.length) {
                return [];
            }

            if (filter.start) {
                this.headers = [...customHeaders];
                this.subHeaders[this.subHeaders.length - 1].align = "end";
                return reportItems;
            }

            this.headers = [...defaultHeaders];
            this.subHeaders[this.subHeaders.length - 1].align = "start";
            this.headers[0].text = `${defaultHeaders[0].text} (${reportItems[0].dates.yesterday[0]})`;
        },
        async getStaffs() {
            const response = await this.$store.dispatch("users/getList", {
                data: {},
            });
            this.staffs = response.data;
        },
        async openStaffPerfReport(type, item) {
            this.staffPerfDialog = true;
            this.selected = item;
            const mws_configuration_id = this.appMap[item.key] || null;
            this.isGettingStaffPerf = true;
            await this.applyStaffPerfReportFilter(type, mws_configuration_id);
            this.isGettingStaffPerf = false;
        },
        async applyStaffPerfReportFilter(type, mws_configuration_id) {
            const { staff_perf } = this.reportItems.find(
                (item) => item.mws_configuration_id === mws_configuration_id
            );
            const staffPerfReport = { ...staff_perf[type] };
            const staffPerfReportTableData = [];
            for (const staffId in staffPerfReport) {
                const convertedStaffPerfReport = {
                    staff: this.staffs.find(
                        (item) => Number(item.id) === Number(staffId)
                    )?.name,
                };
                Marketplaces.forEach((item) => {
                    const valueAtMarketPlace =
                        staffPerfReport[staffId][item.value];
                    convertedStaffPerfReport[item.value] = `${
                        valueAtMarketPlace?.normal_price_item_quantity
                            ? `${valueAtMarketPlace.normal_price_item_quantity} normal items, `
                            : ""
                    }${
                        valueAtMarketPlace?.low_price_item_quantity
                            ? `${valueAtMarketPlace.low_price_item_quantity} low-price items`
                            : ""
                    }`;
                });
                staffPerfReportTableData.push(convertedStaffPerfReport);
            }
            this.staffPerfReport = staffPerfReportTableData;
        },
        async openNewAsinReport(type, item) {
            this.newAsinDialog = true;
            this.selected = item;
            const mws_configuration_id = this.appMap[item.key] || null;
            this.isGettingNewAsin = true;
            await this.applyNewAsinReportFilter(type, mws_configuration_id);
            this.isGettingNewAsin = false;
        },
        async openCustomReport(type, groupKey, item, columns) {
            this.customReportDialog = true;
            this.selected = item;
            const mws_configuration_id = this.appMap[item.key] || null;

            const reportData = this.reportItems.find(
                (item) => item.mws_configuration_id === mws_configuration_id
            )[type][groupKey];

            const customReportTableData = [];
            for (const groupBy in reportData) {
                const converted = {
                    groupBy,
                };

                columns.forEach((column) => {
                    converted[column.field] = reportData[groupBy][column.field];
                });
                customReportTableData.push(converted);
            }
            this.customReport = customReportTableData;
            this.customReportHeaders = [
                {
                    text: " ",
                    value: "groupBy",
                    align: "start",
                    sortable: true,
                },
                ...columns.map((column) => {
                    return {
                        text: column.label,
                        value: column.field,
                        align: "end",
                        sortable: true,
                    };
                }),
            ];
        },
        async openDetailsReport(item) {
            this.detailReportsDialog = true;
            this.selected = item;
            this.detailsReport = Object.keys(item.details)
                .sort()
                .map((field) => ({
                    field,
                    value: item.details[field],
                }));
            let aligns = ["start", "end"];
            this.detailsReportHeaders = detailsReportKeys.map((column) => {
                return {
                    text: column.label,
                    value: column.field,
                    align: aligns.shift(),
                    sortable: true,
                };
            });
        },
        async applyNewAsinReportFilter(type, mws_configuration_id) {
            const { new_asin } = this.reportItems.find(
                (item) => item.mws_configuration_id === mws_configuration_id
            );
            const newAsinReport = { ...new_asin[type] };
            const newAsinReportTableData = [];
            for (const staffId in newAsinReport) {
                const convertedNewAsinReport = {
                    staff: this.staffs.find(
                        (item) => Number(item.id) === Number(staffId)
                    )?.name,
                    new_asin_count: newAsinReport[staffId],
                };
                newAsinReportTableData.push(convertedNewAsinReport);
            }
            this.newAsinReport = newAsinReportTableData;
        },
    },
};
</script>
<style lang="sass">
.report-main-table
  table
    th
      span
        font-weight: bold
        font-size: 17px

    table
      margin-bottom: 30px
      border: solid 1px #888888

      tbody
        tr
          td:last-child
            font-size: 12px !important

        tr:first-child
          background-color: #888888

          td
            font-weight: bold

        tr:last-child
          background-color: #80cbc4

.staff-perf-report-data-table
  tbody
    tr
      td:last-child
        color: red

  thead
    tr
      th:last-child
        color: red !important

.inventory_data-table-wrapper, .price_and_fee_data-table-wrapper
  display: flex
  align-items: start
  height: 100%
  width: 100%

  .v-data-table
    width: 100%

  table
    tbody
      tr:last-child
        background-color: transparent !important
</style>
