const types = [
    "WORKING",
    "SHIPPED",
    "RECEIVING",
    "CANCELLED",
    "DELETED",
    "CLOSED",
    "ERROR",
    "IN_TRANSIT",
    "DELIVERED",
    "CHECKED_IN",
];
const ShipmentStatuses = types.map((value) => ({
    value,
    text: value,
}));

export default ShipmentStatuses;
