const AdsListReportStatuses = [
    {
        value: 0,
        text: "NEW",
    },
    {
        value: 1,
        text: "PROCESSING",
    },
    {
        value: 2,
        text: "SUCCESS",
    },
    {
        value: 3,
        text: "ERROR",
    },
];

export const vTableHeaders = AdsListReportStatuses.map((item) => ({
    text: item.text,
    value: item.value,
    sortable: true,
}));

export default AdsListReportStatuses;
