var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('va-aside-layout',{attrs:{"title":_vm.asideTitle}},[(_vm.show)?_c('users-show',{attrs:{"item":_vm.item}}):_c('users-form',{attrs:{"id":_vm.id,"item":_vm.item},on:{"saved":_vm.onSaved}})],1),_c('base-material-card',{attrs:{"icon":_vm.resource.icon,"title":_vm.title}},[_c('va-list',{ref:"list",attrs:{"disable-create-redirect":"","filters":_vm.filters},on:{"action":_vm.onAction},scopedSlots:_vm._u([{key:"bulk.actions",fn:function(){return [_c('va-bulk-action-button',{attrs:{"label":_vm.$t('users.enable'),"icon":"mdi-publish","color":"success","action":{ active: true },"text":""}}),_c('va-bulk-action-button',{attrs:{"label":_vm.$t('users.disable'),"icon":"mdi-download","color":"orange","action":{ active: false },"text":""}})]},proxy:true}],null,true)},[_c('va-data-table',{attrs:{"fields":_vm.fields,"disable-create-redirect":"","disable-show-redirect":"","disable-edit-redirect":"","disable-clone":""},on:{"item-action":_vm.onAction},scopedSlots:_vm._u([{key:"field.roles",fn:function(ref){
var value = ref.value;
return [_c('v-chip-group',{attrs:{"column":""}},_vm._l((value),function(item,i){return _c('v-chip',{key:i,attrs:{"small":""}},[_c('va-select-field',{attrs:{"source":"roles","item":_vm.roleMap[item]}})],1)}),1)]}},{key:"field.mws_configuration_ids",fn:function(ref){
var value = ref.value;
return [_c('v-chip-group',{attrs:{"column":""}},_vm._l((value),function(item,i){return _c('v-chip',{key:i,attrs:{"small":""}},[_c('va-select-field',{attrs:{"source":"roles","item":_vm.mwsConfigurationMap[item]}})],1)}),1)]}},{key:"item.actions",fn:function(ref){
var resource = ref.resource;
var item = ref.item;
return [_c('impersonate-button',{attrs:{"resource":resource,"item":item,"icon":""}})]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }