<template>
    <va-edit-layout>
        <ShipmentEventOrderCharge-form
            :id="id"
            :title="title"
            :item="item"
        ></ShipmentEventOrderCharge-form>
    </va-edit-layout>
</template>

<script>
export default {
    props: ["id", "title", "item"],
};
</script>
