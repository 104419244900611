<template>
    <OrderRequest-form
        :title="title"
        :item="item"
        :mwsConfigurationId="mwsConfigurationId"
        :cogCatalogItemMap="cogCatalogItemMap"
        :supplierMap="supplierMap"
        @saved="$emit('saved')"
        @discarded="$emit('discarded')"
        @deleted="$emit('deleted')"
    ></OrderRequest-form>
</template>

<script>
export default {
    props: ["title", "item", "mwsConfigurationId", "cogCatalogItemMap", "supplierMap"],
};
</script>
