<template>
    <base-material-card :icon="resource.icon" :title="title">
        <v-tabs v-model="selectedTab">
            <v-tab
                class="mr-3"
                v-for="tab in mwsConfigurationOptions"
                :key="tab.value"
                @click="onClickTab(tab.value)"
            >
                <v-icon class="mr-2">mdi-amazon</v-icon>
                {{ tab.text }}
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="selectedTab" class="transparent"> </v-tabs-items>
        <va-list
            :filters="filters"
            ref="list"
            class="fba-inventory-group-list"
            disable-create
            disable-export
            :filter.sync="filter"
        >
            <va-data-table
                ref="table"
                :fields="fields"
                class="main-table"
                disable-actions
                disable-select
                show-expand
                single-expand
            >
                <template v-slot:expanded-item="{ item }">
                    <v-data-table
                        v-if="item.children.length"
                        hide-default-header
                        hide-default-footer
                        class="item-headers"
                        :headers="item_headers"
                        :items="item.children"
                        show-expand
                    >
                        <template v-slot:expanded-item="{ item }">
                            <v-data-table
                                v-if="item.children.length"
                                hide-default-header
                                hide-default-footer
                                class="item-detail-headers"
                                :headers="item_detail_headers"
                                :items="item.children"
                            ></v-data-table>
                        </template>
                    </v-data-table>
                </template>
            </va-data-table>
        </va-list>
    </base-material-card>
</template>

<script>
export default {
    props: ["resource", "title"],
    computed: {
        mwsConfigurationOptions() {
            return this.$store.getters.mwsConfigurationOptions;
        },
    },
    watch: {
        mwsConfigurationId: {
            immediate: true,
            handler(newVal) {
                if (!newVal) {
                    return;
                }
                const current = this.filter["mws_configuration_id"] || [];
                if (current.includes(newVal)) {
                    return;
                }
                this.filter["mws_configuration_id"] = [newVal];
            },
        },
        mwsConfigurationOptions: {
            immediate: true,
            handler(newVal) {
                if (!newVal || !newVal.length) {
                    return;
                }
                const filter = this.filters.find(
                    (filter) => filter.source === "mws_configuration_id"
                );
                filter.attributes.choices = newVal;
                this.findSelectedTab(newVal);
            },
        },
    },
    async mounted() {
        this.$watch(
            () => {
                return this.$refs.list.listState.items;
            },
            () => {
                this.updateSummary();
            }
        );
    },
    data() {
        return {
            selectedTab: 0,
            filter: {},
            filters: [
                {
                    label: "App Configuration",
                    source: "mws_configuration_id",
                    type: "select",
                    attributes: {
                        choices: this.mwsConfigurationOptions,
                        multiple: true,
                    },
                    alwaysOn: true,
                },
                // {
                //     source: "marketplace_id",
                //     type: "select",
                //     attributes: {
                //         multiple: true,
                //         choices: MarketplaceIds,
                //     },
                //     alwaysOn: true,
                // },
                { source: "asin", type: "text", alwaysOn: true },
                { source: "sellerSkus", type: "text", alwaysOn: true },
                { source: "Quantity_NewItem", type: "text", alwaysOn: true },
            ],
            fields: [
                // { source: "marketplace_id", sortable: true },
                { source: "asin", sortable: true },
                { source: "sellerSkus", sortable: true },
                { source: "Quantity_NewItem", sortable: true },
            ],
            item_headers: [
                { text: "sellerSku", value: "sellerSku", sortable: true },
                {
                    text: "totalQuantity",
                    value: "totalQuantity",
                    sortable: true,
                },
            ],
            item_detail_headers: [
                {
                    text: "fulfillableQuantity",
                    value: "fulfillableQuantity",
                    sortable: true,
                },
                {
                    text: "inboundWorkingQuantity",
                    value: "inboundWorkingQuantity",
                    sortable: true,
                },
                {
                    text: "inboundShippedQuantity",
                    value: "inboundShippedQuantity",
                    sortable: true,
                },
                {
                    text: "inboundReceivingQuantity",
                    value: "inboundReceivingQuantity",
                    sortable: true,
                },
                {
                    text: "researchingQuantity",
                    value: "researchingQuantity",
                    sortable: true,
                },
                {
                    text: "unfulfillableQuantity",
                    value: "unfulfillableQuantity",
                    sortable: true,
                },
            ],
        };
    },
    methods: {
        async updateSummary() {
            const { data } = await this.$store.dispatch(
                "FbaInventoryGroupSummaries/getList",
                {
                    filter: this.filter,
                }
            );
            const { quantity } = data;
            const thead = this.$refs.table.$el.querySelector("thead");
            const row = thead.querySelector("#total-row") || thead.insertRow();
            row.setAttribute("id", "total-row");
            const rowLength = row.cells.length;
            let totalLabelCell = rowLength ? row.cells[0] : row.insertCell();
            totalLabelCell.innerHTML = "Total";
            totalLabelCell.colSpan = 3;
            let totalQtyCell = rowLength ? row.cells[1] : row.insertCell();
            totalQtyCell.innerHTML = quantity;
        },
        onClickTab(id) {
            this.filter["mws_configuration_id"] = id ? id : "";
        },
        findSelectedTab(tabs) {
            const { filter } = this.$route.query;
            if (!filter) {
                return;
            }
            const currentFilter = JSON.parse(filter);
            if (!currentFilter["mws_configuration_id"]) {
                return;
            }
            this.selectedTab = parseInt(
                currentFilter["mws_configuration_id"],
                10
            );
            if (!this.selectedTab) {
                return;
            }
            this.selectedTab = tabs.findIndex(
                (item) => item.value === this.selectedTab
            );
        },
    },
};
</script>
<style>
#app
    > div
    > main
    > div
    > div
    > div.container.flex.container--fluid
    > div
    > div.d-flex.grow.flex-wrap,
#app
    > div
    > main
    > div
    > div
    > div.container.flex.container--fluid
    > div
    > div.v-data-iterator.fba-inventory-group-list
    > header
    > div
    > form
    > div
    > div:nth-child(2) {
    display: none !important;
}
#app > div > main > div > div > div.container.flex.container--fluid > div {
    margin-top: 0;
}

#app
    > div
    > main
    > div
    > div
    > div.container.flex.container--fluid
    > div
    > div.v-data-iterator.fba-inventory-group-list
    > header,
#app
    > div
    > main
    > div
    > div
    > div.container.flex.container--fluid
    > div
    > div.v-data-iterator.fba-inventory-group-list
    > header
    > div {
    height: 120px !important;
}
.v-data-table__expanded.v-data-table__expanded__content > td {
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.main-table
    > .v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr
    > td:nth-child(1),
.v-data-table.item-headers
    > .v-data-table__wrapper
    > table
    > tbody
    > tr
    > td:nth-child(1) {
    width: 52px;
}
.v-data-table.item-headers
    > .v-data-table__wrapper
    > table
    > tbody
    > tr
    > td:nth-child(2) {
    padding-left: 50px;
}
.main-table
    .v-data-table__wrapper
    > table
    > thead
    > tr#total-row
    > td:nth-child(2),
.main-table .v-data-table__wrapper > table > tbody > tr > td:nth-child(4),
.v-data-table.item-headers
    > .v-data-table__wrapper
    > table
    > tbody
    > tr
    > td:nth-child(3) {
    width: 150px !important;
    text-align: right !important;
}
.v-data-table.item-detail-headers
    .v-data-table__wrapper
    > table
    > tbody
    > tr
    > td:nth-child(1) {
    padding-left: 100px;
}
.v-data-table.item-headers
    .v-data-table.item-detail-headers
    .v-data-table__wrapper
    > table
    > tbody
    > tr
    > td:nth-child(1) {
    padding-left: 200px;
}
</style>
