<template>
    <va-create-layout>
        <ServiceFeeEventFee-form
            :title="title"
            :item="item"
        ></ServiceFeeEventFee-form>
    </va-create-layout>
</template>

<script>
export default {
    props: ["title", "item"],
};
</script>
