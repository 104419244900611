const LABEL_VERY_BAD = "VERY_BAD";
const LABEL_BAD = "BAD";
const LABEL_MEDIUM = "MEDIUM";
const LABEL_GOOD = "GOOD";
const LABEL_VERY_GOOD = "VERY_GOOD";
const LABEL_EXCELLENT = "EXCELLENT";

const Labels = [
    LABEL_VERY_BAD,
    LABEL_BAD,
    LABEL_MEDIUM,
    LABEL_GOOD,
    LABEL_VERY_GOOD,
    LABEL_EXCELLENT,
];

const SaleInventoryLabels = Labels.map((label) => ({
    text: label,
    value: label,
}));

export const vTableHeaders = SaleInventoryLabels.map((item) => ({
    text: item.text,
    value: item.value,
    sortable: true,
}));

export const SaleInventoryLabelColorMap = {
    [LABEL_VERY_BAD]: "red",
    [LABEL_BAD]: "pink",
    [LABEL_MEDIUM]: "purple",
    [LABEL_GOOD]: "indigo",
    [LABEL_VERY_GOOD]: "light-blue",
    [LABEL_EXCELLENT]: "blue",
};

export default SaleInventoryLabels;
