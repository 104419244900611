<template>
    <div>
        <base-material-card
            :icon="resource.icon"
            :title="title"
            class="order-list-card"
        >
            <v-tabs v-model="selectedTab">
                <v-tab
                    class="mr-3"
                    v-for="tab in mwsConfigurationOptions"
                    :key="tab.value"
                    @click="onClickTab(tab.value)"
                >
                    <v-icon class="mr-2">mdi-amazon</v-icon>
                    {{ tab.text }}
                </v-tab>
            </v-tabs>
            <v-tabs-items v-model="selectedTab" class="transparent">
            </v-tabs-items>
            <div class="row mt-5">
                <div class="col-2">
                    <v-btn
                        block
                        color="primary"
                        :disabled="!filter.mws_configuration_id"
                        @click="clickCreateNew"
                        >Create New</v-btn
                    >
                    <va-list
                        ref="list"
                        :filters="filters"
                        :filter.sync="filter"
                        disable-export
                        disable-create
                        class="order-request-list"
                    >
                        <va-data-table
                            disable-actions
                            disable-select
                            @row-click="selectOrderRequest"
                            :fields="fields"
                        ></va-data-table>
                    </va-list>
                </div>
                <div class="col-10">
                    <order-request-create
                        v-if="
                            showCreate &&
                            cogCatalogItemMap[filter.mws_configuration_id] &&
                            supplierMap[filter.mws_configuration_id]
                        "
                        @saved="onSaved"
                        @discarded="onDiscarded"
                        :mwsConfigurationOptions="mwsConfigurationOptions"
                        :mwsConfigurationId="filter.mws_configuration_id"
                        :cogCatalogItemMap="
                            cogCatalogItemMap[filter.mws_configuration_id]
                        "
                        :supplierMap="supplierMap[filter.mws_configuration_id]"
                        :filter="filter"
                    ></order-request-create>
                    <order-request-edit
                        v-if="showEdit"
                        :id="id"
                        :item="item"
                        :cogCatalogItemMap="
                            cogCatalogItemMap[filter.mws_configuration_id]
                        "
                        :supplierMap="supplierMap[filter.mws_configuration_id]"
                        @saved="onSaved"
                        @discarded="onDiscarded"
                        @deleted="onDeleted"
                    ></order-request-edit>
                </div>
            </div>
        </base-material-card>
    </div>
</template>

<script>
export default {
    props: ["resource", "title"],
    computed: {
        mwsConfigurationOptions() {
            return this.$store.getters.mwsConfigurationOptions;
        },
    },
    watch: {
        mwsConfigurationId: {
            immediate: true,
            handler(newVal) {
                if (!newVal) {
                    return;
                }
                const current = this.filter["mws_configuration_id"] || [];
                if (current.includes(newVal)) {
                    return;
                }
                this.filter["mws_configuration_id"] = [newVal];
            },
        },
        mwsConfigurationOptions: {
            immediate: true,
            handler(newVal) {
                if (!newVal || !newVal.length) {
                    return;
                }
                const filter = this.filters.find(
                    (filter) => filter.source === "mws_configuration_id"
                );
                if (!filter) {
                    return;
                }
                filter.attributes.choices = newVal;
                this.findSelectedTab(newVal);
            },
        },
    },
    data() {
        return {
            selectedTab: 0,
            filter: {},
            filters: [
                {
                    label: "App Configuration",
                    source: "mws_configuration_id",
                    type: "select",
                    attributes: {
                        choices: this.mwsConfigurationOptions,
                        multiple: true,
                    },
                    alwaysOn: true,
                },
            ],
            fields: [{ source: "name", sortable: true }],
            showCreate: false,
            showEdit: false,
            id: null,
            item: null,
            cogCatalogItemMap: {},
            supplierMap: {},
        };
    },
    mounted() {},
    methods: {
        async onClickTab(id) {
            this.filter["mws_configuration_id"] = id ? id : "";
            this.showCreate = false;
            this.showEdit = false;
            if (id) {
                const mwsConfigurationOption =
                    this.mwsConfigurationOptions.find(
                        (mwsConfiguration) => mwsConfiguration.value === id
                    );
                this.filter["marketplace_id"] =
                    mwsConfigurationOption[this.marketplaceIdKey];
                await this.getCogCatalogMap(id);
                await this.getSupplierMap(id);
            } else {
                this.filter["marketplace_id"] = "";
            }
        },
        findSelectedTab(tabs) {
            const { filter } = this.$route.query;
            if (!filter) {
                return;
            }
            const currentFilter = JSON.parse(filter);
            if (!currentFilter["mws_configuration_id"]) {
                return;
            }
            this.selectedTab = parseInt(
                currentFilter["mws_configuration_id"],
                10
            );
            if (!this.selectedTab) {
                return;
            }
            this.selectedTab = tabs.findIndex(
                (item) => item.value === this.selectedTab
            );
            this.getCogCatalogMap(currentFilter["mws_configuration_id"]);
            this.getSupplierMap(currentFilter["mws_configuration_id"]);
        },
        async getCogCatalogMap(mws_configuration_id) {
            if (this.cogCatalogItemMap[mws_configuration_id]) {
                return;
            }

            const { data } = await this.$store.dispatch(
                "CogCatalogItemMap/getList",
                {
                    filter: { mws_configuration_id },
                }
            );
            this.cogCatalogItemMap[mws_configuration_id] = data;
        },
        async getSupplierMap(mws_configuration_id) {
            if (this.supplierMap[mws_configuration_id]) {
                return;
            }

            const { data } = await this.$store.dispatch("SupplierMap/getList", {
                filter: { mws_configuration_id },
            });
            this.supplierMap[mws_configuration_id] = data;
        },
        async clickCreateNew() {
            this.showEdit = false;
            this.item = null;
            this.id = null;
            this.showCreate = false;
            setTimeout(() => {
                this.showCreate = true;
            }, 200);
        },
        async selectOrderRequest(item) {
            this.showCreate = false;
            this.item = item;
            this.id = item.id;
            if (!this.selectedTab) {
                this.selectedTab = this.mwsConfigurationOptions.findIndex(
                    (i) => i.value === item.mws_configuration_id
                );
                await this.onClickTab(item.mws_configuration_id);
            }
            this.showEdit = false;
            setTimeout(() => {
                this.showEdit = true;
            }, 200);
        },
        async onSaved() {
            await this.$refs.list.fetchData();
            if (!this.item) {
                this.showCreate = false;
                this.showEdit = false;
                return;
            }
            const savedItem = this.$refs.list.$data.listState.items.find(
                (i) => i.id === this.item.id
            );
            if (!savedItem) {
                this.showCreate = false;
                this.showEdit = false;
            }
            return this.selectOrderRequest(savedItem);
        },
        onDiscarded() {
            if (this.showCreate) {
                this.showCreate = false;
                setTimeout(() => {
                    this.showCreate = true;
                }, 200);
                return;
            }
            this.showEdit = false;
            setTimeout(() => {
                this.showEdit = true;
            }, 200);
        },
        onDeleted() {
            this.showCreate = false;
            this.showEdit = false;
        },
    },
};
</script>
<style lang="sass">

.order-request-list
    > header
        > div
            > form
                > div
                    > div:nth-child(2)
                        display: none !important

            > header
                > div
                    > form
                        > div
                            > div:nth-child(2)
                                display: none !important
    .v-data-footer
        .v-btn--icon.v-size--default
            width: 28px !important
</style>
