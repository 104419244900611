<template>
    <base-material-card :icon="resource.icon" :title="title">
        <v-tabs v-model="selectedTab">
            <v-tab
                class="mr-3"
                v-for="tab in mwsConfigurationOptions"
                :key="tab.value"
                @click="onClickTab(tab.value)"
            >
                <v-icon class="mr-2">mdi-amazon</v-icon>
                {{ tab.text }}
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="selectedTab" class="transparent"> </v-tabs-items>
        <va-list
            :filters="filters"
            :filter.sync="filter"
            class="catalog-item-list"
            disable-create
        >
            <va-data-table
                :fields="fields"
                disable-clone
                disable-select
                disable-delete
                disable-edit
                disable-show
            >
            </va-data-table>
        </va-list>
    </base-material-card>
</template>

<script>
import MarketplaceIds from "@/constants/MarketplaceIds";

export default {
    props: ["resource", "title"],
    computed: {
        mwsConfigurationOptions() {
            return this.$store.getters.mwsConfigurationOptions;
        },
    },
    watch: {
        mwsConfigurationId: {
            immediate: true,
            handler(newVal) {
                if (!newVal) {
                    return;
                }
                const current = this.filter["parent_id"] || [];
                if (current.includes(newVal)) {
                    return;
                }
                this.filter["parent_id"] = [newVal];
            },
        },
        mwsConfigurationOptions: {
            immediate: true,
            handler(newVal) {
                if (!newVal || !newVal.length) {
                    return;
                }
                const filter = this.filters.find(
                    (filter) => filter.source === "parent_id"
                );
                filter.attributes.choices = newVal;
                this.findSelectedTab(newVal);
            },
        },
    },
    data() {
        return {
            selectedTab: 0,
            filter: {},
            filters: [
                {
                    label: "App Configuration",
                    source: "parent_id",
                    type: "select",
                    attributes: {
                        choices: this.mwsConfigurationOptions,
                        multiple: true,
                    },
                    alwaysOn: true,
                },
                {
                    source: "MarketplaceId",
                    type: "select",
                    attributes: {
                        multiple: true,
                        choices: MarketplaceIds,
                    },
                    alwaysOn: true,
                },
                {
                    source: "SellerSKU",
                    type: "text",
                    alwaysOn: true,
                },
                {
                    source: "ASIN",
                    type: "text",
                    alwaysOn: true,
                },
                {
                    label: "Name",
                    source: "AttributeSets_Title",
                    type: "text",
                    alwaysOn: true,
                },
            ],
            fields: [
                { source: "SellerSKU", sortable: true },
                { source: "ASIN", sortable: true },
                {
                    label: "Name",
                    source: "AttributeSets_Title",
                    sortable: true,
                },
                {
                    label: "Image",
                    source: "AttributeSets",
                    type: "image",
                    attributes: {
                        src: "SmallImage.URL",
                    },
                    sortable: false,
                },
            ],
        };
    },
    methods: {
        onClickTab(id) {
            this.filter["parent_id"] = id ? id : "";
        },
        findSelectedTab(tabs) {
            const { filter } = this.$route.query;
            if (!filter) {
                return;
            }
            const currentFilter = JSON.parse(filter);
            if (!currentFilter["parent_id"]) {
                return;
            }
            this.selectedTab = parseInt(currentFilter["parent_id"], 10);
            if (!this.selectedTab) {
                return;
            }
            this.selectedTab = tabs.findIndex(
                (item) => item.value === this.selectedTab
            );
        },
    },
};
</script>
<style lang="sass">
.catalog-item-list
    > form
        table
            tbody
                td:nth-child(4)
                    button
                        background: transparent !important
    > header
        > div
            > form
                > div
                    > div:nth-child(2),div:nth-child(3)
                        display: none !important
</style>
