var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-material-card',{attrs:{"icon":_vm.resource.icon,"title":_vm.title}},[_c('va-list',{ref:"list",attrs:{"filters":_vm.filters,"filter":_vm.filter,"sort-by":_vm.sort.by,"sort-desc":_vm.sort.desc,"disable-global-search":"","disable-create":"","disable-create-redirect":"","disable-export":""},on:{"update:filter":function($event){_vm.filter=$event},"update:sortBy":function($event){return _vm.$set(_vm.sort, "by", $event)},"update:sort-by":function($event){return _vm.$set(_vm.sort, "by", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.sort, "desc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.sort, "desc", $event)}},scopedSlots:_vm._u([{key:"filter.end_date",fn:function(props){return [_c('date-range-picker',_vm._b({ref:"picker",attrs:{"opens":'right',"timePicker":false,"showWeekNumbers":true,"showDropdowns":true,"autoApply":true,"locale-data":{
                    firstDay: 1,
                }},on:{"update":function (val) { return _vm.update(val); }},scopedSlots:_vm._u([{key:"input",fn:function(picker){return [(!picker.startDate || !picker.startDate)?_c('label',{staticClass:"v-label theme--light"},[_vm._v(" Date Range ")]):[_vm._v(" "+_vm._s(_vm._f("moment")(picker.startDate,"timezone", _vm.tz, "L"))+" - "+_vm._s(_vm._f("moment")(picker.endDate,"timezone", _vm.tz, "L"))+" ")]]}}],null,true),model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}},'date-range-picker',props,false))]}}])},[_c('va-data-table',{attrs:{"fields":_vm.fields,"disable-clone":"","disable-delete":"","disable-select":"","disable-actions":""},scopedSlots:_vm._u([{key:"field.end_date",fn:function(ref){
                var value = ref.value;
return [_c('span',[_vm._v(_vm._s(value.split("T")[0]))])]}},{key:"field.raw",fn:function(ref){
                var value = ref.value;
                var item = ref.item;
return [(item.raw.length)?_c('a',{on:{"click":function($event){return _vm.openReportDialog(item)}}},[_vm._v("View Report")]):_vm._e()]}}],null,true)})],1),_c('v-dialog',{on:{"click:outside":_vm.closeReportDialog},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":_vm.closeReportDialog}},[_c('v-icon',[_vm._v("mdi-close")])],1),(_vm.item)?_c('v-toolbar-title',[_vm._v(" Type: "+_vm._s(_vm.item.report_type_id.replace("sp", ""))+", Date: "+_vm._s(_vm.item.end_date.split("T")[0])+" ")]):_vm._e()],1),(_vm.item)?_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[(_vm.item.raw.length)?_c('AdsListReportSimpleList',{attrs:{"items":_vm.item.raw}}):_vm._e()],1)],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }