var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('va-aside-layout',{attrs:{"title":_vm.asideTitle}},[(_vm.show)?_c('us-warehouse-inventory-form',{attrs:{"id":_vm.id,"item":_vm.item},on:{"saved":_vm.onSaved}}):_vm._e()],1),_c('base-material-card',{attrs:{"icon":_vm.resource.icon,"title":_vm.title}},[_c('v-tabs',{model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},_vm._l((_vm.mwsConfigurationOptions),function(tab){return _c('v-tab',{key:tab.value,staticClass:"mr-3",on:{"click":function($event){return _vm.onClickTab(tab.value)}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-amazon")]),_vm._v(" "+_vm._s(tab.text)+" ")],1)}),1),_c('v-tabs-items',{staticClass:"transparent",model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}}),_c('va-list',{ref:"list",staticClass:"us-warehouse-inventory-list",attrs:{"filters":_vm.filters,"disable-create":"","disable-export":"","filter":_vm.filter},on:{"action":_vm.onAction,"update:filter":function($event){_vm.filter=$event}}},[_c('va-data-table',{ref:"table",staticClass:"us-warehouse-inventory-main-table",attrs:{"fields":_vm.fields,"show-expand":"","single-expand":"","disable-clone":"","disable-delete":"","disable-show":"","disable-select":"","disable-edit-redirect":""},on:{"item-action":_vm.onAction},scopedSlots:_vm._u([{key:"field.asin",fn:function(ref){
var value = ref.value;
return [_c('a',{attrs:{"target":"_blank","href":'https://amazon.com/dp/' + value}},[_vm._v(_vm._s(value))])]}},{key:"field.stock_available",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_vm._v(" "+_vm._s(value + item.other_stock_available)+" ")]}},{key:"field.stock_inbound",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_vm._v(" "+_vm._s(value + item.other_stock_inbound)+" ")]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [(item.children.length)?_c('v-data-table',{staticClass:"us-warehouse-inventory-item-headers",attrs:{"hide-default-header":"","hide-default-footer":"","headers":_vm.item_headers,"items":item.children,"show-expand":""}}):_vm._e()]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }